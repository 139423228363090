import React from "react";

// Customizable Area Start
import { Box, Table, Button, TableBody, TableCell, TableContainer, TableHead, TableRow, Grid, InputAdornment, Typography, Chip, Radio, withStyles, Theme, Link } from "@material-ui/core";
import { CustomizeInputSearchBox, FitContentDarkButton, OrangeButton } from "../CustomizedElementStyle";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import AddIcon from "@material-ui/icons/Add";
import { ToastContainer } from "react-toastify";
// Customizable Area End

import SearchProductNameController, { Props, configJSON } from "./SearchProductNameController";
import { deleteIcon } from "../../../catalogue/src/assets";
import { Pagination } from "@material-ui/lab";
import { checkmark } from "../assets";
import { CustomOrangeButton } from "../../../../components/src/CommonButton";

export default class SearchProductName extends SearchProductNameController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  rows = [] as any[];
  // Customizable Area End
  // Customizable Area Start

  render() {
    const { classes } = this.props;
    const value: any = this.state.totalCount / 10;
    return (
      <>
        <Box className={classes.header}>{configJSON.productCatalogueText}</Box>
        <ToastContainer/>
        <Box className={classes.wrapper}>
          <Box className={classes.headerWrap}>
            <Box data-test-id="component" className={classes.headerDes}>
              {configJSON.searchProductName}
            </Box>
          </Box>
          <Box className={classes.searchWrapper}>
            <Grid container spacing={2}>
              <Grid item lg={2} md={2} sm={3} xs={12} style={{zIndex:1}}>
                <Button className={classes.searchValue}>{configJSON.productNameText}</Button>
              </Grid>

              <Grid item lg={6} md={5} sm={9} xs={12} className={classes.searchGrid}>
                <Box sx={{ position: "relative" }}>
                  <CustomizeInputSearchBox
                    data-test-id="searchBox"
                    id="outlined-start-adornment"
                    autoComplete="off"
                    value={this.state.keyword}
                    onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
                      this.setState({ keyword: event.target.value });
                      this.handleSearchOnChange(event.target.value);
                    }}
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start" style={webStyle.marginThree}></InputAdornment>,
                      classes: {
                        input: classes.inputPadding,
                      }
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>

        <Typography ref={this.topRef}>
          <Box className={classes.main}>
            <Box className={classes.resultsText}>{this.state.keyword ? `found ${this.state.productsResults.length} results for ${this.state.keyword}` : ""}</Box>
            {this.state.keyword && <Box className={classes.resultDes}>{configJSON.resultDes}</Box>}
          </Box>
          <TableContainer className={classes.tableContainer}>
            <Table aria-label="simple" stickyHeader>
              <TableHead className={classes.thead}>
                <TableRow style={webStyle.bold}>
                  <TableCell align="center" rowSpan={2} colSpan={2} className={classes.tableHeaderText}>
                    {configJSON.imageLabel}
                  </TableCell>
                  <TableCell className={classes.tableHeaderTextOne}>{configJSON.titleText}</TableCell>
                  <TableCell className={classes.tableHeaderText}>{configJSON.categoryLabel}</TableCell>
                  <TableCell className={classes.tableHeaderText}>{configJSON.beskuLabel}</TableCell>
                  <TableCell className={classes.tableHeaderText}>{configJSON.modelLabel}</TableCell>
                  <TableCell className={classes.tableHeaderText}>{configJSON.productLinkText}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.productsResults.length > 0 ? (
                  this.state.productsResults?.map((item: any) => {
                    return (
                      <TableRow data-test-id="list" key={item.id}>
                        <TableCell className={classes.tableRow}>
                          <Radio data-test-id="radioBtn" name="product" className={classes.radioBtn} checked={item.category_id === this.state.productId} onClick={() => this.handleSelectProduct(item.category_id, item.besku)} />
                        </TableCell>
                        <TableCell className={classes.tableRow}>
                          <Box className={classes.prodImage}>
                            <img src={item.product_image} alt="img" className={classes.image} />
                          </Box>
                        </TableCell>
                        <TableCell className={classes.tableRow}>{item.product_title}</TableCell>
                        <TableCell className={classes.tableRow}>{item.category}</TableCell>
                        <TableCell className={classes.tableRow}>{item.besku}</TableCell>
                        <TableCell className={classes.tableRow}>{item.model}</TableCell>
                        <TableCell className={`${classes.tableRow}`}>
                          <Link data-test-id="listYourProductRef" disabled={true} component="button" variant="body2" style={{ cursor:'unset',color: "#FF6008", fontStyle: "italic", textDecoration: "underline" }} onClick={() => this.handleViewProduct(item)}>
                            {configJSON.viewProductLink}
                          </Link>   
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableCell colSpan={8}>
                    <Typography variant="h6" className={classes.text}>
                      {configJSON.noDataAvailableText}
                    </Typography>
                  </TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Typography>
          <Pagination
            data-test-id="pagination"
            className={classes.pagination}
            page={this.state.page}
            count={this.state.totalCount > 10 ? parseInt(value) + 1 : 1}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => {
              this.topRef.current?.scrollIntoView({ behavior: 'smooth' });
              this.setState({ page: value });
              this.searchProducts(this.state.keyword, value);
            }}
            shape="rounded"
            siblingCount={0}
            boundaryCount={1}
          />
          <Box sx={{ marginTop: 80 }}  style={{position:'relative'}}>
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Grid container className={classes.left}>
                  <Grid item lg={4} md={4} sm={12} xs={12} xl={4}>
                      <CustomizeInputSearchBox
                        data-test-id="createSKU"
                        label={configJSON.createSkuLabel}
                        className={`${classes.sku}${classes.inputPadding}`}
                        id="outlined-start-adornment"
                        autoComplete="off"
                        value={this.state.skuValue}
                        fullWidth
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleSKUOnChangeInputs(event.target.value)}
                        InputLabelProps={{
                          shrink: true,
                          style: {
                            transform: 'translate(1px, -6px) scale(0.75)'
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" style={webStyle.marginThree}></InputAdornment>
                          ),
                          classes: {
                            input: classes.inputPadding,
                          },
                        }}
                      />
                    {this.state.skuError && (
                      <span>
                        <Typography data-test-id="skuErr" className={classes.nextError} style={webStyle.marginTwo}>
                          {this.state.skuError}
                        </Typography>
                      </span>
                    )}
                  </Grid>
                  <Grid item lg={2} md={2} sm={12} xs={12} xl={2} style={{margin:this.setValidateBtnStyle(), textAlign: this.setAlignment()}}>
                    <FitContentDarkButton data-test-id="validateBtn" className={classes.validateBtn} onClick={this.handleValidates}>
                      {configJSON.validateBtn}
                    </FitContentDarkButton>
                  </Grid>
                  <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className={classes.validateErr}>
                    {this.state.validateError && (
                      <Typography data-test-id="validateErr" className={classes.nextError}>
                        {this.state.validateError}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={12} xs={12} className={classes.newProductBtn}>
                <Button color="primary" variant="text" startIcon={<AddIcon />} data-test-id="addNewProductBtn" className={classes.addNew} onClick={this.handleAddNewProductBtn}>
                  {configJSON.addNewProductText}
                </Button>
              </Grid>
                </Grid>
              </Grid>
            </Grid>

            {this.state.isValidate && (
              <>
                {this.state.skuValidateErr === configJSON.skuValidText && (
                  <Grid container spacing={2} className={classes.marginOne}>
                    <Grid item lg={12} md={12} sm={12} xs={12} className={classes.validateWrap}>
                      <Grid item lg={4} md={4} sm={4} xs={10} xl={4}>
                        <Chip data-test-id="check" icon={<img src={checkmark} alt="checkIcon" style={webStyle.checkIcon} />} label={this.state.skuValue} className={classes.checkWrap} />
                      </Grid>
                      <Grid item lg={2} md={2} sm={2} xs={2} className={classes.deleteWrap}>
                        <img data-test-id="deleteIcon" onClick={this.handleDeleteIcon} src={deleteIcon} alt="delIcon" style={webStyle.cursor} />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {this.state.skuValidateErr === configJSON.alreadyExistsText && (
                  <Grid container spacing={2} className={classes.marginOne} style={{top: this.setInvalidStyle()}}>
                    <Grid item lg={12} md={12} sm={12} xs={12} className={classes.validateWrap}>
                      <Grid item lg={4} md={4} sm={4} xs={10} xl={4}>
                        <Chip data-test-id="cancel" icon={<CancelOutlinedIcon className={classes.cancel} />} label={this.state.skuValue} className={classes.cancelWrap} />
                      </Grid>
                      <Grid item lg={2} md={2} sm={2} xs={2} className={classes.deleteWrap}>
                        <img data-test-id="deleteIcon" onClick={this.handleDeleteIcon} src={deleteIcon} alt="delIcon" style={webStyle.cursor} />
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </>
            )}

            <Box className={classes.nextWrap}>
              {this.state.errorMsg && (
                <Typography data-test-id="nextError" className={classes.nextError}>
                  {this.state.errorMsg}
                </Typography>
              )}
              <CustomOrangeButton className={classes.nextButton} data-test-id="nextRef" disabled={this.state.productsResults.length === 0 || this.state.isNextDisable} onClick={this.handleNextBtn}>
                {configJSON.nextBtn}
              </CustomOrangeButton>
            </Box>
          </Box>
        </Box>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  footercontainer: {
    color: "#B1B2B4",
    fontFamily: `"Lato", sans-serif`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    fontSize: "14px"
  },
  footer_item: {
    fontWeight: 600,
    padding: "50px 0px 10px 20px"
  }
};
const webStyle = {
  tabelHeadeing: {
    fontWeight: 600,
    fontSize: "15px"
  },
  icon: {
    color: "#fff"
  },
  search: {
    background: "#F6F6F6",
    border: "1px solid rgba(151, 151, 151, 0.147084)",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.0468298)",
    marginTop: "8px",
    maxHeight: "400px",
    overflowY: "auto" as const
  },
  checkIcon: {
    marginRight: "-9px",
    marginLeft: "14px",
    width: "22px"
  },
  cursor: {
    cursor: 'pointer'
  },
  marginTwo: { 
    marginTop: "8px" 
  },
  marginThree: { 
    marginRight: 20 
  },
  bold: { 
    fontWeight: 600 
  }
};

const styles = (theme: Theme) => ({
  radioBtn: {
    color: "#FF6008",
    marginRight: "-20px",
    // paddingLeft: "0",
    "&.Mui-checked": {
      color: "#FF6008"
    }
  },
  prodImage: {
    width: "59px",
    height: "59px",
    borderRadius: "5px",
    background: "rgb(231,231,231)",
    padding: "8px",
    [theme.breakpoints.down("xs")]: {
      width: "40px",
      height: "40px"
    }
  },
  tableRow: {
    "&.MuiTableCell-body": {
      border: "none",
      fontFamily: `"Lato", sans-serif`,
      fontSize: "14px",
      fontWeight: 400,
      color: "#697a8d",
      textAlign: "center" as const,
      padding: "16px 12px"
    }
  },
  thead: {
    "&.MuiTableHead-root": {
      border: "1px solid rgba(151,151,151,0.33)"
    }
  },
  nextError: {
    fontFamily: `"Lato", sans-serif`,
    color: "red",
    textAlign: "center" as const,
    fontSize: "12px",
    marginRight: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    '@media (max-width:820px)': {
      width: '200px'
    },
    '@media (max-width:600px)': {
      marginRight: '0'
    }
  },
  validateErr: {
    fontFamily: `"Lato", sans-serif`,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingLeft: "20px",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center"
    }
  },
  header: {
    fontSize: "18px",
    padding: "20px 0px 15px 0px",
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 400
  },
  wrapper: {
    overflowX: "hidden" as const,
    overflowY: "auto" as const,
    padding: "30px",
    marginBottom: "30px",
    borderRadius: "8px",
    backgroundColor: "#fff"
  },
  headerWrap: {
    display: "flex",
    flexDirection: "row" as const,
    width: "100%",
    flexWrap: "wrap" as const,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10
  },
  headerDes: {
    fontFamily: `"Lato", sans-serif`,
    fontSize: "27px",
    fontWeight: 700
  },
  searchText: {
    fontSize: 12,
    fontWeight: 500,
    color: "#666666",
    fontFamily: `"Lato", sans-serif`,
    marginBottom: 10,
    marginTop: 25
  },
  searchWrapper: {
    marginTop: "30px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  searchValue: {
    background: "#000",
    color: "#fff",
    border: "none",
    textTransform: "none" as const,
    width: "100%",
    cursor: "none",
    height: "46px",
    padding: "11px 0 8px",
    "&:hover": {
      background: "#000",
      color: "#fff"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px"
    }
  },
  filterContainer: {
    fontSize: "27px",
    fontWeight: 700,
    fontFamily: `"Lato", sans-serif`
  },
  filterBtn: {
    padding: "7px 23px",
    color: "#657A8E",
    fontFamily: `"Lato", sans-serif` as const,
    fontSize: 16,
    fontWeight: 500 as const,
    border: "1px solid rgba(101, 122, 142, 0.320241)",
    borderRadius: 5,
    textTransform: "none" as const,
    backgroundColor: "rgb(242,243,244)"
  },
  main: {
    marginBottom: 20,
    display: "flex",
    flexDirection: "row" as const,
    marginTop: 30
  },
  resultsText: {
    color: "#666666",
    fontWeight: 1000,
    fontSize: 12,
    fontFamily: `"Lato", sans-serif`,
    marginRight: 5
  },
  resultDes: {
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 500,
    color: "#666666",
    fontSize: 12
  },
  tableHeaderText: {
    fontWeight: 600,
    background: "#f2f3f4",
    fontFamily: `"Lato-Heavy", sans-serif`,
    fontSize: "14px",
    color: "#33333a",
    textAlign: "center" as const
  },
  image: {
    width: "100%",
    height: "100%"
  },
  left: {
    display: "flex",
    flexDirection: "row" as const,
    flexWrap: "nowrap" as const,
    height:'45px',
    '@media (max-width: 600px)': {
      flexWrap:'wrap' as const,
      height: 'auto'
    }
  },
  sku: {
    fontFamily: `"Lato-Semibold",sans-serif`,
    fontWeight: 400,
    fontSize: "12px",
    color: "#666666"
  },
  inputPadding: {
    paddingRight: '20px',
    '@media (max-width: 600px)': {
      paddingRight: '5px',
    }
  },
  validateBtn: {
    marginLeft: 20,
    minWidth: 124,
    padding: "9px 0 11px",
    borderRadius: "4px",
    "@media (max-width: 600px)": {
        padding: "10px 20px",
        fontSize: "14px",
        width:'220px',
        marginLeft: '0',
        "@media (max-width: 400px)": {
          padding: "8px 16px",
          fontSize: "12px",
      },
    },
  },
  marginOne: {
    marginTop: "20px",
    "@media (max-width: 600px)": {
      position: 'absolute',
      top: '40px'
    }
  },
  validateWrap: {
    display: "flex",
    alignItems: "center"
  },
  check: {
    color: "#44a213"
  },
  cancel: {
    color: "rgb(255,85,111)",
    marginRight: "-9px",
    marginLeft: "14px"
  },
  checkWrap: {
    height: "46px",
    borderRadius: "3px",
    width: "100%",
    justifyContent: "flex-start",
    background: "#d9f8c5",
    color: "#44a213"
  },
  cancelWrap: {
    height: "46px",
    borderRadius: "3px",
    width: "100%",
    justifyContent: "flex-start",
    background: "rgb(255,229,231)",
    color: "rgb(255,85,111)"
  },
  deleteWrap: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
    '@media (max-width:600px)': {
      justifyContent: 'flex-end'
    }
  },
  nextWrap: {
    justifyContent: "flex-end",
    width: "100%",
    display: "flex",
    marginTop: "50px",
    '@media (max-width: 600px)': {
      justifyContent:'center',
      margin:'32px auto',
      flexDirection:"column" as const,
      alignItems:'center'
    }
  },
  details: {
    display: "block",
    width: "100px",
    whiteSpace: "nowrap" as const,
    overflow: "hidden" as const,
    textOverflow: "ellipsis" as const,
    marginTop: "26px"
  },
  tableContainer: {
    overflowX: "auto" as const,
    maxWidth: "100%"
  },
  text: {
    border: "none",
    textAlign: "center" as const,
    fontFamily: `"Lato", sans-serif`,
    padding: "10px"
  },
  tableHeaderTextOne: {
    fontWeight: 600,
    background: "#f2f3f4",
    fontFamily: `"Lato-Heavy", sans-serif`,
    fontSize: "14px",
    color: "#33333a",
    textAlign: "center" as const,
    width: "130px"
  },
  newProductBtn: {
    justifyContent: "flex-end",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      justifyContent: "center",
      alignItems: "center"
    }
  },
  addNew: {
    color: "#fff",
    padding: "8px 18px",
    textTransform: "none" as const,
    fontFamily: "'Lato', sans-serif",
    fontWeight: 600,
    fontSize: 14,
    background: "#0fba9f",
    "&:hover": {
      backgroundColor: "#0fba99",
      color: "#fff"
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "12px"
    },
    "@media (max-width: 600px)": {
      padding: "10px 20px",
      fontSize: "14px",
      width:'220px',
      "@media (max-width: 400px)": {
        padding: "8px 16px",
        fontSize: "12px"
    },
  },
  },
  nextButton: {
    '@media (max-width: 600px)': {
      padding:'10px 70px',
      width:'220px'
    }
  },
  viewButton: {
    width: "100px",
    textTransform: "none" as const,
    fontFamily: "'Montserrat Variable', sans-serif",
    fontWeight: 400,
    fontSize: 12,
    background: "#ffe5e7",
    color: "#ff556f",
    padding: "3px 8px"
  },
  searchGrid: {
    marginLeft:'-20px',
    '@media (max-width : 600px)': {
      marginLeft: '0'
    }
  },
  pagination: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "center",
    '@media (max-width : 600px)': {
      width: 'max-content',
      alignItems:'center',
      margin:'20px auto'
    }
  },
});

const styledProductName = withStyles(styles)(SearchProductName);
export { styledProductName };
// Customizable Area End
