import React from "react";

// Customizable Area Start
import { Box, Backdrop, Button, Typography, Modal, Paper, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, withStyles, makeStyles } from "@material-ui/core";
import { BoxNoScroll, CustomDeleteButton } from "../CustomizedElementStyle";
import { editIcon, trash_1, cross } from "../assets";
import { styled } from "@material-ui/styles";
import { Loader2 } from "../../../../components/src/Loader.web";
import CloseIcon from '@material-ui/icons/Close';

export const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "rgb(255, 160, 106)",
    color: theme.palette.common.black,
    fontSize: 14,
    fontWeight: 600
  },
  body: {
    fontSize: 14,
    fontWeight: 600
  }
}))(TableCell);
const CustomBackdrop = styled(Backdrop)(({ theme }) => ({
  backgroundColor: "rgba(0, 0, 0, 0.5)"
}));
export const StyledTableRow = withStyles(theme => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

export const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
});

// Customizable Area End

import SellerBrandCatalogListController, { Props } from "./SellerBrandCatalogListController";
import Visibility from "@material-ui/icons/Visibility";
import { CustomBox } from "../../../otp-input-confirmation/src/CustomElementStyling";
import { errorImg } from "../../../bulkuploading/src/BulkUploadingController";
import { ToastContainer } from "react-toastify";
import { CustomOrangeButton } from "../../../../components/src/CommonButton";

export default class SellerSuggestionFeedback extends SellerBrandCatalogListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderTable() {
    const { listResponse } = this.state;
    return (
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow style={{ whiteSpace: "nowrap" }}>
              <StyledTableCell align="center" style={{ width: "25%" }}>
                {" "}
                Name
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: "25%" }}>
                Date
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: "23%" }}>
                Website
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: "10%" }}>
                View Products
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: "7%" }}>
                {" "}
                Edit
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: "7%" }}>
                {" "}
                Delete
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listResponse.map((row: any, index: number) => {
              const date = new Date(row.attributes.created_at);
              let formattedDate;
              if (!isNaN(date.getTime())) {
                formattedDate = date.toISOString().split("T")[0];
              }
              return (
                <StyledTableRow key={row.id} data-test-id="list">
                  <StyledTableCell align="center">{row.attributes.brand_name}</StyledTableCell>
                  <StyledTableCell align="center">{formattedDate}</StyledTableCell>
                  <StyledTableCell align="center">{row.attributes.brand_website || "www.demo.com"}</StyledTableCell>
                  <StyledTableCell align="center">
                    <Visibility className="rotateIcon" style={{ width: 22, cursor: "pointer" }} data-test-id={`demoOpen${index}`} onClick={() => this.openProductList(row.id)} />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <img src={editIcon} style={{ width: 22, cursor:"pointer" }} data-test-id={`demoEdit${index}`} onClick={() => this.handleEdit(row, true)} />
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <img data-test-id={`demo${index}`} src={trash_1} style={{ width: 22, cursor:"pointer" }} onClick={() => this.handleModal(row.id)} />
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  renderListScreen() {
    return (
      <>
        <BoxNoScroll>
          <StyledInnerBox>
            <Box sx={{ width: "100%", display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", marginBottom: 10 }}>
              <Typography data-test-id="brandlist" style={{ fontSize: "27px", fontWeight: 700, fontFamily: `"Lato", sans-serif` }}>Brand List</Typography>
              <CustomOrangeButton
                data-test-id="submitRef"
                onClick={this.handleGoToSellerBrandCatalog}
                style={{
                  textTransform: "none",
                  fontWeight: 700,
                  fontSize: 16,
                  background: "#FF6008",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "8px"
                }}
              >
                Create Brand
              </CustomOrangeButton>
            </Box>
          </StyledInnerBox>
        </BoxNoScroll>
        <BoxNoScroll style={{ paddingBottom: 20, overflow: "auto", marginTop: 1.75 }}>
          <StyledTableBox style={{ borderRadius: "8px", borderTopRightRadius: 0, borderTopLeftRadius: 0, padding: "30px", overflowY: "auto", overflowX: "hidden", position: "relative", background: "white" }}>
            {this.renderTable()}
            <Typography style={{ color: "rgb(61 168 46)" }}>{this.state.successMsg}</Typography>
          </StyledTableBox>
        </BoxNoScroll>
      </>
    );
  }
  renderCreateBrandScreen() {
    return (
      <Box data-test-id="component" sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "80px" }}>
          <img src={cross} style={webStyles.image} />
          <Box style={webStyles.textOne}>Welcome</Box>
          <Box style={webStyles.textTwo}>Start adding your brand now!</Box>
          <CustomOrangeButton style={webStyles.addBtn} onClick={this.handleGoToSellerBrandCatalog}>
            Create Brand
          </CustomOrangeButton>
        </Box>
      </Box>
    );
  }

  brandListPage() {
    return (
      <StyledOuterBox>
        {this.state.loading ? (
          <Box style={webStyles.loader}>
            <Loader2 loading={this.state.loading} />
          </Box>
        ) : (
          <>
            {this.state.listResponse.length > 0 && this.renderListScreen()}
            {this.state.listResponse.length === 0 && this.renderCreateBrandScreen()}
          </>
        )}
      </StyledOuterBox>
    );
  }
  pageTorender() {
    switch (this.state.activeStep) {
      case "brandList":
        return this.brandListPage();
      default:
        return <></>;
    }
  }
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        <ToastContainer/>
        <Box data-test-id="brandMgmt" sx={{ padding: "20px 0px 15px 0px", fontSize: "18px", fontWeight: 400, fontFamily: `"Lato", sans-serif` }}>Brand Management</Box>
        <BoxNoScroll style={{ paddingBottom: 20, overflow: "auto" }}>
          {this.pageTorender()}
          {this.state.openModal && (
            <Modal open={true}>
              <Box sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center " }}>
                <Box sx={{ width: "299px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", borderRadius: "8px", padding: "50px" }} style={{ background: "white" }}>
                  <Box sx={{ marginBottom: "21px", textAlign: "center", fontFamily: `"Lato", sans-serif` }}>{"Are you sure you want to delete?"}</Box>
                  <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                    <CustomDeleteButton id="modalClose" data-test-id="cancel" style={{ backgroundColor: "green" }} onClick={() => this.handleModalClose()}>
                      Cancel
                    </CustomDeleteButton>
                    <CustomDeleteButton id="modalClose" data-test-id="delete" style={{ backgroundColor: "red" }} onClick={() => this.handleDelete(this.state.rowId)}>
                      Delete
                    </CustomDeleteButton>
                  </Box>
                </Box>
              </Box>
            </Modal>
          )}
          {this.state.openErrorPopup && (
            <Modal open={true}>
              <Box sx={webStyles.boxContainer}>
                <Box sx={webStyles.innerBox}>
                <Box style={webStyles.closeBox}>
                  <CloseIcon data-test-id="closeErrIcon" onClick={this.handleDeleteErrModal} style={webStyles.close}/>
                </Box>
                <CustomBox sx={webStyles.errorBox}>
                    <img style={webStyles.errorImage} src={errorImg} alt="error image" />
                </CustomBox>
                <Box sx={webStyles.delBrandErr}>{this.state.delBrandErr}</Box>
                </Box></Box>
            </Modal>
          )}
        </BoxNoScroll>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyles = {
  footer_container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    color: "#B1B2B4",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px"
  },
  footer_item: {
    padding: "50px 0px 10px 20px",
    fontWeight: 600
  },
  loader: { height: "80vh" },
  textOne: {
    fontFamily: `"Lato-Medium", sans-serif`,
    marginTop: 10,
    fontWeight: 400,
    fontSize: 16,
    color: "#33333A"
  },
  textTwo: {
    fontFamily: `"Lato-Medium", sans-serif`,
    marginTop: 5,
    fontWeight: 400,
    fontSize: 16,
    color: "#33333A"
  },
  addBtn: {
    margin: '20px 0',
    fontSize: "16px"
  },
  image: {},
  closeBox: { 
    fontFamily: `"Lato", sans-serif`, 
    textAlign: "right" as const,
    margin:'-20px -20px 20px 0',
  },
  close: {
    cursor:'pointer', 
    padding: '4px',
    fontSize: '24px'
  },
  delBrandErr: { 
    fontSize:'16px',
    fontWeight:600, 
    marginBottom: "21px", 
    textAlign: "center", 
    fontFamily: `"Lato", sans-serif` 
  },
  boxContainer: { 
    width: "100%", 
    height: "100vh", 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center " 
  },
  innerBox: { 
    width: "300px", 
    height: "auto", 
    display: "flex", 
    flexDirection: "column", 
    justifyContent: "center", 
    borderRadius: "8px", 
    padding: "30px",
    background: "white" 
  },
  errorBox: {
    marginBottom: '24px', 
    textAlign:'center'
  },
  errorImage: {
    height: '30px', 
    width: '30px'
  }
};

const StyledTableBox = styled(Box)({
  borderRadius: "8px", 
  borderTopRightRadius: 0, 
  borderTopLeftRadius: 0, 
  padding: "30px", 
  overflowY: "auto", 
  overflowX: "hidden", 
  position: "relative", 
  background: "white",
  '@media (max-width:600px)': {
    padding:'20px 0 !important'
  } 
})

const StyledOuterBox = styled(Box)({
  borderRadius: "8px", 
  display: "flex", 
  flexDirection: "column", 
  overflowY: "auto", 
  overflowX: "hidden", 
  position: "relative", 
  background: "white",
  '@media (max-width:600px)': {
    padding:'20px'
  }
})

const StyledInnerBox = styled(Box)({
  borderRadius: "8px", 
  borderBottomRightRadius: 0, 
  borderBottomLeftRadius: 0, 
  display: "flex", 
  flexDirection: "column", 
  padding: "30px", 
  overflowY: "auto", 
  overflowX: "hidden", 
  position: "relative", 
  background: "white",
  '@media (max-width:600px)': {
    padding:'12px 0px'
  }
})
// Customizable Area End
