//@ts-nocheck

import React from "react";

// Customizable Area Start
import { Box, Input, Typography, Button, Modal } from "@material-ui/core";
import { BoxNoScroll, CustomDeleteButton } from "../CustomizedElementStyle";
import { errorImage } from "../../../bulkuploading/src/assets";
import { file } from "../assets";
import CircularProgress from "@material-ui/core/CircularProgress";
import { styled } from "@material-ui/styles";
// Customizable Area End

import SellerDragDropFileController, { Props } from "./SellerDragDropFileController";
import { CustomOrangeButton } from "../../../../components/src/CommonButton";

export default class SellerDragDropFile extends SellerDragDropFileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  DeleteVerifyModal2 = () => {
    return (
      <Modal open={this.state.showVerifyDeleteModal1}>
        <Box sx={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center " }} style={{ background: "rgba(0,0,0,0.1)" }}>
          <Box sx={{ width: "300px", height: "auto", display: "flex", flexDirection: "column", justifyContent: "center", borderRadius: "8px", padding: "50px" }} style={{ background: "white" }}>
            <Box data-test-id="errorMsgs" sx={{ marginBottom: "20px", textAlign: "center", fontFamily: `"Lato", sans-serif` }}>
              {this.state?.errorMsg2?.length ? "Upload Failed: The file contains invalid data. Please fix the highlighted errors and try again." : "Uploaded Successfully"}
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <CustomDeleteButton data-testid="deleteConfirmRef" style={{ background: !this.state?.errorMsg2?.length && "green" }} onClick={() => this.handleCloseDeleteModal()}>
                OK
              </CustomDeleteButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };

  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        {this.DeleteVerifyModal2()}
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box style={webStyle.headerStyle}>Product Catalog</Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}></Box>
        </Box>
        <BoxNoScroll style={{ paddingBottom: 20, overflow: "auto" }}>
          <Box style={webStyle.headingBoxStyle as React.CSSProperties}>
            <StyledText>Upload template</StyledText>
            <StyledDownloadText data-test-id="downloadTemplate" onClick={this.handleDownloadTemplate}>Download Template</StyledDownloadText>
            <StyledDownloadText data-test-id="uploadGuide" style={{margin: '-16px 0 10px'}} onClick={this.handleUploadGuide}>Download Upload Guide</StyledDownloadText>
            <ParaText>Format your CSV file correctly for a smooth upload! If you need assistance with uploading your products, please contact seller support to learn more. </ParaText>

            <Box>
              <Input
                data-test-id="inputFIleBrandRef"
                style={{
                  display: "none"
                }}
                type="file"
                inputProps={{
                  "aria-label": "upload documents",
                  ref: this.inputBrandRef,
                  accept: ".csv"
                }}
                onChange={event => this.handleUploadBrandFile(event)}
                value=""
              />
              <label>
                <Button
                  style={{
                    width: "100%",
                    height: 300,
                    padding: "20px 0px",
                    margin: "10px 0px",
                    border: "2.5px dashed #c9d2dc",
                    borderRadius: "5px",
                    textTransform: "none",
                    fontFamily: `"Lato", sans-serif`
                  }}
                  onClick={this.handleOpenFileInput}
                  fullWidth
                  variant="outlined"
                  data-test-id="openFileRef"
                >
                  <Box>
                    <img src={file} />
                    <Box sx={{ fontSize: "27px", fontWeight: 700, fontFamily: `"Lato", sans-serif` }}> upload your file</Box>
                    <Box sx={{ color: "#9a9a9a", fontFamily: `"Lato", sans-serif`, fontSize: 16 }}>Only CSV template file allowed to upload here</Box>
                  </Box>
                </Button>
                {/* <CircularProgress /> */}
              </label>
              {this.state.branding_tradmark_certificate && (
                <Box sx={{ marginBottom: "10px", textAlign: "start", fontFamily: `"Lato", sans-serif` }}>
                  File:
                  {this.state.branding_tradmark_certificate && this.state.branding_tradmark_certificate.name}
                </Box>
              )}

              {!!this.state?.errorMsg2?.length &&
                this.state?.errorMsg2.map((error, index) => {
                  return (
                    <Box key={index} sx={{ display: "flex", flexDirection: "row", flexWrap: "no-wrap", alignItems: "center" }}>
                      <Box sx={{ marginRight: "10px" }}>
                        <img src={errorImage} />
                      </Box>
                      <Box>
                        <Typography variant="body1" style={{ color: "#d74d4d", marginTop:'6px' }}>
                          {error}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              {this.state.isLoading && (
                <>
                  <Box width={"90%"} display={"flex"} justifyContent={"center"} alignContent={"center"}>
                    <CircularProgress />
                  </Box>
                </>
              )}
              {this.state?.successMsg2 && (
                <Box>
                  <Typography variant="body2" style={{ color: "rgb(61 168 46)", fontSize: 14 }}>
                    {this.state?.successMsg2}
                  </Typography>
                </Box>
              )}
              <StyledButtonContainer>
                <BlackButton data-test-id="blackButton" onClick={() => this.handleCancel()}>
                  Cancel
                </BlackButton>
                <CustomOrangeButton data-test-id="orangeButton" onClick={() => this.handleFileOnSubmit()}>
                  Confirm
                </CustomOrangeButton>
              </StyledButtonContainer>
            </Box>
          </Box>
        </BoxNoScroll>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  headingBoxStyle: {
    // minHeight: 753,
    borderRadius: "8px",
    background: "white",
    display: "flex",
    flexDirection: "column",
    padding: "30px",
    position: "relative"
  },
  subHeaderStyle: {
    padding: "2rem 20px",
    fontSize: "27px",
    fontWeight: 700,
    fontFamily: "Lato, sans-serif",
    borderBottom: "1px solid #F2F3F4"
  },
  headerStyle: {
    padding: "20px 0px 15px 0px",
    fontSize: "18px",
    fontWeight: 400,
    fontFamily: "Lato, sans-serif"
  },
  btnContainer: { display: "flex", justifyContent: "end", marginTop: "30%", gap: "24px" },
  footerNav: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    color: "#B1B2B4",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px"
  },
  itemNav: {
    padding: "50px 0px 10px 20px",
    fontWeight: 600
  }
};

const StyledText = styled(Box)({
  fontWeight: 700,
  fontFamily: "Lato, sans-serif",
  padding: "0 0 20px",
  fontSize: "27px",
  borderBottom: "1px solid #F2F3F4",
  "@media (max-width:600px)": {
    fontSize: "28px"
  }
});

const StyledButtonContainer = styled(Box)({
  display: "flex",
  justifyContent: "end",
  marginTop: "30%",
  gap: "24px",
  padding: "10px 0px",
  width: "100%",
  "@media (max-width:600px)": {
    justifyContent: "center"
  }
});

const StyledDownloadText = styled(Box)({
  color: '#ff6008',
  cursor:'pointer',
  margin: '30px 0',
  fontFamily:"Lato, sans-serif"
})

const BlackButton = styled(Button)({
  color: "white",
  fontSize: "16px",
  fontWeight: 700,
  padding: "8px 30px",
  backgroundColor: "#444",
  textTransform: "none",
  borderRadius: "8px",
  fontFamily: "lato",
  "&:hover": {
    backgroundColor: "#444",
    color: "white"
  },
  "@media (max-width:600px)": {
    height: "40px",
    fontSize: "14px",
    width: "100px"
  }
});

const OrangeButton = styled(Button)({
  backgroundColor: "#FF6008",
  padding: "8px 30px",
  width: "150px",
  borderRadius: "8px",
  fontFamily: "lato",
  color: "white",
  fontSize: "20px",
  fontWeight: 700,
  height: "60px",
  textTransform: "none",
  "&:hover": {
    color: "white",
    backgroundColor: "#FF6008"
  },
  "@media (max-width:600px)": {
    width: "100px",
    height: "40px",
    fontSize: "14px"
  }
});

const ParaText = styled(Typography)({
  padding: "0px 0 16px",
  fontSize: "14px",
  fontWeight: 800,
  fontFamily: "Lato, sans-serif",
  color: "gray",
  wordBreak: "break-word",
  "@media (max-width:1240px)": {
    whiteSpace: "unset"
  }
});
// Customizable Area End
