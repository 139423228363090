import React from "react";

// Customizable Area Start
import { theme } from "../../dashboard/src/CustomizedElementStyle";
import { ThemeProvider, styled } from "@material-ui/core/styles";
import { Box, Container, Grid, Divider } from "@material-ui/core";
import WhyusController, { Props } from "./WhyusController.web";
import DOMPurify from "dompurify";
import { objectsImg } from "./EmailAccountRegistrationController";
import SellerFooter from "./SellerFooter.web";
import SellerLandingNavbar from "./SellerLandingNavbar.web";
// Customizable Area End

class Whyus extends WhyusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  backgroundImg = () => {
    return(
        <CustomImgBox>
          <img src={objectsImg} width={"90%"} />
        </CustomImgBox>
    )
  }
  // Customizable Area End

  // Customizable Area Start
  render() {
    // Customizable Area Start
    return (
      <>
      <SellerLandingNavbar navigation={this.props.navigation} id={this.props.id} />
        <ThemeProvider theme={theme}>
          <Box style={{  justifyContent: "center", width: "100%", display: "flex" }}>
          {this.backgroundImg()}
            <Container style={{ position: "relative", textAlign: "start", maxWidth: "96vw", paddingLeft: "50px", marginLeft: 0 }}>
                {this.state.whyUsContent &&
                <CustomText container>
                <Grid item lg={12} sm={12} xs={12} style={{marginBottom:'60px'}}>
                  <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.whyUsContent.content)}} data-test-id="whyusContent" >
                  </div>
                  </Grid>
                  </CustomText>
                }
            </Container>
          </Box>
          <Divider />
          <SellerFooter navigation={this.props.navigation} id={this.props.id} />
        </ThemeProvider>
      </>
    );
    // Customizable Area End
  }
  // Customizable Area End
}

// Customizable Area Start

const CustomText = styled(Grid)({
  marginLeft: "7px",
  marginTop: "160px" ,
  '@media (max-width:780px)' : {
    marginTop: "240px"
  }
})

const CustomImgBox = styled(Box)({
  top: 176, 
  left: 25, 
  position: "absolute" as const,
  zIndex: 0 ,
  '@media (max-width:600px)' : {
    top: "260px"
  }
})

export default Whyus
// Customizable Area End
