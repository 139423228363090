// Customizable Area Start
import React from "react";
import BrandStoreController, { ISection, IStoreMenuList, StoreProduct, ProductData, Props, configJSON } from "./BrandStoreController.web";
import { Box, Grid, Typography, Container, Card, CardMedia, styled } from "@material-ui/core";
import { CustomProductSliderTwo, styles } from "./StoreManagement/TemplatePreview.web";
import FooterWeb from "../../landingpage/src/Footer.web";
import HeaderWeb from "../../landingpage/src/Header.web";
import KeyboardArrowRightIcon from "@material-ui/icons//KeyboardArrowRight";
import { rapidLogo, starIcon } from "../../catalogue/src/assets";
import { CustomWhiteButton, OrangeButton } from "./CustomizedElementStyle";
import { calculateDiscountPercentage } from "../../catalogue/src/BuyerSearchProduct/ProductListController.web";
import { ExpressLogo } from "../../productquickview/src/assets";
import { Pagination } from "@material-ui/lab";
import { Loader2 } from "../../../components/src/Loader.web";
// Customizable Area End

class BrandStore extends BrandStoreController {
    constructor(props:Props){
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
      // Customizable Area Start
      renderHome = () => {
        return(
          <>
            {this.state.bannerImage && (
                <Typography data-test-id="bannerImg" component="a" target="_self" href={this.state.bannerUrl} style={styles.bannerSec}>
                  <img style={styles.bannerImg} src={this.state.bannerImage} alt="banner_img" />
                  <Typography style={styles.bannerName}>{this.state.storeBannerName}</Typography>
                </Typography>
            )}
            {this.state.storeSectionOne && (
              <Grid container style={webStyle.secOneContainer}>
                {this.state.storeSectionOne.map((item: ISection) => {
                  return (
                    <>
                      <Grid item xs={12} sm={12} md={4} lg={4} xl={4} spacing={2} style={styles.card}>
                      <Typography data-test-id="sectionOne" component="a" target="_self" href={item.data.attributes.grid_url} >
                        <img src={item.data.attributes.grid_image} alt="img" style={styles.sectionOneImg} />
                        <Typography gutterBottom style={styles.cardText} variant="h5" component="h2" align="center">
                          {item.data.attributes.grid_name}
                        </Typography>
                      </Typography>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            )}
            {this.state.storeSectionTwo && (
              <Grid container style={styles.sectionTwoContainer}>
                {this.state.storeSectionTwo.map((item: ISection) => {
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} spacing={2} style={styles.cardTwo}>
                      <Typography component="a" target="_self" href={item.data.attributes.grid_url}>
                      <img style={styles.gridImage} src={item.data.attributes.grid_image} alt="img" />
                      <Box style={styles.sectionTwoText}>
                        <Typography style={styles.name}>{item.data.attributes.grid_name}</Typography>
                        <Box style={styles.linkBox}>
                          <Typography style={styles.linkText}>
                            {configJSON.learnMoreText} <KeyboardArrowRightIcon style={styles.icon} />{" "}
                            <span style={styles.buy}>
                              {configJSON.buyText}{" "}
                              <Typography>
                                <KeyboardArrowRightIcon style={styles.icon} />
                              </Typography>
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                      <CustomWhiteButton style={{...styles.exploreBtn, ...webStyle.menuLink}}>{configJSON.exploreText}</CustomWhiteButton>
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            <Box style={webStyle.sliderWrap}>
            <CustomProductSliderTwo {...this.productSliderSettings}>
              {this.state.prodList && this.state.prodList.map((item: StoreProduct, index: number) => {
                const  {product_image, product_rating} = item.data?.attributes
                const {product_title, retail_price, mrp } = item.data?.attributes?.product_content?.product_attributes
                return(
                <div key={index}>
                  <div data-test-id="prodList" onClick={() => this.handleProduct(item.data.id)} style={{...styles.sliderSec, ...webStyle.menuLink}}>
                    <img src={product_image} alt={"image"} style={styles.sliderImg} />
                    <OrangeButton style={styles.newButton}>{configJSON.newText}</OrangeButton>
                  </div>
                  <Box style={styles.desc}>
                    <Typography style={styles.itemName}>{product_title}</Typography>
                    <Typography style={styles.priceBox}>
                      {configJSON.aedText} <span style={styles.cardPriceText}>{retail_price}</span>
                    </Typography>
                    {calculateDiscountPercentage(mrp,retail_price)>0 ?
                    <Box style={styles.priceWrapper}>
                      <Typography style={styles.price}>{mrp}</Typography>
                      <Typography style={styles.discountText}>{calculateDiscountPercentage(mrp,retail_price)}% {configJSON.offText}</Typography>
                    </Box>
                    : <Box style={styles.priceMargin}>
                    <Typography style={styles.price}>{""}</Typography>
                    <Typography style={styles.discountText}>{""}</Typography>
                  </Box>
              }
                    <Box style={styles.bottom}>
                      <img style={styles.expressImg} src={ExpressLogo} alt="express_img" />
                      <Box style={styles.flex}>
                        <Typography style={styles.rating}>
                          {product_rating?.average_rating || 0}{" "}
                          <span style={styles.starSec}>
                            <img src={starIcon} alt="start" />
                          </span>
                        </Typography>
                        <Typography style={styles.offerPrice}>{product_rating?.total_reviews || 0}</Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
                )
              })}
            </CustomProductSliderTwo>
            </Box>
            {this.state.storeSectionThree && 
              <Grid container spacing={3}>
                {this.state.storeSectionThree.map((item: ISection) => {
                  return (
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <Typography component="a" target="_self" href={item.data.attributes.grid_url} >
                      <Box style={styles.productCard}>
                        <img style={styles.sectionThreeImg} src={item.data.attributes.grid_image} alt={"img"} />
                        <Box style={styles.rightSide}>
                          <Box style={styles.right}>
                            <Typography style={styles.gridName}>{item.data.attributes.grid_name}</Typography>
                            <CustomWhiteButton style={{...styles.shopBtn, ...webStyle.menuLink}}>{configJSON.shopNowText}</CustomWhiteButton>
                          </Box>
                        </Box>
                      </Box>
                    </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            }
          </>
        )
      }
      // Customizable Area End
    render() {
      // Customizable Area Start
      const roundCount = this.getCount()

      // Customizable Area End
        return(
          // Customizable Area Start
            <>
            <HeaderWeb navigation={this.props.navigation} id={this.props.id} />
            {this.state.apiInprogress ? (
              <Loader2 loading={this.state.apiInprogress} />
            ) : (
              <></>
            )}
            <Box style={styles.modal} data-test-id="component">
            <Box style={styles.modalWrapper}>
            <Box style={styles.storeSection}>
              <Box style={styles.position}>
                {this.state.storeLogo && 
                <Box style={styles.storeBox}>
                  <Box>
                    <img src={this.state.storeLogo} alt="img" style={styles.logo} />
                    <Typography variant="h6" style={styles.logoName}>
                      {this.state.storeName}
                    </Typography>
                  </Box>
                </Box>
                }
                {this.state.coverImage && 
                  <img src={this.state.coverImage} alt="cover_img" style={styles.storeCover} />
                }
              </Box>
            </Box>
            <Box style={styles.navBar}>
              <Box style={styles.menuContainer}>
                <Typography onClick={this.handleHomeMenu} style={this.getMenuStyle("home" as string)}>Home</Typography>
                {this.state.storeMenuList.map((item: IStoreMenuList) => {
                  return (
                    <>
                      <Typography data-test-id="storeMenu" style={this.getMenuStyle(item.menuName as string)} onClick={() => this.handleMenuClick(item.id, item.menuName)}>{item.menuName}</Typography>
                    </>
                  );
                })}
              </Box>
            </Box>
            {!this.state.isMenuOpen ? this.renderHome() : 
            <>
            <Grid container spacing={2} style={webStyle.productContainer}>
              {this.state.storeProductList && this.state.storeProductList.map((product: ProductData) => {
                const productDetails = product.attributes?.product_content?.product_attributes;
                return(
                  <Grid data-test-id="menuProductList" item xs={12} sm={6} md={3} lg={3} key={product.id}>
                      <ContainerBox >
                        <Card style={webStyle.mainCard}>
                          <Typography style={webStyle.newBtn}>New</Typography>
                          <CardMedia
                            onClick={() => this.handleProduct(product.id)}
                            style={webStyle.cardImage}
                            component="img"
                            image={product.attributes?.product_image}
                            alt={productDetails.product_title}
                            data-test-id="cardMediaRef"
                          />
                        </Card>
                        <Typography style={webStyle.mobDetails}>
                          {productDetails.product_title || 'N/A'}
                        </Typography>
                        <Typography style={webStyle.discountedPrice}>
                          AED <span style={webStyle.offer}>{productDetails?.retail_price || 0}</span>      </Typography>
                      {calculateDiscountPercentage(productDetails?.mrp,productDetails?.retail_price)>0 && <Typography style={webStyle.ogPrice}>
                          <span style={webStyle.mrp}>
                            {productDetails?.mrp}{" "}
                          </span>{" "}
                          <b style={webStyle.discount} data-test-id="discountRef"> &nbsp; {calculateDiscountPercentage(productDetails?.mrp,productDetails?.retail_price)}% OFF</b>
                        </Typography>}
                        <Box style={webStyle.last}>
                        <img data-test-id="rapidButtonRef" style={webStyle.rapidImg} src={rapidLogo} alt="express_img" />
                        <Box style={{ display: "flex" }}>
                          <Typography style={webStyle.rateBox}>
                          {productDetails.product_rating?.average_rating || 0}{" "}
                            <span style={webStyle.starImg}>
                              <img src={starIcon} alt="start" />
                            </span>
                          </Typography>
                          <Typography data-test-id="ratingRef" style={webStyle.ratingText}>
                            {productDetails.product_rating?.total_reviews || 0}
                          </Typography>
                        </Box>
                      </Box>
                    </ContainerBox>
                  </Grid>
                  )
                })
              }
            </Grid>
            <Pagination
            style={webStyle.paginationStyle}
            data-test-id="pagination"
            page={this.state.pageNumber}
            count={roundCount}
            onChange={(event: React.ChangeEvent<unknown>, value: number) => {
              this.handlePagination(value)
            }}
            shape="rounded"
            siblingCount={0}
            boundaryCount={1}
         />
         </>
            }
          </Box>
        </Box>

        <FooterWeb navigation={this.props.navigation} id={this.props.id} categories={[]} token={""}/>
            </>
            // Customizable Area End
        )
    }
}
// Customizable Area Start
const styles2 = {
  cardContainer: {
    width: "100%",
    border: "1px solid rgb(229, 229, 229)",
    padding: "10px",
    borderRadius: "8px",
    '&:hover': {
      boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)"
    }
  },
}

export const webStyle = {
  sliderWrap: {
    margin: "60px -6px 30px",
  },
  mainCard: {
    width: "100%",
    height: "280px",
    borderRadius: "8px",
    border: "1px solid #eee",
    position: "relative" as const,
    '@media (max-width:600px)': {
      padding: 0,
      marginLeft: '0'
    },
  },
  cardImage: {
    alignItems: "center",
    justifyContent: "center",
    objectFit:'cover' as const ,
    height: "100%",
  },
  mobDetails: {
    fontSize: "16px",
    marginTop: "10px",
    marginLeft: "10px",
    color: "#33333a",
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    textOverflow: 'ellipsis',
    fontFamily:"Lato, sans-serif",
    fontWeight: 400
  },
  discountedPrice: {
    fontSize: "14px",
    marginTop: "10px",
    marginLeft: "10px",
    color: "#ff6008",
    fontFamily:"Lato, sans-serif",
    fontWeight: 400
  },
  ogPrice: {
    fontSize: "10px",
    marginLeft: "10px",
  },
  rapidImg: {
    width:'67px',
    height: '24px',
    marginLeft:'10px'
  },
  rateBox: {
    padding: "2px 6px",
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    backgroundColor: "#565656",
    color: "#ffffff",
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
    fontWeight: 700 as const,
  },
  starImg: {
    marginLeft: "5px",
  },
  last: {
    marginTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ratingText: {
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
    marginLeft: "5px",
    display: "flex",
    alignItems: "center",
    color: "#ff6008",
    fontWeight: 400 as const,
  },
  offer: {
    color: "#ff6008",
    fontWeight: 700 as const,
    fontFamily: `"Lato",sans-serif`,
    fontSize: "14px",
  },
  mrp: { 
    color: "grey", 
    textDecoration: "line-through", 
    fontSize:'14px', 
    fontWeight: 400, 
    fontFamily: `"Lato",sans-serif`,
  },
  discount: { 
    color: "#33333a", 
    fontSize:'14px', 
    fontWeight: 700, 
    fontFamily: `"Lato",sans-serif`,
  },
  menuLink: {
    cursor: 'pointer'
  },
  activeMenuStyle: {
    fontSize: "14px",
    fontFamily: "Lato, sans-serif",
    fontWeight: 600,
    color: "#fff",
    textTransform: "uppercase" as const,
    margin: "0 40px",
    textDecoration:'underline' as const,
    cursor: 'pointer',
    textAlign: "center" as const
  },
  inactiveMenuStyle: {
    fontSize: "14px",
    fontFamily: "Lato, sans-serif",
    fontWeight: 500,
    color: "#fff",
    textTransform: "uppercase" as const,
    margin: "0 40px",
    cursor: 'pointer',
    textAlign: "center"  as const
  },
  secOneContainer: {
    margin: "30px 0",
    gridGap: "12px",
    flexWrap: "nowrap" as const
  },
  paginationStyle: {
    marginTop: "40px",
    display: "flex",
    justifyContent: "center"
  },
  productContainer: {
    padding: '0 50px'
  },
  newBtn: {
    width: "20%",
    textAlign: "center" as const,
    marginLeft: "78%",
    background: "#fb620f",
    color: "white",
    borderRadius: "3px",
    fontSize: "9px",
    padding: "1.5px",
    position: "absolute" as const ,
    top: "5px",
  }
}

const ContainerBox = styled(Container)({
  width: "100%",
    border: "1px solid rgb(229, 229, 229)",
    padding: "10px",
    borderRadius: "8px",
    '&:hover': {
      boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)"
    }
})

export default BrandStore
// Customizable Area End