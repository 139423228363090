// @ts-nocheck
import React from "react"

// Customizable Area Start
import ProductQuickviewController, {
  ICustomFieldContent,
  IReviews,
  Props, configJSON,
} from "./ProductQuickviewController";
import { Loader2 } from "../../../components/src/Loader.web";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FooterWeb from "../../landingpage/src/Footer.web";
import { Pagination } from "@material-ui/lab";
import {
  Container,
  Box,
  Button,
  Grid,
  TableCell,
  TableRow,
  withStyles,
  Table,
  Typography,
  styled,
  Modal,
  IconButton,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Chip,
  Dialog
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import HeaderWeb from "../../landingpage/src/Header.web";
import { Rating } from "@material-ui/lab";
import Slider from "react-slick";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "typeface-lato";
import { ExpressLogo } from "./assets";
import { calculateDiscountPercentage } from "../../catalogue/src/BuyerSearchProduct/ProductListController.web";
import { BoxNoScroll } from "../../dashboard/src/CustomizedElementStyle";
import Close from "@material-ui/icons/Close";
import { successImg } from "../../user-profile-basic/src/assets";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 20,
    borderRadius: 5,
    border: "1px solid #979797"
  },
  colorPrimary: {
    backgroundColor: "#fff",
  },
  bar: {
    borderRadius: 6,
    backgroundColor: '#FF8808',
  },
}))(LinearProgress);

const StyledRating = withStyles({
  iconFilled: {
    color: '#FF8808',
  },
  iconHover: {
    color: '#FF8808',
  },
  iconEmpty: {
    color: '#FF8808',
  },
})(Rating);


const CustomSlider2 = styled(Slider)({
  "& .slick-slide": {
    height: "unset",
  },
  "& .slick-prev": {
    color: "#000",
    backgroundColor: "#fff",
    padding: "6px",
    fontSize: "12px",
    borderRadius: "50%",
    marginLeft: 20,
    zIndex: 999,
    boxShadow: "rgba(0, 0, 0, 0.2) 1px 2px 10px",
  },
  "& .slick-next": {
    color: "#000",
    backgroundColor: "#fff",
    padding: "6px",
    fontSize: "12px",
    borderRadius: "50%",
    boxShadow: "rgba(0, 0, 0, 0.2) 1px 2px 10px",
    marginRight: 20,
    zIndex: 999,
    //@ts-ignore
    "& >path": {},
  },
  "& .slick-prev::before": {
    fontSize: 35,
    opacity: 1,
    color: "rgb(255, 255, 255)",
  },
  "& .slick-next::before": {
    fontSize: 35,
    opacity: 1,
    color: "rgb(255, 255, 255)",
  },
});
const settings = {
  centerMode: false,
  infinite: false,
  dots: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  rows: 1,
  slidesPerRow: 1,
  initialSlide: 0 ,
  prevArrow: <ArrowBackIosIcon style={{ color: "black", fontSize: "18px" }} />,
  nextArrow: (
    <ArrowForwardIosIcon style={{ color: "black", fontSize: "18px" }} />
  ),
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 786,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1
      },
    },
  ],
};
// Customizable Area End

export default class ProductQuickview extends ProductQuickviewController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  addToCartModal = () => {
    return (
      <Modal open={this.state.openModal}>
        <Box
          className={this.props.classes.modalBox}
        >
          <Box
            className={this.props.classes.modalWrapper}
          >
            <Box
              className={this.props.classes.iconBox}
            >
              <IconButton
                aria-label="delete"
                className={this.props.classes.icon}
                onClick={this.handleCloseModal}
              >
                <Close className={this.props.classes.closeIcon} />
              </IconButton>
            </Box>
            <Box
              className={this.props.classes.successIconWrapper}
            >
              <img
                src={successImg}
                alt="rounded Circl"
                className={this.props.classes.successImg}
              />
            </Box>
            <Typography
              className={this.props.classes.successText}
            >
              {configJSON.successfullyText}
            </Typography>
            <Typography
              className={this.props.classes.successPara}
            >
              {this.state.amount === 1 ? '1 Item ' : `${this.state.amount} Items `}{configJSON.oneItemAddedToCartText}
            </Typography>

            <Box
              className={this.props.classes.buttonBox}
            >
              <Button
                data-testid="checkout"
                onClick={() => this.handleCheckout()}
                className={this.props.classes.checkOutBtn}
              >
                {configJSON.checkoutText}
              </Button>
              <Button
                data-testid="continueRef"
                onClick={() => this.handleCloseModal()}
                className={this.props.classes.continueBtn}
              >
                {configJSON.continueText}
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };

  showPercentageDiscount = (mrpValue = 0, retailPrice = 0) => {
    const percentage = calculateDiscountPercentage(
      Number(mrpValue),
      Number(retailPrice)
    );
    if (percentage > 0) {
      return `${percentage}% OFF`;
    } else {
      return "";
    }
  };

  selectVariantColor = () => {
    if (this.state.variantProducts) {
      return (
        <>
        {this.state.parentProduct  && <Box>
                  <div
                    style={{
                      width: "47px",
                      height: "47px",
                      marginRight: "14px",
                      borderRadius: "8px",
                      padding: "8px",
                      border:
                        ((this.state.selectedVariant === this.state.parentProduct.id) || !this.isVariantProduct())
                          ? "1px solid #FF6008"
                          : "1px solid #ccc",
                    }}
                  >
                    <img
                      alt="product_image"
                      src={
                        this.state.parentProduct.attributes?.product_image
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        fontFamily: `"Lato", sans-serif`,
                        cursor: "pointer",
                      }}
                      data-test-id={`colorImage`}
                      onClick={() => this.setProductData(this.state.parentProduct.id)}
                    />
                  </div>
                </Box>}
          {this.state.variantProducts?.map(
            (item, index) => {
              return (
                <Box key={`${index}-${item?.id}`}>
                  <div
                    style={{
                      width: "47px",
                      height: "47px",
                      marginRight: "14px",
                      borderRadius: "8px",
                      padding: "8px",
                      border:
                        ((this.state.selectedVariant === item.id) && this.isVariantProduct())
                          ? "1px solid #FF6008"
                          : "1px solid #ccc",
                    }}
                  >
                    <img
                      alt="product_image"
                      src={
                        item.attributes?.product_image
                      }
                      style={{
                        width: "100%",
                        height: "100%",
                        fontFamily: `"Lato", sans-serif`,
                        cursor: "pointer",
                      }}
                      data-test-id="variantColorImage"
                      onClick={() => this.getVariantProduct(item.id)}
                    />
                  </div>
                  <span
                    key={index}
                    data-test-id="option"
                    className={this.props.classes.label}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginRight: "14px",
                      fontFamily: `"Lato", sans-serif`,
                    }}
                  >
                    {item?.color}
                  </span>
                </Box>
              );
            }
          )}
        </>
      );
    }
  };

  displayedReviews = () => {
    return this.state.showMore ? this.state.customerReview : this.state.customerReview.slice(0,3)
  }

  renderHelpfulCount = (review: IReviews) => {
    return (
      review.attributes.helpful_count > '0' && <Typography className={this.props.classes.helpfulTypography}>
      {review.attributes.helpful_count}{ configJSON.peopleFoundUsefulText}
      </Typography>
    )
  }

  displayProductColor = () => {
    return (
      this.state.productContent?.product_color &&
      <TableRow id="productColor" className={this.props.classes.deliver}>
        <TableCell className={this.props.classes.tableHeader}>
          Product color
        </TableCell>
        <TableCell
          style={webStyle.tableCell}
        >
          <Box className={this.props.classes.text}>
            {this.state.productContent?.product_color}
          </Box>
        </TableCell>
      </TableRow>
    )
  }

  displayNumberOfPieces = () => {
    console.log("this.state.productviewList?.attributes?.product_content.size_and_capacity", this.state.productviewList?.attributes?.product_content.size_and_capacity)
    return (
      this.state.productviewList?.attributes?.product_content.size_and_capacity && this.state.productviewList.attributes.product_content.size_and_capacity.number_of_pieces && 
      <TableRow id="piecesNumber" className={this.props.classes.deliver}>
        <TableCell className={this.props.classes.tableHeader}>
          Number of Pieces
        </TableCell>
        <TableCell
          style={webStyle.tableCell}
        >
          <Box className={this.props.classes.text}>
            {this.state.productviewList.attributes.product_content.size_and_capacity.number_of_pieces}
          </Box>
        </TableCell>
      </TableRow>
    )
  }

  renderCustomerReviews = (reviewType: IReviews[]) => {
    const reviews = reviewType
    return(
      reviews.map((review:IReviews, index: number) => (
        <ListItem data-test-id="reviewList" key={index} alignItems="flex-start" className={this.props.classes.reviewItem}>
          <ListItemText
            primary={
              <>
              <Box className={this.props.classes.flex}>
              <ListItemAvatar style={{marginTop: 0}}>
                  <Avatar className={this.props.classes.avatar}>{review.attributes.reviewer.data.attributes.profile_picture || review.attributes.reviewer.data.attributes.first_name.charAt(0)}</Avatar>
                </ListItemAvatar>
                <Box>                                
                <Typography data-test-id="Customer-review-name" className={this.props.classes.fullName}>{review.attributes.reviewer.data.attributes.first_name} {review.attributes.reviewer.data.attributes.last_name}</Typography>
                <Typography variant="body2" color="textSecondary">{this.formatDate(review.attributes.created_at)}</Typography>
                </Box>
              </Box>
              <Box display={"flex"} className={this.props.classes.customerRatingHeading} alignItems={"center"}>
              <StyledRating
                  value={review.attributes.rating}
                  readOnly
                  emptyIcon={<StarBorderIcon fontSize="inherit" />}
                />
                <Typography variant="body1" className={this.props.classes.title}>{review.attributes?.title}</Typography>
              </Box>
              </>
            }
            secondary={
              <>
                <Typography variant="body2" color="textPrimary" className={this.props.classes.prodDescription}>
                {review.attributes?.description}
                </Typography>
                {this.renderHelpfulCount(review)}
                <Box className={this.props.classes.imagesContainer}>
                  {review.attributes?.review_images?.map((image, idx) => (
                    <img data-test-id="reviewImage" key={idx} style={{cursor:'pointer', padding:'10px'}} src={image} alt={`review ${index} image ${idx}`} onClick={() => this.handleViewReviewImages(image)}/>
                  ))}
                </Box>
                {this.renderHelpfulBtn(review)}
              </>
            }
            />
        </ListItem>
      ))
    )
  }

  renderReviews = () => {
    return(
      <>
      <Divider  height={"2px"} color={"gray"} style={{height: "2px", margin:"16px 0px"}}/>
      <Grid container
        display={'flex'}
        justifyContent="space-between"
        style={{padding: "20px 0px"}}
      >
        <Grid
          item
          xl={4}
          lg={4}
          md={12}
          xs={12}
          sm={12}
        >
          <Box>
            <Typography data-test-id="Customer-review-heading" className={this.props.classes.customerReviewHeading} gutterBottom>
              {configJSON.customerReviewText}
            </Typography>
            <Box className={this.props.classes.reviewHeader}>
              <StyledRating 
              style={{fontSize: "24px"}}
              precision={0.5}
              value={this.state.averageRating} 
              readOnly 
              emptyIcon={<StarBorderIcon fontSize="inherit" />}
              />
              <Typography className={this.props.classes.ratingRangeText}>
                {configJSON.ratingRangeText(this.state.averageRating)}
              </Typography>
            </Box>
            <Box className={this.props.classes.progressContainer}>
              {this.state.customerRating.map((item, index) => (
                <Grid container key={index} className={this.props.classes.progressLabel}>
                  <Grid item xs={2}>
                  <Typography className={this.props.classes.starLabel}>{item.label}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                  <BorderLinearProgress variant="determinate" value={item.value} className={this.props.classes.progress} />
                  </Grid>
                  <Grid item xs={1} style={{marginLeft:'12px'}}>
                  <Typography variant="body2" style={{marginLeft:'0px'}}>{item.value}%</Typography>
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Box>
        </Grid>

        {this.state.customerReview.length > 0 ?
        <Grid
          item
          xl={8}
          lg={8}
          md={12}
          xs={12}
          sm={12}
        > 
            <Typography variant="h4" className={this.props.classes.allReviews} gutterBottom>All reviews</Typography> 
          <List style={{ padding: 0}}>
            {this.state.customerReview.length > 10 ?
            this.renderCustomerReviews(this.state.customerReview)
            :
            this.renderCustomerReviews(this.displayedReviews())
          }
          </List>
          {this.state.customerReview.length > 3 && this.state.customerReview.length <= 10 && !this.state.showMore && 
                <Box className={this.props.classes.loadMoreButton}>
                  <Typography variant="body1" style={{color: "#FF8808", cursor:'pointer'}} onClick={this.handleSeeMoreReviewBtn}>
                    {configJSON.seeMoreReviewBtnText}
                  </Typography>
                  <NavigateNextIcon style={{color: "#FF8808", cursor:'pointer'}} />
                </Box>
          }
          {this.state.customerReview.length > 0 && this.state.showMore && (
            <Pagination
              style={webStyle.paginate}
              data-test-id="pagination"
              count={this.getReviewListCount()}
              page={this.state.reviewPageNumber}
              onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                this.setState({ reviewPageNumber: value }, () => this.getReviewsList(this.state.selectedVariant));
              }}
              shape="rounded"
              siblingCount={0}
              boundaryCount={1}
            />
          )}
        </Grid>
        :
        <Grid
          item
          xl={8}
          lg={8}
          md={12}
          xs={12}
          sm={12}
          className={this.props.classes.noReviews}
        >
          <Typography variant="h4" className={this.props.classes.allReviews} gutterBottom>No reviews yet</Typography>
        </Grid>
        }
      </Grid>
      </>
  )}

  renderHelpfulBtn = (review: IReviews) => {
    const isHelpful = review.attributes.helpful.helpful_by_current_user
    return(
      <Box  className={this.props.classes.helpfulChipContainer}>
        <Chip
        style={{backgroundColor: isHelpful ? '#ff6008': '#fff', color: isHelpful ? '#fff': '#000', border: isHelpful ? 'none' : '1px solid rgba(0, 0, 0, 0.23)', cursor:'pointer' }}
        data-test-id="helpfulBtn"  
        label="Helpful"
          variant="outlined"
          onClick={() => this.setHelpful(review.id, isHelpful)}
        />
      </Box>
    )
  }

  renderDescription = () => {
    const isDescriptionLong = this.state.productContent?.long_description.split(' ').length > 50
    const {classes} = this.props;
    return(
      <Typography data-test-id="description" className={this.props.classes.longDesc}>
        {this.state.isViewMoreClicked ? `${this.state.productContent?.long_description + ' ' }` : this.formattedText(this.state.productContent.long_description) + ' '}
        {isDescriptionLong && !this.state.isViewMoreClicked && <span data-test-id="viewMoreBtn" className={classes.viewMoreText} onClick={this.handleViewMore}>{configJSON.viewMoreText}</span>}
        {isDescriptionLong && this.state.isViewMoreClicked && <span data-test-id="viewLessBtn" className={classes.viewMoreText} onClick={this.handleViewMore}>{configJSON.viewLessText}</span>}
      </Typography>
    )
  }
    // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        {this.addToCartModal()}
        <BoxNoScroll style={{ overflow: "auto" }}>
          {this.state.apiInprogress ? (
            <Loader2 loading={this.state.apiInprogress} />
          ) : (
            <></>
          )}
          <HeaderWeb
            navigation={this.props.navigation}
            id={this.props.navigation}
            cartId={this.state.cartId}
            itemCount={this.state.totalItemCount}
          />
          <Container className={this.props.classes.container}>
            <Box className={this.props.classes.main}>
              <Grid container style={{ backgroundColor: "#e5e5e5" }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={this.props.classes.routeContainer}
                >
                    <Typography data-test-id="homeText" onClick={this.handleHome} className={`${this.props.classes.route} ${this.props.classes.link}`}>
                      Home{" "}
                    </Typography>
                    <Typography
                    onClick={() => this.handleGoToCategory(this.state.productviewList?.attributes?.category?.id,this.state.productviewList?.attributes?.category?.name)}
                      data-test-id={`URLL`}
                      className={`${this.props.classes.route} ${this.props.classes.link}`}
                    >
                      {`/ ${this.state.productviewList?.attributes.category?.name}`}
                    </Typography>
                  {this.state.productviewList?.attributes.sub_category ? (
                    <Typography
                      data-test-id={`URLM`}
                      className={this.props.classes.product}
                    >
                      {" "}
                      {`/ ${this.state.productviewList?.attributes.sub_category?.name}`}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
              <Box className={this.props.classes.box}>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box className={this.props.classes.sellerReviewBox}>
                        <Box className={this.props.classes.sellerReviewContainer}>Sold By : <span className={this.props.classes.reviewSpan}>{this.state.productviewList?.attributes?.seller?.data?.attributes?.company_or_store_name}</span></Box>
                        <Box className={this.props.classes.sellerRatingContainer}>
                          <Typography>
                            <span className={this.props.classes.prodRatingBox}>
                              <Rating
                                name="customized-color"
                                precision={0.5}
                                readOnly
                                value={Number(
                                  this.state.productviewList?.attributes?.seller
                                    ?.data?.attributes.seller_rating
                                    ?.average_rating
                                )}
                                className={this.props.classes.ratingMobile}
                              />
                              <p className={this.props.classes.reviews}>
                                {this.state.productviewList?.attributes?.seller
                                  ?.data?.attributes.seller_rating
                                  ?.total_reviews || 0}{" "}
                                reviews
                              </p>
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                </Grid>
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    xs={12}
                    sm={12}
                    className={this.props.classes.leftSide}
                  >
                    <Box style={{ width: '100%', background: "#F3F3F3", textAlign: "center",borderRadius:10 }}>

                      <img
                        src={this.state.mainImage}
                        alt="mainImage"
                        key=""
                        width="90%"
                        style={{
                          backgroundColor: "#F3F3F3",
                          maxWidth: "90%",
                          padding: 0,
                          margin: 0,
                          textAlign: "center",
                          height: "410px",
                          borderRadius: "6px",
                          paddingTop: "10%",
                          paddingBottom: "10%",
                        }}
                      />
                    </Box>
                    <Box style={webStyle.imgContainer}>
                      <CustomSlider2 {...settings}>
                        {this.state.productviewList?.attributes?.product_content?.image_urls?.map(
                          (item: string, index: number) => {
                            return (
                              <Box
                                data-test-id="categorySlideShow"
                                key={index + "categorySlideShow"}
                                sx={{
                                  padding: "0 10px",
                                  boxSizing: "border-box",
                                }}
                                onClick={()=> this.updateMainImgUrl1(item)}
                              >
                                <div
                                  style={{
                                    borderRadius: "10px",
                                    backgroundColor: "#fff",
                                    width: "100%",
                                    cursor: "pointer",
                                    border: this.getBorder(item)
                                      
                                  }}
                                >
                                  <img
                                    data-test-id={`mainImage${index}`}
                                    src={item}
                                    alt={item.imgSrc}
                                    style={webStyle.image}
                                    onClick={() => this.updateMainImgUrl1(item)}
                                  />
                                </div>
                              </Box>
                            );
                          }
                        )}
                      </CustomSlider2>
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    xs={12}
                    sm={12}
                    className={this.props.classes.rightContainer}
                  >
                    <Typography className={this.props.classes.productTitle}>
                      {this.state.productviewList?.attributes?.product_title}
                    </Typography>
                    <Box className={this.props.classes.ratingContainer}>
                      <Typography>
                        <span className={this.props.classes.rating}>
                          <Rating
                            name="customized-color"
                            precision={0.5}
                            value={Number(
                              this.state.productviewList?.attributes
                                ?.product_rating?.average_rating
                            )}
                            className={this.props.classes.productRating}
                            readOnly
                          />
                          <p className={this.props.classes.reviews}>
                            {this.state.productviewList?.attributes
                              ?.product_rating?.total_reviews || 0}{" "}
                            reviews
                          </p>
                          <img
                        src={ExpressLogo}
                        alt="express"
                        style={webStyle.logo}
                      />
                        </span>
                      </Typography>
                    </Box>
                    {this.state.productviewList?.attributes?.brand && this.state.productviewList?.attributes?.brand.store &&
                    <Typography data-test-id="store" style={webStyle.storeLink} onClick={() => this.handleStore(this.state.productviewList.attributes.brand.store.id)}>Visit the "{this.state.productviewList.attributes.brand.brand_name} store"</Typography>}
                    {((this.state.stockCount === 0 && !this.state.apiInprogress) || !this.state.isActive) && (
                      <Box>
                      <Typography className={this.props.classes.soldOutText}>{configJSON.soldOutText}</Typography>
                      <Typography variant="body1" className={this.props.classes.outOfStockText}>{configJSON.outOfStockItemText}</Typography>
                    </Box>
                    )} 
                    {this.state.stockCount !== 0 && !this.state.apiInprogress && this.state.isActive && (
                      <div style={{ display: "inline" }}>
                      <Typography className={this.props.classes.productPrice}>
                        AED {this.state.productContent?.retail_price}
                        <span className={this.props.classes.mrp}>
                          {this.state.productContent?.mrp}
                        </span>
                        <span className={this.props.classes.discount}>
                          {this.showPercentageDiscount(
                            this.state.productContent?.mrp,
                            this.state.productContent?.retail_price
                          )}
                        </span>
                      </Typography>
                      <Typography className={this.props.classes.tax}>
                        (all taxes)
                      </Typography>
                    </div>
                    )}
                    
                    <Box className={this.props.classes.bottom}>
                    {this.renderDescription()}
                    
                    {this.state.productVariantGroup?.length > 0 && (
                      <Grid>
                        <Typography className={this.props.classes.selectColor}>
                          Select variant
                        </Typography>

                        <Grid
                          style={webStyle.selectVariantGrid}
                        >
                          <React.Fragment>
                            {this.selectVariantColor()}
                          </React.Fragment>
                        </Grid>
                      </Grid>
                    )}
                    
                    <Box>
                      <h3
                        style={webStyle.quantity}
                      >
                        Quantity
                      </h3>

                      <Box
                        sx={webStyle.amountToggle}
                      >
                        <Button
                          className={this.props.classes.decrementBtn}
                          data-test-id="decrement"
                          onClick={() => this.setDecrease()}
                          disabled={this.state.stockCount === 0}
                        >
                          <RemoveIcon />
                        </Button>
                        <p
                          className={this.props.classes.amountText}
                          data-test-id="amount"
                        >
                          {this.state.amount}
                        </p>
                        <Button
                          className={this.props.classes.incrementBtn}
                          data-test-id="increment"
                          onClick={() => this.setIncrease(this.state.stockCount)}
                          disabled={this.state.stockCount <= this.state.amount}
                        >
                          <AddIcon />
                        </Button>
                      </Box>
                    </Box>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12} className={this.props.classes.btnContainer}>
                    <Button
                      variant="contained"
                      data-test-id="wishlistRef"
                      className={this.props.classes.wishlistBtn}
                      disabled={
                        this.state.wishlistAddInprogress ||
                        this.state.wishlistremoveInprogress
                      }
                      onClick={this.handleAddToWishlist}
                    >
                      {this.state.wishlistStatus ? (
                        <>
                          <FavoriteIcon
                          data-test-id="favourite"
                            className={this.props.classes.wishlistTextFav}
                          />{" "}
                          WISHLIST{" "}
                        </>
                      ) : (
                        <>
                          <FavoriteBorderIcon
                          data-test-id="unfavourite"
                          className={this.props.classes.wishlistTextUnFav}
                          />{" "}
                          WISHLIST
                        </>
                      )}
                    </Button>
                    <Button
                      data-test-id="addToCartRef"
                      onClick={this.handleAddToCartApi}
                      className={this.props.classes.addToCartBtn}
                      disabled={this.state.productviewList?.attributes.stocks === 0 || this.state.productviewList?.attributes.stocks === null || !this.state.productviewList?.attributes.status }
                    >
                      ADD TO CART
                    </Button>
                    </Grid>
                    </Grid>
                    <Box className={this.props.classes.packageBox}>
                      <Typography className={this.props.classes.packageStyle} >
                        <span style={{ overflow: 'hidden', whiteSpace: 'normal',display: 'inline-flex'}}>What's in the package</span>
                        <span className={this.props.classes.packageSpan}>{this.state.productContent?.whats_in_the_package}</span></Typography>
                    </Box>
                    <Box style={{ overflow: 'hidden'}}>
                      <Table
                        className={this.props.classes.tableRoot}
                        data-test-id="tableData"
                        style={webStyle.table}
                      >
                        {this.state.modelName &&
                         <TableRow className={this.props.classes.model}>
                          <TableCell className={this.props.classes.tableHeader}>
                            Model
                          </TableCell>
                          <TableCell
                            style={webStyle.tableCell}
                          >
                            <Box className={this.props.classes.text}>
                              {this.state.modelName}
                            </Box>
                          </TableCell>
                        </TableRow>
                        }
                        {this.state.selectedVariantGroupDetails.map(
                          (group, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell
                                  className={this.props.classes.tableHeader}
                                >
                                  <Box className={this.props.classes.text}>
                                    {group?.attribute_name}
                                  </Box>
                                </TableCell>
                                <TableCell
                                data-test-id="selectedVariant"
                                  style={webStyle.tableCell}
                                >
                                  {group?.options?.[0]}
                                </TableCell>
                              </TableRow>
                            );
                          }
                        )}

                          {this.displayProductColor()}
                          {this.displayNumberOfPieces()}

                        <TableRow className={this.props.classes.deliver}>
                          <TableCell className={this.props.classes.tableHeader}>
                            Delivery
                          </TableCell>
                          <TableCell
                            style={webStyle.tableCell}
                          >
                            <Box className={this.props.classes.text}>
                              {this.state.productContent?.country_of_origin}
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow className={this.props.classes.deliver}>
                          <TableCell className={this.props.classes.tableHeader}>
                            Seller
                          </TableCell>
                          <TableCell
                            style={webStyle.tableCell}
                          >
                            <Box className={this.props.classes.text}>
                              {this.state.productviewList?.attributes?.seller?.data?.attributes?.company_or_store_name}
                            </Box>
                          </TableCell>
                        </TableRow>
                        <TableRow className={this.props.classes.deliver}>
                          <TableCell className={this.props.classes.tableHeader}>
                            Features
                          </TableCell>
                          <TableCell style={webStyle.tableCell}>
                              {this.state.featureBullets?.map((item:{field_name: string, value: string}) => {
                                return(
                                <p><span style={webStyle.dotStyle}><FiberManualRecordIcon className={this.props.classes.dotColor}/></span>
                                 <Box className={this.props.classes.text}>
                                  {item.value}
                                 </Box>
                                </p>
                                )
                              })}
                          </TableCell>
                        </TableRow>
                        {this.state.customFieldContent?.map((content: ICustomFieldContent) => {
                          return(
                            <TableRow className={this.props.classes.deliver}>
                          <TableCell className={this.props.classes.tableHeader}>
                            {content.custom_field_name}
                          </TableCell>
                          <TableCell
                            style={webStyle.tableCell}
                          >
                            <Box className={this.props.classes.text}>
                              {content.value}
                            </Box>
                          </TableCell>
                        </TableRow>
                          )
                        })}
                        
                      </Table>
                    </Box>
                    </Box>
                  </Grid>
                </Grid>
                {this.renderReviews()}
              </Box>
            </Box>
        <FooterWeb navigation={this.props.navigation} id={this.props.id} categories={[]} token={this.state.token}/>
          </Container>
        </BoxNoScroll>
        <Dialog 
          data-test-id="modal" 
          style={{boxShadow:'none'}} 
          className={this.props.classes.dialogBox} 
          maxWidth="lg" 
          open={this.state.openReviewImages}
          onClose={this.handleCloseIcon}
        >
          <Box className={this.props.classes.closeContainer}>
          <IconButton
                aria-label="delete"
                className={this.props.classes.icon}
                onClick={this.handleCloseIcon}
              >
                <Close className={this.props.classes.closeIcon} />
              </IconButton>
          </Box>
          <Box>
            <img src={this.state.setImage} alt="prodImg" className={this.props.classes.imageBox}/>
          </Box>
        </Dialog>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  test: {
    background: "grey",
    height: "50px",
    width: "100%",
  },
  test2: {
    width: "100%",
  },
  mainWrapper: {
    background: "#fff",
    width: "100%",
    overflowX: "hidden" as const,
  },
  navigationBar: {
    width: "100%",
    minHeight: "70px",
    background:
      "linear-gradient(139deg, rgba(249,199,33,1) 0%, rgba(250,57,146,1) 64%, rgba(162,105,238,1) 100%)",
  },
  categoriesBar: {
    width: "100%",
    height: "60px",
    background: "rgba(23,34,54,255)",
  },
  hero: {
    width: "100%",
    height: "100vh",
    background:
      "linear-gradient(180deg, rgba(253,213,213,1) 0%, rgba(221,241,255,1) 100%)",
  },
  footer: {
    width: "100%",
    minHeight: "500px",
    background: "rgba(23,34,54,255)",
    padding: "20px 0px",
  },
  footerWrapper: {
    width: "100%",
    height: "auto",
    background: "white",
    flexDirection: "row" as const,
    display: "flex",
  },
  fontTheme1: {
    fontSize: 14,
    color: "white",
    fontFamily: `"Lato", sans-serif`,
    paddingBottom: "5px",
  },
  imgContainer: { 
    margin: "30px", 
    width: "100%" 
  },
  imageBox: {
    padding: "0 10px",
    boxSizing: "border-box" as const,
  },
  image: {
    padding: "5px",
    boxSizing: "border-box" as const,
    height: "100px",
    width: "100%",
    borderRadius: "6px",
  },
  logo: {
    fontFamily: "Lato, sans-serif",
    height: "23px",
    width: "58px",
    margin: "0px 20px",
  },
  selectVariantGrid: {
    width: "500px",
    display: "flex",
    flexDirection: "row" as const,
    justifyContent: "flex-start",
    flexWrap:'wrap' as const,
    gap: '10px'
  },
  table: {
    marginTop: "0.9rem",
    marginBottom: "0.9rem",
    fontFamily: `"Lato", sans-serif`,
    border: '1px solid #f0f0f0',
    borderCollapse: "separate",
    borderRadius: '6px'
  },
  tableCell: {
    fontWeight: 400,
    fontFamily: `"Lato", sans-serif`,
    fontSize:'15px',
    color: '#1c1c1c',
    borderBottom: '1px solid #f0f0f0',
    wordBreak: 'break-all',
    whiteSpace: 'nowrap',
    display: 'inline-block'
  },
  quantity: {
    fontWeight: "bold",
    fontFamily: `"Lato", sans-serif`,
    color: "#545454",
    marginBottom: 0,
  },
  amountToggle: {
    borderRadius: "5px",
    border: "0.5px solid rgb(193, 193, 193)",
    display: "inline-flex",
    marginTop: "0.9rem",
    marginBottom: "0.9rem",
  },
  storeLink : {
    color: "#FF6008",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "16px",
    lineHeight: "16.8px",
    fontWeight: 700 as const,
    marginBottom:'20px',
    marginTop:'-10px',
    cursor: 'pointer' as const,
    '&:hover': {
      textDecoration: 'underline' as const,
      color: 'rgba(23,34,54,255)'
    }
  },
  dotStyle: {
    marginLeft: '-13px',
    marginRight: '5px',
    position: 'absolute',
    marginTop: '8px'
  },
  paginate: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "center"
  }
};

export const styles =  (theme:Theme) => ({
  tableRoot: {
    "& tr:nth-child(odd)": {
      backgroundColor: "#f7f7f7",
    },
  },
  container: {
    fontFamily: `"Lato", sans-serif`,
    maxWidth: "1700px",
    padding: "0",
    margin: "0 auto",
  },
  link: {
    fontFamily: `"Lato", sans-serif`,
    textDecoration: "none" as const,
    cursor: 'pointer' as const,
  },
  routeContainer: {
    padding: "20px 0",
    backgroundColor: "#e5e5e5",
    display: "flex",
    gap: "3px",
    alignItems: "center",
    fontFamily: `"Lato", sans-serif`,
  },
  route: {
    color: "#585858",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    fontWeight: 400 as const,
    lineHeight: "16.8px",
  },
  product: {
    color: "#FF6008",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px",
    lineHeight: "16.8px",
    fontWeight: 700 as const,
  },
  main: {
    backgroundColor: "#e5e5e5",
    padding: "0px 30px 30px",
  },
  box: {
    '@media (max-width: 600px)': {
      padding: "20px",
    },
    backgroundColor: "white",
    marginBottom: "20px",
    padding: "70px",
    borderRadius: "6px",
    fontFamily: `"Lato", sans-serif`,
  },
  downLeft: {
    fontFamily: `"Lato", sans-serif`,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start" as const,
    marginTop: "20px",
    marginBottom: "20px",
  },
  productTitle: {
    color: "#1c1c1c",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "36px",
    fontWeight: 700 as const,
    lineHeight: "46px",
    marginBottom: "8px",
    whiteSpace: "normal",
    wordBreak: 'break-all',
    width: "100%",
    display: "inline-block",
    '@media (max-width:600px)': {
      fontSize: "20px",
      fontWeight: 700 as const,
      lineHeight: 1.4,
    }
  },
  ratingContainer: {
    fontFamily: `"Lato", sans-serif`,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    marginBottom: "12px",
  },
  rating: {
    fontFamily: `"Lato", sans-serif`,
    "@media (max-width: 400px)": {
      display: "block",
    },
    display: "flex",
    flexDirection: "row" as const,
    alignItems: "center",
    justifyContent: "center",
    color: "orange",
  },
  ratingMobile: {
    fontSize: '1.3rem',
    "@media (max-width: 400px)": {
      fontSize: "15px",
    },
  },
  reviews: {
    color: "#66676a",
    fontWeight: 400 as const,
    fontSize: "13px",
    marginLeft: "10px",
    fontFamily: `"Lato", sans-serif`,
    lineHeight: "18px",
    letterSpacing: "-0.1px",
  },
  productPrice: {
    color: "#1c1c1c",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "24px",
    fontWeight: 700 as const,
    lineHeight: "32px",
    letterSpacing: "-0.4px",
    display: "flex",
    alignItems: "center",
  },
  mrp: {
    color: "#33333a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "16px",
    fontWeight: 400 as const,
    lineHeight: "30px",
    textDecoration: "line-through" as const,
    marginLeft: "22px",
  },
  discount: {
    color: "#33333a",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "16px",
    fontWeight: 700 as const,
    lineHeight: "30px",
    marginLeft: "10px",
  },
  tax: {
    color: "#969696",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "13px",
    fontWeight: 400 as const,
    lineHeight: "18px",
    letterSpacing: "-0.1px",
    marginTop: "6px",
  },
  longDesc: {
    color: "#545454",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "15px",
    fontWeight: 400 as const,
    lineHeight: '22px',
    letterSpacing: '-0.2px',
    margin: '12px 0 20px 0',
    wordBreak: 'break-all'
  },
  selectColor: {
    color: "#1c1c1c",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "15px",
    fontWeight: 700,
    lineHeight: "22px",
    letterSpacing: "-0.1px",
    textAlign: "left" as const,
    marginBottom: "12px",
  },
  label: {
    color: "#969696",
    fontFamily: `"Lato", sans-serif `,
    fontSize: "10px",
    fontWeight: 400 as const,
    lineHeight: "18px",
    letterSpacing: "-0.07692308px",
  },
  model: {borderBottom: 'none'},
  deliver: {},
  tableHeader: {
    color: "#545454",
    fontFamily: `"Lato", sans-serif `,
    fontSize: "15px",
    fontWeight: "400",
    lineHeight: "22px",
    letterSpacing: "-0.2px",
    textTransform: "capitalize" as const,
    width:"20%",
    borderBottom: '1px solid #f0f0f0'
  },
  modalBox: {
    width: "100%",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center ",
    fontFamily: "Lato, sans-serif",
    background: "rgba(0,0,0,0.1)"
  },
  modalWrapper: {
    position: "relative" as const,
    width: "300px",
    height: "auto",
    display: "flex",
    flexDirection: "column" as const,
    justifyContent: "center",
    borderRadius: "8px",
    padding: "30px 40px",
    fontFamily: "Lato, sans-serif", 
    background: "white" ,
    '@media (max-width:600px)': {
      padding:'30px 10px'
    }
  },
  iconBox: {
    position: "absolute" as const,
    top: "14px",
    right: "14px",
  },
  icon: { 
    cursor: "pointer" as const, 
    padding: "7px" 
  },
  closeIcon: { 
    cursor: "pointer" as const
  },
  successIconWrapper: {
    fontFamily: "Lato, sans-serif",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column" as const,
  },
  successImg: {
    fontFamily: "Lato, sans-serif",
    margin: "auto",
    width: 65,
  },
  successText: {
    marginTop: "5px",
    fontWeight: 600,
    fontSize: "20px",
    textAlign: "center" as const,
    fontFamily: `"Lato", sans-serif`,
    color: "black",
  },
  successPara: {
    marginBottom: "40px",
    marginTop: "5px",
    textAlign: "center" as const,
    fontFamily: `"Lato", sans-serif`,
    color: "black",
  },
  buttonBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    gap: "15px",
  },
  checkOutBtn: {
    fontFamily: "Lato, sans-serif",
    textTransform: "none" as const,
    fontWeight: 500,
    fontSize: 14,
    background: "#454545",
    color: "white",
    alignItems: "center",
    borderRadius: "5px",
    padding: "8px 20px",
    letterSpacing: 1,
    '&:hover' : {
      backgroundColor: '#454545',
      color: '#fff'
    }
  },
  continueBtn: {
    fontFamily: "Lato, sans-serif",
    textTransform: "none" as const,
    fontWeight: 500,
    fontSize: 14,
    background: "#FF6008",
    color: "white",
    alignItems: "center",
    borderRadius: "5px",
    padding: "8px 20px",
    letterSpacing: 1,
    '&:hover' : {
      backgroundColor: '#FF6008',
      color: '#fff'
    }
  },
  leftSide: {
    fontFamily: `"Lato", sans-serif`,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    flexDirection: "column" as const,
    padding: "0 60px 0 0",
    "@media (max-width:768px)": {
      padding: "0px",
    },
  },
  reviewHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
  },
  progressContainer: {
    width: '80%',
    marginTop: theme.spacing(2),
    '@media (max-width: 1240px)': {
      width: '35%'
    },
    '@media (max-width: 960px)': {
      width: '50%'
    },
    '@media (max-width: 760px)': {
      width: '65%'
    },
    '@media (max-width: 600px)': {
      width: '100%'
    }
  },
  progressLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  progress: {
    width: "100%",
    height: 20,
    borderRadius: 6,
  },
  avatar: {
    marginRight: theme.spacing(2),
    width: 56,
    height: 56
  },
  helpful: {
    marginTop: theme.spacing(1),
  },
  reviewItem: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingLeft: 0
  },
  customerRatingHeading: {
    margin: "16px 0px",
    gap: 8,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column' as const,
      gap: 4,
      alignItems:'flex-start'
    }
  },
 formControl: {
    minWidth: 150,
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    '& .MuiOutlinedInput-root': {
      height: 44
    },
    '& .MuiSelect-outlined.MuiSelect-outlined':{
      fontWeight: 600
    }
  },
  imagesContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    rowGap:'10px',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column" as const
    },
    '& img': {
      marginRight: theme.spacing(1),
      width: 100,
      height: 100,
      objectFit: 'contain',
      border:'1px solid #ccc',
      borderRadius: theme.shape.borderRadius,
      [theme.breakpoints.down('xs')]: {
        width: "100%",
        gap: "8px"
      },
    },
  },
  helpfulChipContainer: {
    marginTop: theme.spacing(1),
    '& .MuiChip-outlined': {
      width: "100px"
    },
    '& .MuiChip-label': {
      fontSize: "1rem"
    }
  },
  loadMoreButton: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: "center"
  },
  helpfulTypography: {
    fontFamily: `"Helvetica", sans-serif"`,
    fonSize:'16px',
    marginTop:'8px',
    color:'#939393'
  },
  dotColor: {
      fontSize: '0.45rem',
      color: '#c1c1c1'
  },
  sellerRatingContainer: {
    fontFamily: `"Lato", sans-serif`,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  packageStyle: {
    color: "#545454",
    fontFamily: `"Lato", sans-serif `,
    fontSize: "15px",
    fontWeight: "400",
    lineHeight: "22px",
    letterSpacing: "-0.2px",
    textTransform: "none" as const
  },
  productRating: {
    fontSize: '1.3rem'
  },
  prodRatingBox: {
    marginLeft: '12px',
    display:'flex',
    alignItems:'center'
  },
  rightContainer: { 
    paddingLeft: "15px", 
    position:'relative' ,
    '@media (max-width: 460px)': {
      marginTop:'0px'
    }
  },
  sellerReviewBox: {
    fontFamily: `"Lato", sans-serif`, 
    borderRadius:'8px', 
    display:'flex', 
    alignItems:'center', 
    justifyContent:'flex-end',
    paddingBottom:'10px',
    marginTop:'-50px',
    '@media (max-width: 960px)': {
      paddingBottom:'10px',
      marginTop:'10px',
      display:'flex',
      flexDirection:'column' as const
    }
  },
  sellerReviewContainer: { 
    margin: "0px", 
    fontFamily: `"Lato", sans-serif` ,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: "200px"
  },
  reviewSpan: { 
    color: "#ff6008", 
    fontFamily: `"Lato", sans-serif` 
  },
  bottom: {
    maxWidth: '500px'
  },
  decrementBtn: {
    backgroundColor: "#f26e22",
    color: "white",
    textAlign: "center",
    padding: "9px",
    fontSize: "30px",
    minWidth: "39px",
    width: "39px",
    height: "39px",
    '&:hover': {
      backgroundColor: "#f26e22",
      color: "white"
    }
  },
  amountText: {
    textAlign: "center",
    padding: "9px",
    margin: "0",
  },
  incrementBtn: {
    backgroundColor: "#f26e22",
    color: "white",
    textAlign: "center",
    padding: "12px",
    fontSize: "30px",
    minWidth: "39px",
    width: "39px",
    height: "39px",
    '&:hover': {
      backgroundColor: "#f26e22",
      color: "white"
    }
  },
  wishlistBtn: {
    backgroundColor: "#5c5756",
    color: "white",
    letterSpacing: "3px",
    fontSize: "16px",
    fontFamily: `"Lato", sans-serif`,
    margin: '10px 0.9rem 25px 0',
    '&:hover': {
      backgroundColor: "#5c5756",
      color: "white",
    }
  },
  wishlistTextFav: {
    padding: "3px",
    fontSize: "16px",
    color: "red",
  },
  wishlistTextUnFav: {
    padding: "3px",
    fontSize: "16px"
  },
  btnContainer: {
    '& .MuiButton-contained.Mui-disabled' :{
      backgroundColor: "#f26e22 !important",
    color: "white",
    }
  },
  addToCartBtn: {
    letterSpacing: "3px",
    fontSize: "16px",
    backgroundColor: "#f26e22",
    color: "white",
    fontFamily: `"Lato", sans-serif`,
    margin: "10px 0 25px 0",
    '&.Mui-disabled': {
      backgroundColor: "grey !important",
      color: "white",
    },
    '&:hover': {
      backgroundColor: "#f26e22",
      color: "white"
    }
  },
  packageBox: {
    border: '1px solid rgba(224,224,224,1)',
    marginBottom:'22px', 
    padding:'12px 18px', 
    borderRadius:'6px'
  },
  packageSpan: {
    paddingLeft:'40px',
    wordBreak: 'break-all',
    width: '50%',
    whiteSpace: 'normal',
    display: 'inline-flex',
    "@media (max-width: 610px)": {
      paddingLeft:'20px',
    },
    "@media (max-width: 510px)": {
      paddingLeft:'0px',
    }
  },
  soldOutText: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    color: 'rgba(28, 28, 28, 1)',
    textAlign: 'left' as const,
    fontFamily: 'Helvetica',
    fontSize: "30px",
  },
  outOfStockText: {
    fontWeight: 'normal',
    color: 'rgba(102, 102, 102, 1)',
    textAlign: 'left' as const,
    fontFamily: 'Helvetica',
    fontSize: "20px",
  },
  customerReviewHeading: {
    marginBottom:'0.15em',
    fontSize:'28px', 
    fontFamily: 'Helvetica, sans-serif', 
    color:'#1c1c1c', 
    fontWeight:'700'
  },
  ratingRangeText: { 
    paddingTop:'7px',
    marginLeft: 8, 
    fontSize:'16px', 
    fontFamily: 'Helvetica, sans-serif',
    color:'#1c1c1c' 
  },
  starLabel: { 
    color: "#FF8808", 
    fontSize:'16px',
    fontFamily: 'Helvetica, sans-serif'
  },
  allReviews: {
    fontSize:'20px', 
    fontFamily: 'Helvetica, sans-serif', 
    color:'#1c1c1c', 
    fontWeight:'700', 
    marginTop:'6px'
  },
  noReviews: {
    display:'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media (max-width: 1240px)': {
      justifyContent:'flex-start',
      marginTop:'20px'
    }
  },
  flex: {
    display:"flex", 
    alignItems:'center'
  },
  fullName: {
    fontSize:'16px', 
    fontFamily: 'Helvetica, sans-serif', 
    color:'#1c1c1c', 
    fontWeight:'700'
  },
  title: {
    fontWeight: 700,
    fontSize: 16, 
    marginTop: 4,
    fontFamily: 'Helvetica, sans-serif'
  },
  prodDescription: {
    fontSize:'16px', 
    fontFamily: 'Helvetica, sans-serif', 
    color:'#1c1c1c', 
    fontWeight:'400'
  },
  dialog: {
    maxWidth: '100% !important' ,
    boxShadow: 'none !important' ,
    '.MuiDialog-paperWidthMd' : {
      maxWidth: '100%',
      boxShadow: 'none !important' ,
    }
  },
  imageBox: {
    width: '350px',
    maxWidth: '350px',
    height: '350px',
    maxHeight: '350px',
    padding: '48px',
    '@media (max-width:460px)': {
      width: '150px',
      maxWidth: '150px',
      height: '150px'
    }
  },
  closeContainer: { 
    margin:'20px 16px -42px',
    fontFamily: `"Lato", sans-serif`, 
    textAlign: "right" as const
  },
  closeImgIcon: {
    padding: '4px',
    fontSize: '24px',
    cursor:'pointer' as const, 
    border: '1px solid #666',
    borderRadius: '50%'
  },
  text: { 
    overflow: 'hidden', 
    textOverflow: 'ellipsis', 
    whiteSpace: 'normal', 
    display: 'inline-block', 
    width: "auto",
    "@media (max-width:566px)": {
      width: "100px",
    }
  },
  viewMoreText : {
    color:'#ff6008', 
    fontSize:'15px', 
    fontWeight:400, 
    fontFamily:'Lato, sans-serif', 
    cursor:'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});

const styledProductQuickView = withStyles(styles)(ProductQuickview);
export { styledProductQuickView };
// Customizable Area End
