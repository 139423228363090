Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/bx_block_dashboard/candidates";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.totalCandidateslabelTitleText = "Total Candidates";
exports.viewDetailsBtn = "View Details";
exports.candidateslabelTitleText = "Candidates";
exports.baseUrl = "";
exports.labelBodyText = "dashboard Body";
exports.getMethod = "GET";
exports.contentType = "application/json";
exports.apiGetSellerInfoEndpoint = "account_block/accounts/";
exports.apiGetSellerDocumentsStatusEndpoint = "account_block/accounts/";
exports.patchMethod = "PATCH";
exports.deleteMethod = "DELETE";
exports.postMethod = "POST";
exports.putMethod="PUT"
exports.apiCreateBrandEndpoint = "bx_block_catalogue/brands/create_seller_brand";
exports.apiGetCategoriesEndpoint = "bx_block_categories/categories";
exports.apiSearchBrandEndpoint = "search_brands?keyword=";
exports.getProductByBrandEndpoint = `bx_block_catalogue/catalogues/search_catalogues_by_brand?brand_id=`
exports.apiSearchProductBrandEndpoint = "bx_block_catalogue/parent_catalogues/search_parent_catalogues_by_title_or_brand_name";
exports.apiSearchProductEndpoint = "search_products?keyword=";
exports.apiCreateStoreEndpoint = "bx_block_store_management/stores";
exports.apiCheckLoggedUserEndpoint = "account_block/accounts/logged_user?token=";
exports.apiGetMiniMicroEndpoint = "bx_block_categories/";
exports.apiPostProductProfileEndpoint = "bx_block_catalogue/catalogues";
exports.apiCreateOfferEndpoint = "bx_block_catalogue/catalogues/";
exports.apiSupportDocsEndpoint = "bx_block_support/support_documents";
exports.apiSupportContactUsEndpoint = "bx_block_support/supports";
exports.apiTermsAndPolicyEndpoint = "bx_block_termsandconditions/terms_policies";
exports.apiTaxAndLegalPolicyEndpoint = "bx_block_termsandconditions/privacy_and_legal_policy"
exports.apiPostProductContentEndpoint = "bx_block_catalogue/catalogues/"
exports.language = {
  english: {
    features: "features",
    pricing: "pricing",
    cost: "Cost & Commission Details",
    beginner: "Beginner’s guide",
    fulfill: "Fullfill by ByEzzy",
    advertise: "Advertise on Byezzy",
    brand: "Brand store",
    support: "Support",
    contact: "Contact ByEzzy",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    details: "Details",
    submit: "Submit",
  },
};
exports.bulkUploadApi="bulk_upload"
exports.templateDownload="template_download"
exports.apiEndpointUploadProfileImage="account_block/accounts/upload_image"
exports.welcomeText = "Welcome!";
exports.startAddingProductsText = "Start adding your products now!";
exports.addNewProductLabel = "Add New Product";
exports.prodImageLabel = "Product Image";
exports.prodNameLabel = "Product Name";
exports.sellerSKULabel = "Seller SKU";
exports.beskuLabel = "BESKU";
exports.stocksLabel = "Stocks";
exports.priceLabel = "Price";
exports.contentStatusLabel = "Content Status";
exports.liveStatusLabel = "Live Status";
exports.actionsLabel = "Actions";
exports.fulfillmentLabel = "Fulfillment";
exports.optionsLabel = "Options";
exports.productCatalogueText = "Partner Catalog";
exports.searchProductText = "Let's start search the product or brand";
exports.searchProductName = "Let's start searching the product";
exports.searchProductDes = "Search for the product via Product Name or via Brand Name";
exports.productNameText = "Product name";
exports.brandNameText = "Brand name";
exports.filterBtn = "Filter";
exports.resultDes = "Please use a more specific keyword to narrow down the results";
exports.titleText = "Title";
exports.categoryLabel = "Category";
exports.modelLabel = "Model No";
exports.detailsLabel = "Details";
exports.viewMoreBtn = "View More";
exports.imageLabel = "Image";
exports.createSkuLabel = "Create SKU";
exports.validateBtn = "Validate";
exports.nextBtn = "Next";
exports.skuValidText = "SKU is valid";
exports.alreadyExistsText = "Already exist in database";
exports.selectProductText = 'Please select the product';
exports.createSkuError = 'Please create SKU';
exports.nextBtnErrorMsg = "Please select the product and create SKU";
exports.productKeyword = "product_keyword";
exports.brandKeyword = "brand_keyword";
exports.noDataAvailableText = "No data available";
exports.fulfilledByByEzzyText = "Fulfilled by ByEzzy";
exports.fulfilledByPartnerText = "Fulfilled by Partner";
exports.inStockText = "In stock";
exports.inStoreText = "In store"
exports.activeText = "Active" ;
exports.inActiveText = "Inactive";
exports.productLinkText = "Product Link";
exports.viewProductLink = "View Product";
exports.apiGetSearchCatgeoryEndpoint = "";
exports.sellerWarehouseApiEndPointList = "bx_block_catalogue/warehouses/seller_warehouse_listing";
exports.postCreateStoreEndpoint = "bx_block_store_management/stores";
exports.storeName = "Store Name";
exports.storeYear = "Store Year";
exports.edit = "Edit";
exports.delete = "Delete";
exports.getStoreEndPoint = "bx_block_store_management/stores";
exports.home = "Home";
exports.title = "Title";
exports.productAdded = "Products added";
exports.productImage = "Image";
exports.productTitle = "CODE";
exports.productCatergory = "Deo";
exports.besku = "BESKU";
exports.modelNo = "Model No123";
exports.details = "Details Other";
exports.addMoreProducts = "+ Add more products";
exports.next = "Next";
exports.filters = "Filters";
exports.fulfillmentOptions = [{label:"Fulfilled by ByEzzy", value:"byezzy"},{label: "Fulfilled by Partner", value:"partner"}];
exports.filterByLiveStatus = [{label:"Active",value: true}, {label:"Inactive", value: false}];
exports.filterByContentStatus = [{label: "Accepted",value:"accepted"},{label:"Rejected", value:"rejected"}, {label: "In Review", value:"under_review"}];
exports.priceOptions = ["Low to High", "High to Low"];
exports.sortingOptions = [{label:"Product Name: A-Z", value: "product_title_AZ"}, {label: "Product Name: Z-A", value: "product_title_ZA"}, {label: "Price: Low to High",value: "low_to_high"}, {label: "Price: High to Low", value:"high_to_low"}]
exports.filterLabel = "Filters";
exports.fulfillmentTypeLabel = "Fulfillment Type";
exports.sortingLabel = "Sorting";
exports.addNewProductText = "Add new product";
exports.addSingleProductText = "Add Single Product";
exports.addBulkProductText = "Add Bulk Product"
exports.acceptedText = "accepted";
exports.rejectedText = "rejected"
exports.acceptedBtnText = "ACCEPTED";
exports.rejectedBtnText = "REJECTED";
exports.inReviewText = "IN REVIEW";
exports.partnerText = "partner";
exports.byEzzyText = "byezzy";
exports.myDealsApiEndPoint = {
  createDeals: (cat_id,subCatId,miniCatId,microCatId, brandId)=>`template_download?category_id=${cat_id}&subcategory_id=${subCatId}&minicategory_id=${miniCatId}&microcategory_id=${microCatId}&brand_id=${brandId}`,
  editStoreData: (store_id)=>`bx_block_store_management/stores/${store_id}/update_seller_store`,
  deleteStoreData: (store_id)=>`bx_block_store_management/stores/${store_id}/delete_seller_store`,
}
exports.postStoreEndPoint = "bx_block_store_management/stores/create_seller_store";
exports.postCreateStoreContentType = "multipart/form-data";
exports.getStoreNameEndPoint = "bx_block_store_management/stores/seller_store_listing";
exports.getCustomFiledList=(id)=>`/bx_block_catalogue/catalogues/${id}/catalogue_contents`
exports.getProductContentEndpoint = (id) => `bx_block_catalogue/catalogues/${id}/product_contents`;
exports.createStore = "Create Store";
exports.welcome = "Welcome!";
exports.letCreateStore = "Let's create your own store!";
exports.createNewStore = "Create new store";
exports.storeName = "Store Name*";
exports.storeYear = "Store Year*";
exports.regEx = /^\d{0,10}$/;
exports.brandTradeCertificate = "Branding & Trademark Certificate*";
exports.upload = "Upload your file";
exports.uploadPDF = "Only PDF file allowed to upload here";
exports.back = "Back";
exports.submitAndCreate = "Submit and Create Page";
exports.generate = "Generate";
exports.createStore = "Create Store";
exports.variant={
  createGroup:(id="")=>`bx_block_catalogue/catalogue_variants${id ? ('/' + id) :""}`,
  getGroupDetails:(groupId)=>`bx_block_catalogue/catalogue_variants/${groupId}`,
  createVariantWithGroup:(groupId)=>`bx_block_catalogue/catalogues/${groupId}/product_variant_groups`,
  updateVariantWithGroup:(catalogue_id,variant_id)=>`bx_block_catalogue/catalogues/${catalogue_id}/product_variant_groups/${variant_id}`,
  listAllGroup:(micro_category_id)=>`bx_block_catalogue/catalogue_variants?micro_category_id=${micro_category_id}`,
  deleteGroup:(groupId)=>`bx_block_catalogue/catalogue_variants/${groupId}`,
  listProductVariants:(catalogue_id)=>`bx_block_catalogue/catalogues/${catalogue_id}/product_variant_groups`,
  deleteVariant:(catalogue_id,variant_id)=>`bx_block_catalogue/catalogues/${catalogue_id}/product_variant_groups/${variant_id}`,
}
exports.infoRequiredText = "Please provide all the needed information.";
exports.selectUnitText = "Select unit";
exports.countryOriginText = "country_of_origin";
exports.apiSearchProductStoreEndpoint = "bx_block_catalogue/catalogues/search_catalogues_by_title_or_brand_name";
exports.gtinErrorMsg = "Please enter a number between 8 and 14 digits with no spaces";
exports.productTitleErrorMsg = "Min 3 and max 200 alphanumeric characters , including the special characters &.,| -. are allowed"; 
exports.mrpErrorMsg = "Price between 1 to 9999999";
exports.fieldRequiredText = "This field is required";
exports.descErrorMsg = "Min 50 and max 2000 alphanumeric characters , including the special characters &.,| -. are allowed";
exports.packageErrorMsg = "Min 3 and max 200 alphanumeric characters , including the special characters &.,| -. are allowed";
exports.countryErrorMsg = "Must be 3-25 characters, no leading/trailing spaces, and not just special characters/numbers.";
exports.modelNameErrorMsg = "Max 50 characters alphanumeric allowed";
exports.modelNumberErrorMsg = "Min 3 and max 16 alphanumeric characters , including the special characters are allowed";
exports.piecesErrorMsg = "Please enter a value between 1 and 10000"
exports.commonRegex = /^(0\.[1-9]\d??|[1-9]\d{0,3}(\.\d{1,2})?|10000)$/;
exports.sizeErrorMsg = "Please enter valid number";
exports.featureErrorMsg = "Min 3 and max 700 alphanumeric characters , including the special characters &.,| -. are allowed";
exports.hsCodeErrorMsg = 'Max 6 digits allowed';
exports.imageUrlErrorMsg = "Url is invalid";
exports.imageUrlRegex = /^(https:\/\/|www)[^\s]+$/;
exports.imageUrlRegexStoreUrl = /^(https:\/\/|www)[^\s]*byezzy1[^\s]*$/;
exports.shippingLengthLabel = "Shipping Length";
exports.shippingLengthUnitLabel = "Shipping Length Unit";
exports.centimeterText = "Centimeter";
exports.meterText = "Meter";
exports.inchText = "Inch";
exports.feetText = "Feet";
exports.shippingHeightLabel = "Shipping Height";
exports.shippingHeightUnitLabel = "Shipping Height Unit";
exports.shippingWidthUnitLabel = "Shipping Width Depth Unit";
exports.shippingWidthLabel = "Shipping Width Depth";
exports.shippingWeightLabel = "Shipping Weight";
exports.shippingWeightUnitLabel = "Shipping Weight Unit"
exports.kilogramText = "Kilogram";
exports.gramText = "Gram";
exports.ounceText = "Ounce (OZ)";
exports.poundText = "pound  (lb)";
exports.brandManagementText = "Brand Management";
exports.brandManagementMenu = "brandlist";
exports.brandCatalogMenu = "create-brand";
exports.addSingleHomeMenu = "products";
exports.addSingleProductMenu = "addSingleProduct";
exports.searchBrandMenu = "searchbrand";
exports.searchProductNameMenu = "searchProductName";
exports.addNewProductMenu = "addNewProduct";
exports.alphanumericString = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
exports.validateSkuMsg = 'Please validate SKU';
exports.gtinAlreadyTakenErrorMsg = "Gtin has already been taken";
exports.skuAlreadyTakenText = "This SKU has already been taken";
exports.skuValidateMsg = "SKU must be min 2 and max 25 characters, alphanumeric";
exports.restrictionMessage = "The brand you have selected has some restrictions for sellers or distributors,if you have products of the brand, please submit a permit to sell under this brand, and submit a request for approval.";
exports.requestAlreadySubmitedMessage = "Your request for selected brand is already under review.";
exports.requestBrandRestrict = (brandid)=>`/bx_block_catalogue/brands/${brandid}/restricted_brands`
exports.storeCreateList={
  storeDDList:`/bx_block_catalogue/brands/seller_brand_listing`,
}
exports.bottomText = "bottom";
exports.leftText = "left";
exports.topText = "top";
exports.rightText = "right";
exports.sizeUnitText = "Size Unit";
exports.gramsText = "Grams";
exports.kilogramsText = "KiloGrams";
exports.millimeterText = "Milimeter";
exports.litreText = "Litre";
exports.mililetreText = "Mililitre";
exports.uniquePskuText = "Unique PSKU";
exports.uniqueskuText = "Variant SKU";
exports.uniquePskuToolTip = "Unique PSKU is a unique id given to a product";
exports.mrpLabel = "MRP (AED)";
exports.productTitleLabel = "Product Title *"; 
exports.validation=/^(?=.*[a-zA-Z])[a-zA-Z0-9&.,()|@#$%^*+= !~\-_\+\`\{\}\[\]\\:;"'<>,.?\/]{3,200}$/
exports.brandLabel = "Brand *";
exports.GtinLabel = "GTIN";
exports.retailPriceLabel = "Recommended Retail Price (AED) *";
exports.longDescLabel = "Long Description *";
exports.whatsInPackageLabel = "What's in the package *";
exports.countryOfOriginLabel = "Country of Origin *";
exports.addMoreImageUrls = "+ Click to add more image URL's";
exports.addMoreFeatureBullets = "+ Click to add more Features Bullets";
exports.sellerHomeText = "SellerHomeDashboard";
exports.bulkSelectCategoryPath = "bulkupload-select-category";
exports.singleProductSelCategoryPath = "select-category";
exports.addProductPath = "add-product";
exports.bulkUploadCategoryId = "seller-home-bulk-upload-product";
exports.bulkUploadSearchBrandPath = "bulkupload-search-brand";
exports.bulkUploadSearchBrandId  = "seller-home-bulk-upload-product-1";
exports.bulkUploadDownloadTempId = "bulkupload-download-template";
exports.searchProductPath = "search-product";
exports.bulkUploadFileId = "seller-home-bulk-upload-product-2";
exports.bulkUploadFilePath = "bulkupload-template";
exports.createStockReqPath = "create-stock-request";
exports.partnerOrderDetailsPath = "partner-order-details";
exports.createWarehousePath = "add-warehouse";
exports.warehousePath = "warehouse";
exports.warehouseListPath = "warehouse-list";
exports.requestListPath = "requestlist";
exports.createDeliveryReqPath = "create-delivery-request";
exports.createStorePath = "create-store";
exports.createTabsPath = "create-tabs";
exports.myProfilePath = "myprofile";
exports.dashboardPath = "SellerDashboard";
exports.servicesText = "SERVICES";
exports.discountOffersPath = "deals";
exports.bulkProductUploadTab = "Bulk Product Upload";
exports.byezzyOrdersPath = "byezzy-orders";
exports.storagePath = "storage";
exports.promotionAdsTab = "Promotions & Ads"
exports.storePath = "store";
exports.brandCatalogPath = "brand-catalog";
exports.storeManagementTab = "Store Management";
exports.happinessRatingTab = "happiness-rating";
exports.analyticsReportsTab = "Analytics & Reports";
exports.otherTab = "OTHER";
exports.contactUsTab = "contact-us"; 
exports.termConditionsPath = "terms-conditions";
exports.termsConditionsTab = "Terms & Conditions";
exports.privacyPolicyTab = "Privacy Policy";
exports.privacyPolicyPath = "privacy-policy";
exports.partnerOrdersPath = "partner-orders";
exports.productListPath = "/seller/home/products";
exports.productProfileMenu = "productProfile";
exports.productContentMenu = "product-content";
exports.variantMenu = "variant";
exports.barcodeMenu = "barcode";
exports.offerMenu = "offer";
exports.dealsMenu = "deals";
exports.productProfileTab = "Product Profile";
exports.contentTab = "Content";
exports.variantTab = "Variant";
exports.barcodeTab = "Barcode";
exports.offersTab = "Offer";
exports.dealsTab = "Deals";
exports.productDetailsText = "Product details";
exports.upgradeText = "Upgrade";
exports.moreThemesText = "More Themes";
exports.supportText = "Support";
exports.contactText = "Contact";
exports.deselectProductMsg = "Please deselect existing product to add new product";
exports.validateYearText = "Please enter valid year";
exports.editStore = "Edit Store";
exports.editStoreText = "Edit Store";
exports.updateAndNextBtnText = "Update and Next";
exports.storeNameLabel = "Store Name*";
exports.storeLogoLabel = "Store Logo*";
exports.storeLogoText = "storeLogo";
exports.uploadImageErrorText = "Please upload an image.";
exports.imageSizeErrorText = "Please upload an image less than 5MB.";
exports.imageType1 = "image/png";
exports.imageType2 = "image/jpg";
exports.imageType3 = "image/jpeg";
exports.menuTabLabel = "Create Menu/Tabs for your Store";
exports.addText = "Add";
exports.tabPositionLabel = "Enter Tab Position"
exports.sellerCreateStoreTab = "StoreTabs";
exports.bannerNameLabel = "Banner Name";
exports.bannerImage = "Banner Image";
exports.dropBannerImageText = "Drop or upload banner image";
exports.fileSizeInfoText = "Only JPG, PNG file allowed to upload here (Maximum size- 5mb)";
exports.bannerLinkLabel = "Banner Link/URL";
exports.removeText = "Remove";
exports.uploadlogoText = "Upload Logo";
exports.coverImgText = "Cover Image";
exports.uploadCoverImgText = "Upload Cover Image";
exports.templatePreviewText = "Template Preview";
exports.headerTab = "Header";
exports.menutabText = "Menu / Tabs";
exports.bannerText = "Banner";
exports.validUrlErrorText = "Please enter valid url."
exports.sectionOneText = "Section 1";
exports.sectionTypeLabel = "Section Type";
exports.sectionOneGridOneText = "Section 1 / Grid 1";
exports.gridNameLabel = "Grid Name";
exports.gridImageLabel = "Grid Image";
exports.dropImageText = "Drop or upload image";
exports.gridLink = "Grid Link/URL";
exports.sectionOneGridTwoText = "Section 1 / Grid 2";
exports.sectionOneGridThreeText = "Section 1 / Grid 3";
exports.fillAllFieldsText = "Please fill all the fields";
exports.sectionTwoText = "Section 2";
exports.sectionTwoGridOneText = "Section 2 / Grid 1";
exports.sectionTwoGridTwoText = "Section 2 / Grid 2";
exports.sectionThreeText = "Section 3";
exports.sectionThreeGridOneText = "Section 3 / Grid 1";
exports.sectionThreeGridTwoText = "Section 3 / Grid 2";
exports.sectionThreeGridThreeText = "Section 3 / Grid 3";
exports.sectionThreeGridFourText = "Section 3 / Grid 4";
exports.productListingText = "Product listing";
exports.categoriesText = "Categories";
exports.selectQuantityLabel = "Select Quantity";
exports.productOneLabel = "Product 1";
exports.productTwoLabel = "Product 2";
exports.categoryOneLabel = "Category 1";
exports.categoryNameLabel = "Category Name";
exports.categoryImageLabel = "Category Image";
exports.selectCategory = "Select Category"
exports.categoryLink = "Catgeory Link/URL";
exports.selectProductLabel = "Select Product";
exports.selectPageToCreateHeaderText = "Select page to create header";
exports.searchProductEndpoint = 'bx_block_catalogue/catalogues/search_catalogues_by_title_or_brand_name?product_keyword=';
exports.viewMoreButtonText = "VIEW MORE";
exports.nameValidateText = "Should be Alphanumeric with some special characters allowed.";
exports.quantityErrorMsg = "Quantity should be more than 5";
exports. productListingErrMsg = 'Quantity and number of products selected should match';
exports.tabPositionErrMsg = "Please enter a number between 2 and 7";
exports.globalSearchPlaceholderText = "Title (in English and Arabic), Brand, Seller SKU, BESKU";
exports.myAccountText = " My Account";
exports.signOutText = "Sign Out";
exports.languageText = "Language";
exports.englishText = "English";
exports.selectQuantityText = "Please select quantity."
exports.createTabErrMsg = "Please create tab";
exports.barCodeErrMsg = 'Should be alphanumeric upto 40 characters';
exports.brandSuccesstext = "Brand Created Successfully";
exports.approvalPendingText = "Approval is pending";
exports.returnsPath = "partner-returns";
exports.returnDetailsPath = "returns-details";
exports.shopNowText = "Shop Now";
exports.newText = "NEW";
exports.aedText = "AED";
exports.offText = "OFF";
exports.exploreText = "Explore";
exports.learnMoreText = "Learn more";
exports.buyText = "Buy";
exports.analyticsPath = "analytics";
exports.recommendedSize = "Recommended Image Size - "
exports.productColorText = "Product Color *";
exports.selectProductColorText = "Select product color";
exports.ProductColorOptions = [
  {title: "Purple", value: "#7877FF"},
  {title: "Light Coral", value: "#FF7979"},
  {title: "Lime Green", value: "#B2E12F"},
  {title: "Yellow", value: "yellow"},
  {title: "Grey", value: "#646464"},
  {title: "Ocean Green", value: "#4CB28E"},
  {title: "Pink", value: "pink"},
  {title: "Sky blue", value: "#51B2EF"},

]
exports.delBrandErrMsg = "Cannot delete brand because it is associated with products or store."
exports.delBrandErrorText = "Selected brand has products linked to it. Please review and delete the products to delete the brand."
exports.bibcInfoText = "(Code will be visible after product creation)"
exports.productTitleRegex = /^(?!^\d+$)(?=.*[a-zA-Z])[^\s][\s\S]{1,198}[^\s]$/
// Customizable Area End
