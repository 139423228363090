// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { styles } from "./StoreManagement/TemplatePreview.web";
import React from 'react';
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import { GetCookies } from "../../../framework/src/WebUtilities";
import { webStyle } from "./BrandStore.web";
export const configJSON = require("./config.js");


interface StoreDataRes {
  data: {
    id: string,
    attributes: {
      logo: string;
      title:string,
      store_name: string,
      catalogues: StoreProduct[]; 
      cover_image: string;
    };
  }[];
}

interface SectionDataRes {
  attributes: {
    banner_url: string,
    section_name: string;
    banner_name?: string;
    banner_image?: string;
    store_section_grids?: {
      data: {
        attributes: {
          grid_image: string,
          grid_name: string,
          grid_url: string
        }
      }
    }[] ;
  };
}

export interface IStoreMenuRes {
  data: ProductData[],
  total_count: number 
}

export interface ISection {
  data: {
    attributes: {
      grid_image: string,
      grid_name:string,
      grid_url: string
    }
  }
}

export interface ProductData {
  id: string,
  attributes: {
    product_content: {
      product_attributes: {
        product_title: string;
        retail_price: number;
        mrp: number;
        product_rating: {
          total_reviews: number,
          average_rating: number
        }
      };
    };
    product_rating: {
      average_rating: number;
      total_reviews: number;
    };
    product_image: string;
  };
}

export interface IStoreMenuList {
  id: string,
  menuName: string | undefined
}

export interface StoreProduct {
  data: ProductData
}

export interface IStoreMenuData {
  id: string,
  attributes: {
    catalogues: StoreProduct[]
  }
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  match: {
    params: {
      menu: string
    }
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  coverImage: string,
  storeLogo: string,
  storeMenuList:IStoreMenuList[],
  brandStoreId: string,
  storeBannerName: string | undefined,
  bannerImage: string | undefined,
  storeSectionOne : ISection[] | undefined,
  storeSectionTwo : ISection[] | undefined,
  storeSectionThree: ISection[] | undefined,
  prodList: StoreProduct[],
  bannerUrl: string,
  isMenuOpen: boolean,
  storeMenuData: IStoreMenuData[],
  storeProductList: ProductData[],
  storeName: string,
  selectedMenuId: string,
  selectedMenu: string,
  pageNumber: number,
  storeHomeId: string,
  totalCount: number,
  apiInprogress: boolean
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class BrandStoreController extends BlockComponent<Props, S, SS> {
  getStoreInfoApiCallId: string = "";
  getStoreSectionDetailsApiCallId: string = "";
  showMenuApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage), getName(MessageEnum.ReceiveMessage), getName(MessageEnum.NavigationPayLoadMessage)];

    this.state = {
      coverImage: '',
      storeLogo: '',
      storeMenuList: [],
      brandStoreId: '',
      storeBannerName: '',
      bannerImage: '',
      bannerUrl: '',
      storeSectionOne: [],
      storeSectionTwo:  [],
      storeSectionThree:  [],
      prodList: [],
      isMenuOpen: false,
      storeMenuData: [],
      storeProductList: [],
      storeName: '',
      selectedMenuId: '',
      selectedMenu: '',
      pageNumber: 1,
      storeHomeId: '',
      totalCount: 0,
      apiInprogress: false
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  // Customizable Area End

  // Customizable Area Start

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (apiRequestCallId === this.getStoreInfoApiCallId) {
        this.handleStoreRes(responseJson)
      }
      if (apiRequestCallId === this.getStoreSectionDetailsApiCallId) {
        this.handleSecDetailsResponse(responseJson)
      }
      if (apiRequestCallId === this.showMenuApiCallId) {
        this.handleShowMenuRes(responseJson)
      }
    }
    // Customizable Area End
  }

  handleShowMenuRes = (responseJson: IStoreMenuRes) => {
    if(responseJson.data) {
      this.setState({ storeProductList :responseJson.data, totalCount: responseJson.total_count})
    }
  }

  handleStoreRes = (responseJson : StoreDataRes) => {
    this.setState({
      apiInprogress: false
    })
    if(responseJson.data) {
      if(responseJson.data[0]) {
        const {cover_image, logo, catalogues, store_name} = responseJson.data[0].attributes
        this.setState({coverImage : cover_image, storeLogo: logo, storeName: store_name})
        if(catalogues?.length > 0 && this.state.selectedMenu === "home") {
          this.setState({prodList : catalogues, storeHomeId: responseJson.data[0].id})
        }
      }
      const menuNames = responseJson.data?.slice(1).map((item: {id: string, attributes: {title?:string}}) => {
        return {
          id: item.id,
          menuName: item.attributes.title
        }
      })
      this.setState({storeMenuData: responseJson.data?.slice(1)})
      if(menuNames) {
        this.setState({storeMenuList: menuNames})
      }
      this.getStoreSectionDetails(this.state.brandStoreId)
    }
  }

  handleSecDetailsResponse = (responseJson: { data?: SectionDataRes[] }): void => {
    if(responseJson.data) {
      const bannerInfo: SectionDataRes[]  = responseJson.data.filter((item: SectionDataRes) => {
        return item.attributes.section_name === "banner";
      });
      if(bannerInfo[0]) {
        const {banner_name, banner_image, banner_url} = bannerInfo[0].attributes
        this.setState({storeBannerName: banner_name, bannerImage: banner_image, bannerUrl: banner_url})
      }
      const sectionOne: SectionDataRes[]  = responseJson.data.filter((item: SectionDataRes) => {
        return item.attributes.section_name === "section_1";
      });
      
      if(this.state.selectedMenu !== "home") {
        this.handleMenuClick(this.state.selectedMenuId, this.state.selectedMenu)
        this.setState({isMenuOpen: true})
      }
      else {
      if(sectionOne) {
        this.setState({
          storeSectionOne: sectionOne[0]?.attributes.store_section_grids
        });
      }
      const sectionTwo: SectionDataRes[]  = responseJson.data.filter((item: SectionDataRes) => {
        return item.attributes.section_name === "section_2";
      });
      if(sectionTwo[0]) {
        this.setState({
          storeSectionTwo: sectionTwo[0]?.attributes.store_section_grids
        });
      }
      const sectionThree: SectionDataRes[]  = responseJson.data.filter((item: SectionDataRes) => {
        return item.attributes.section_name === "section_3";
      });
      if(sectionThree[0]) {
        this.setState({
          storeSectionThree: sectionThree[0]?.attributes.store_section_grids
        });
      }
    }
    }
  }

  async componentDidMount(): Promise<void> {
    const path = window.location.pathname.split("/")
    const storeId = path[2]
    this.setState({brandStoreId: storeId, selectedMenuId: path[4] !== undefined ? path[4] : '', selectedMenu: path[4] ? path[3] : 'home'})
    if(storeId) {
      this.getStoreInfo(storeId)
    }
  }

  getStoreInfo = async(storeId: string) => {
    this.setState({
      apiInprogress: true
    })
    
    const headers = { token: GetCookies("ba")  };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStoreInfoApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + storeId + "/store_menus");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }
  
  getStoreSectionDetails = (storeId: string) => {
    const headers = { token: GetCookies("ba")  };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getStoreSectionDetailsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.apiCreateStoreEndpoint + "/" + storeId + "/store_dashboard_sections");
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }

  showMenu = (menuId: string) => {
    const headers = { token: GetCookies("ba")  };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.showMenuApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),  `bx_block_catalogue/catalogues/list_store_menu_products?menu_id=${menuId}&per_page=10&page=${this.state.pageNumber}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getMethod);
    runEngine.sendMessage(requestMessage.id, requestMessage); 
  }

  productSliderSettings = {
    centerMode: false,
    infinite: true,
    className: "center",
    centerPadding: "200px",
    slidesToShow: 2.5,
    speed: 500,
    rows: 1,
    slidesPerRow: 2,
    padding: "10px 0px",
    prevArrow: <ArrowBackIos style={styles.arrow} />,
    nextArrow: <ArrowForwardIos style={styles.arrow} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 0.5,
          slidesToScroll: 1,
        },
      },
    ],
  };

  handleMenuClick = (menuId:string, menuName: string | undefined) => {
    this.props.navigation.navigate("BrandStore", {id: this.state.brandStoreId, menu: menuName?.toLocaleLowerCase().replace(/\s+/g, ''), menuId: menuId})
    this.showMenu(menuId)
    this.setState({isMenuOpen: true, selectedMenuId: menuId, selectedMenu: menuName as string})
  }

  handleProduct = (productId: string) => {
    this.props.navigation.navigate("ProductQuickview", {id: productId})
  }

  getMenuStyle = (text: string) => {
    const menuName = window.location.pathname.split("/")[3]
    if (menuName === text.toLocaleLowerCase().replace(/\s+/g, '')) {
      return webStyle.activeMenuStyle;
    }
    else {
      return webStyle.inactiveMenuStyle;
    }
  }

  handleHomeMenu = () => {
    this.props.navigation.navigate("BrandStore", {id: this.state.brandStoreId, menu: "home"})
    this.getStoreInfo(this.state.brandStoreId)
    this.setState({isMenuOpen: false, selectedMenu: 'home'})
  }

  handlePagination = (value: number) => {
    this.setState({ pageNumber: value }, () =>  this.showMenu(this.state.selectedMenuId)) 
  }

  getCount = () => {
    const count: number = this.state.totalCount / 10;
    const roundedCount = Number.isInteger(count) ? count : Math.ceil(count); 
    return roundedCount
  }

  // Customizable Area End
}