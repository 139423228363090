// Customizable Area Start
import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { galaxyFold, galaxyPhone } from "../../../landingpage/src/assets";
import { Message } from "../../../../framework/src/Message";
import { GetCookies, SetCookies } from "../../../../framework/src/WebUtilities";
import { toast } from "react-toastify";
import { getStorageData, setStorageData } from "framework/src/Utilities";
export const configJSON = require("../../src/config");

export interface IProductData {
  id: string,
  attributes: {
    quantity: string,
    price:string,
    item: {
      favourite: {
        id:string
      },
      catalogue: {
        data: {
          id: string,
          attributes: {
            product_image: string,
            product_title: string,
            brand: {
              id: string,
              brand_name: string
            },
            stocks?: number,
            status?: boolean,
            content_status?: string,
            product_content: {
              product_attributes: {
                unique_psku: string
              }
            }
          }
        }
      }
    }
  }
}

export interface CartItem {
  id: string;
  quantity: number;
  calculated_price: number;
  attributes: {
    product_image: string, 
    product_title: string, 
    stocks?: number | null,
    content_status?: string | null,
    status?: boolean,
    brand: {
      id: string,
      brand_name: string
    },
    product_content: {
      product_attributes: {
        retail_price: number;
      };
    },
    
  };
  // Add more properties if needed
}
export interface ITrendingItems {
  id: string,
  attributes:{
    product_image: string,
    product_name: string
  }
}

export interface IOrderItem {
  id: string,
  quantity: string | number
}

export interface IPriceDetails {
  total_fees: string | number, 
  total_tax: string,
  final_price: string | number,
  discount: string
}

export interface IProductData {
  product_image: string, 
  product_title: string, 
  brand: {
    id: string,
    brand_name: string
  }
}

export interface IUserDetails {
  data: {
    attributes: {
      current_order: string | null
    }
  }
}

export interface IProductListRes {
  data: {
    id: string,
    attributes: {
      total_items: number,
      total_fees: string | number, 
      total_tax: string,
      final_price: string | number,
      discount: string,
      order_items: {
        data: IProductData[]
      }
    }
  }
}

interface RecentProductResp {
  data: ProductView[];
}

interface ProductView {
  id: string;
  type: string;
  attributes: ProductViewAttributes;
}

interface ProductViewAttributes {
  id: number | string;
  catalogue_id: number;
  catalogue: Catalogue;
}

interface Catalogue {
  data: CatalogueData;
}

interface CatalogueData {
  id: string;
  type: string;
  attributes: CatalogueAttributes;
}

interface CatalogueAttributes {
  id: number;
  category_id: number;
  brand_id: number;
  seller_id: number;
  sku: string;
  besku: string;
  product_title: string | null;
  is_variant: boolean;
  fulfilled_type: string;
  product_type: string;
  stocks: number;
  content_status: string;
  status: boolean;
  product_image: string;
  product_rating: {
    average_rating: number,
    total_reviews: number
  } | null;
  product_content: {product_attributes: ProductAttributes} | null;
  seller: Seller;
}

interface Seller {
  data: {
    attributes: {
      seller_rating: {
        average_rating: number, 
        total_reviews: number
      }
    }
  }
}

type RetailPrice = string | number | null;

interface ProductAttributes {
  id: number;
  mrp?: RetailPrice,
  retail_price: RetailPrice,
}

export interface RecentProduct {
  id: number,
  product_image: string,
  product_title: string | null,
  mrp?: string | number | null,
  retail_price: string | number | null;
  average_rating: number,
  total_reviews: number
}


// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: {
    cardContainer: string;
    path: string,
    background: string,
    span: string,
    cartText: string,
    cartBox: string,
    cartBoxDes: string,
    productWrapper: string,
    productDetailsWrapper: string,
    tableHeaderText: string,
    flex: string,
    productName: string,
    color: string,
    brandName: string,
    quantity: string,
    quantityField: string,
    priceWrapper: string,
    priceTextOne: string,
    removeBtn: string,
    rightSide: string,
    coupon: string,
    apply: string,
    text: string,
    detail:string,
    nextBtn: string,
    continueBtn: string,
    continueBtnWrapper: string,
    bottomWrapper: string,
    trendingProd: string,
    dualSliderImg: string,
    trendingProductsSlider: string,
    newBtn: string,
    cardDesc: string,
    cardProdName: string,
    cardProdPrice: string,
    cardPrice: string,
    priceValue: string,
    discount: string,
    ratingBox: string,
    star: string,
    imgBox: string,
    orangeText: string,
    vertical: string,
    priceContainer: string,
    bottomContainer: string,
    horizontal: string,
    heading: string,
    headingTwo: string,
    arrowUp: string,
    arrowDown: string,
    arrowContainer: string,
    right: string,
    detailHeader: string,
    detailOne: string,
    cartEmpty: string,
    container: string,
    wrap: string,
    linkHeader: string,
    image: string,
    scrollBar: string,
    couponText: string,
    productInfoContainer: string,
    express: string,
    noBrowsingText: string
  }
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  quantity: {id: string, quantity: string | number }[],
  couponData: string,
  productData: IProductData[],
  priceDetails: IPriceDetails,
  currentOrder: string,
  loading: boolean,
  favLoader: boolean,
  cartData: CartItem[],
  cartId: string,
  itemCount: number | null,
  accountId: string,
  disabled: boolean,
  recentProducts: RecentProduct[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class OrderSummaryController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCartItemsApiCallId: string = ""
  getUserDetailsApiCallId: string = ""
  setFavItemApiCallId: string = ""
  removeFavItemApiCallId: string = ""
  removeCartItemApiCallId: string = ""
  updateItemApiCallId: string = ""
  getRecentProductApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.NavigationPayLoadMessage), getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      quantity: [],
      couponData: '',
      productData: [],
      priceDetails: {
        total_fees: '', 
        total_tax: '',
        final_price: '',
        discount: ''
      },
      currentOrder: '',
      loading: false,
      favLoader: false,
      cartId: '',
      cartData: [],
      itemCount: null,
      accountId: '',
      disabled: false,
      recentProducts: [],
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  // Customizable Area Start

  trendingProdCarousel :ITrendingItems[] = [
    { id: '1', attributes: { product_image: galaxyPhone, product_name: "Product 1" }},
    { id: '2', attributes: {product_image: galaxyFold, product_name: "Product 2" }},
    { id: '3', attributes: {product_image: galaxyPhone, product_name: "Product 3" }},
    { id: '4', attributes: {product_image: galaxyFold, product_name: "Product 4" }},
    { id: '5', attributes: {product_image: galaxyPhone, product_name: "Product 5" }},
    { id: '6', attributes: {product_image: galaxyFold, product_name: "Product 6" }},
    { id: '7', attributes: {product_image: galaxyPhone, product_name: "Product 7" }},
    { id: '8', attributes: {product_image: galaxyFold, product_name: "Product 8" }},
    { id: '9', attributes: {product_image: galaxyPhone, product_name: "Product 9" }},
    { id: '10', attributes: {product_image: galaxyFold, product_name: "Product 10" }},
    { id: '11', attributes: {product_image: galaxyPhone, product_name: "Product 11" }},
    { id: '12', attributes: {product_image: galaxyFold, product_name: "Product 12" }},
    { id: '13', attributes: {product_image: galaxyPhone, product_name: "Product 13" }},
  ];

  handleQuantityChange = (value: string,catalogId:string,prodId:string) => {
    const isNumber = Number(value)
    this.setState(prevState => {
      const updatedQuantity = [...prevState.quantity];
      const indexValue = updatedQuantity.findIndex(catObj => catObj.id === catalogId);
      if (indexValue !== -1 && value !== '0' && !isNaN(isNumber)) {
        updatedQuantity[indexValue].quantity = value;
      }
      this.updateQuantity(prodId, updatedQuantity[indexValue].quantity as string)
      return { quantity: updatedQuantity };
    });
    
  }

  handleIncrease = (itemId: string, prodId: string) => {
    
    this.setState(prevState => {
      const updatedQuantity = [...prevState.quantity];
      const indexValue = updatedQuantity.findIndex(itemObj => itemObj.id === itemId);
      if (indexValue !== -1 && (Number(updatedQuantity[indexValue].quantity) < 99)) {
        updatedQuantity[indexValue].quantity = Number(updatedQuantity[indexValue].quantity) + 1;
      }
      this.updateQuantity(prodId, updatedQuantity[indexValue].quantity as string)
      return { quantity: updatedQuantity };
    });
  };

  handleDecrease = (itemId: string, prodId: string) => {
    this.setState(prevState => {
      const updatedQuantity = [...prevState.quantity];
      const indexValue = updatedQuantity.findIndex(objVal => objVal.id === itemId);
      if (indexValue !== -1 && updatedQuantity[indexValue].quantity as number > 1) {
        updatedQuantity[indexValue].quantity =  (updatedQuantity[indexValue].quantity as number) - 1;
      }
      this.updateQuantity(prodId, updatedQuantity[indexValue].quantity as string)
      return { quantity: updatedQuantity };
    });
  };

  handleContinue = async() => {
    const token = GetCookies("ba")
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token,
    };
    const cartMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCartItemsApiCallId = cartMessage.messageId;
    cartMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/orders/${this.state.currentOrder}`
    );
    cartMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    cartMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(cartMessage.id, cartMessage);

    this.handleNavigate(token);
  }

  handleNavigate = (token: string) => {
    setTimeout(() => {
      if(!this.state.disabled) {
        if(token) {
          SetCookies('currentOrder',this.state.currentOrder, 7)
          this.props.navigation.navigate("ShippingAddress");
        }
        else {
          const message: Message = new Message(getName(MessageEnum.SendMessage));
          message.addData(getName(MessageEnum.OpenBuyerEmailLoginModal), true);
          this.send(message);
        }
      }
    }, 1000);
  }

  handleFavouriteItem = (item: {favourite: {id: string}, catalogue: {data: {id: string}}}, isFav: boolean | unknown) => {
    this.setState({favLoader : true})
    if(isFav) {
      this.removeFavourite(item.favourite.id)
    }
    else this.setFavourite(item.catalogue.data.id)
  }

  handleRemoveItem = (itemId: string) => {
    this.setState({favLoader: true})
    this.removeCartItem(itemId)
  }

  handleContinueShoppingBtn = () => {
    this.props.navigation.navigate("Home")
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleSuccessApiResponse(responseJson, apiRequestCallId);
    }
  }

  handleSuccessApiResponse = (responseJson:unknown, apiRequestCallId:string) => {

    if (apiRequestCallId === this.getUserDetailsApiCallId) {
      const userRes = responseJson as IUserDetails
      this.setState({currentOrder: userRes.data?.attributes.current_order as string})
    }
    if (apiRequestCallId === this.getCartItemsApiCallId) {
      const cartRes = responseJson as IProductListRes
      if(cartRes.data) {
        
        const isAnyItemUnavailable = cartRes.data.attributes.order_items.data.length === 0 || cartRes.data.attributes.order_items.data.map((item) => {
          const { stocks, content_status, status } = item.attributes.item.catalogue.data.attributes;
          const isItemAvailable = status && !!content_status && !!stocks && stocks > 0 && content_status !== "archived";
          return !isItemAvailable;
        }).includes(true);

        this.setState({ productData: cartRes.data.attributes.order_items.data,
          disabled: isAnyItemUnavailable,
          priceDetails:  cartRes.data.attributes, cartId: ''})
          const quantities = cartRes.data.attributes.order_items.data.map((orderItem:IProductData) => {
            const { id, attributes } = orderItem;
            const { quantity } = attributes;
            return { id, quantity };
          });
          this.setState({
            quantity: quantities,
            itemCount: cartRes?.data?.attributes?.total_items
          });
      }
      this.setState({loading: false})
    }
    if (apiRequestCallId === this.setFavItemApiCallId) {
      this.getCartItems(this.state.currentOrder)
      toast.success("Item successfully added to the wishlist")
    }
    if (apiRequestCallId === this.removeFavItemApiCallId) {
      this.getCartItems(this.state.currentOrder)
      toast.success("Item successfully removed from the wishlist")
    }
    if (apiRequestCallId === this.removeCartItemApiCallId) {
      this.setState({cartId: this.state.currentOrder})
      this.getCartItems(this.state.currentOrder)
    }

    if (apiRequestCallId === this.updateItemApiCallId) {
      const updatedRes = responseJson as IProductListRes
      this.setState({favLoader: true, cartId: updatedRes.data.id})
      this.getCartItems(this.state.currentOrder)
    }

    if(apiRequestCallId === this.getRecentProductApiCallId) {
      const recentProducts = responseJson as RecentProductResp
      this.handleRecentProductResp(recentProducts)
    }
  }

  async componentDidMount(): Promise<void> {
    const token =  GetCookies("ba")
    const accountId =  GetCookies("accountId")
    if(token) {
      this.setState({accountId: accountId})
      this.getUserDetails()
      this.getRecentItems()
    }
    else {
      this.setState({accountId: ''})
      this.getCartItemList()
    }
  }

  getUserDetails = async () => {
    const token = GetCookies("ba");
    this.setState({loading : true})
    const headers = { "Content-Type": configJSON.productApiContentType };
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getUserDetailsApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getLoggedUserApiEndPoint + `${token}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(message.id, message);
  };

  getCartItems = (orderId:string) => {
    if(!this.state.favLoader){
    this.setState({loading: true})
    }
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: GetCookies("ba"),
    };
    const cartMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCartItemsApiCallId = cartMessage.messageId;
    cartMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/orders/${orderId}`
    );
    cartMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    cartMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(cartMessage.id, cartMessage);
  }

  getRecentItems = () => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: GetCookies("ba"),
    };
    const recentItemsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getRecentProductApiCallId = recentItemsMessage.messageId;
    recentItemsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.recentItemsEndpoint
    );
    recentItemsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    recentItemsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(recentItemsMessage.id, recentItemsMessage);
  }

  handleRecentProductResp = (resp: RecentProductResp) => {
    if(resp.data.length > 0 ) {
      const data = resp.data.map((item: ProductView)=>{
          const { product_image, product_title, product_content, seller} = item.attributes.catalogue.data.attributes
          const { retail_price, mrp} = product_content?.product_attributes || {}
          const { catalogue_id } = item.attributes || {}
          const productRating =  item.attributes.catalogue.data.attributes.product_rating || null;
          return {
            id: catalogue_id,
            product_image,
            product_title: product_title,
            mrp: mrp || null,
            retail_price: retail_price || null,
            average_rating: productRating?.average_rating || 0, 
            total_reviews: productRating?.total_reviews || 0
          }
        })
      this.setState({
        recentProducts: data
      })
    }
  }

  handleRedirectProduct = (productId: string) => {
    this.props.navigation.navigate("ProductQuickview", {id: productId})
  }

  updateQuantity = (catId:string,value:string) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: GetCookies("ba"),
    };
    const httpBody = {    
      "order_items": {
        "catalogue_id": catId,
        "shopping_cart": true,
        "quantity": value   
      }
    }
    const cartMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateItemApiCallId = cartMessage.messageId;
    cartMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/order_items`
    );
    cartMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    cartMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    cartMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(cartMessage.id, cartMessage);
  }

  setFavourite = (prodId: string) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: GetCookies("ba"),
    };

    const httpBody = {
      "favouriteable_id": Number(prodId)  
    }
    
    const favMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.setFavItemApiCallId = favMessage.messageId;
    favMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_favourites/favourites`
    );
    favMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    favMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    favMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    runEngine.sendMessage(favMessage.id, favMessage);
  }

  removeFavourite = (prodId: string) => {
    const headers = {
      token: GetCookies("ba"),
    };
    
    const removeFavMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeFavItemApiCallId = removeFavMessage.messageId;
    removeFavMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_favourites/favourites/${prodId}`
    );
    removeFavMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    removeFavMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    runEngine.sendMessage(removeFavMessage.id, removeFavMessage);
  }

  removeCartItem = (itemId: string) => {
    const headers = {
      token: GetCookies("ba"),
    };
    
    const removeCartMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeCartItemApiCallId = removeCartMessage.messageId;
    removeCartMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_shopping_cart/order_items/${itemId}`
    );
    removeCartMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    removeCartMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    runEngine.sendMessage(removeCartMessage.id, removeCartMessage);
  }

  getCartItemList = async() => {
    const list =  await getStorageData("cart_items", true)
    const dataWithQuantities = list.map((item:CartItem) => ({
      ...item,
      quantity: item.quantity
    }));

    const combinedData = Object.values(dataWithQuantities.reduce((accumulator: Record<string, CartItem>, item:CartItem) => {
      if (accumulator[item.id]) {
        accumulator[item.id].quantity += item.quantity;
        accumulator[item.id].calculated_price = accumulator[item.id].quantity * item.attributes.product_content.product_attributes.retail_price;
      } else {
        accumulator[item.id] = { ...item, calculated_price: item.quantity * item.attributes.product_content?.product_attributes.retail_price };
      }
      return accumulator;
    }, {}));

    this.setState({ cartData: combinedData as CartItem[],cartId: ''})
    const quantities = combinedData.map((orderItem) => {
      const { id, quantity } = orderItem as IOrderItem;
      return { id , quantity };
    });
    this.setState({
      quantity: quantities,
    });
    const totalCalculatedPrice = combinedData.reduce((total:number, item:unknown) => total + (item as CartItem).calculated_price, 0);
    this.setState({priceDetails: {...this.state.priceDetails, total_fees: totalCalculatedPrice, final_price: totalCalculatedPrice} })
    this.setItemCount()
    this.getCartItems(this.state.currentOrder)
  }

  handleRemoveItemFromCart = async(productId:string) => {
    const filteredData = this.state.cartData.filter((item:{id: string}) => item.id !== productId)
    this.setState({cartData: filteredData})
    await setStorageData("cart_items", JSON.stringify(filteredData))
    this.setItemCount()
  }

  handleIncreaseQuantity = async(catalogueId: string, price:unknown, quantity:number ) => {
    const newQuantity = quantity + 1;

    const newPrice = parseFloat(price as string) * newQuantity;
    const indexValue = this.state.cartData.findIndex(item => item.id === catalogueId);

    if (indexValue !== -1 && (Number(newQuantity <= 99))) {
        const updatedProdData = [...this.state.cartData];

        updatedProdData[indexValue].quantity = newQuantity;
        updatedProdData[indexValue].calculated_price = newPrice;

        const quantities = updatedProdData.map((orderItem:IOrderItem) => {
          const { id, quantity } = orderItem;
          return { id, quantity };
        });
        this.setState({
          quantity: quantities,
        });

        this.setState({ cartData: updatedProdData });
        this.calculatePrice(updatedProdData)
        await setStorageData("cart_items",JSON.stringify(updatedProdData))
        this.setItemCount()
    }
  }

  handleDecreaseQuanitity = async(catalogueId: string, price:unknown, quantity:number) => {
    const newQuantity = quantity - 1;

    const newPrice = parseFloat(price as string) * newQuantity;
    const indexValue = this.state.cartData.findIndex(item => item.id === catalogueId);

    if (indexValue !== -1 && newQuantity >= 1) {
        const newProductData = [...this.state.cartData];

        newProductData[indexValue].quantity = newQuantity;
        newProductData[indexValue].calculated_price = newPrice;

        const quantities = newProductData.map((orderItem:IOrderItem) => {
          const { id, quantity } = orderItem;
          return { id, quantity };
        });
        this.setState({
          quantity: quantities,
        });
        this.setState({ cartData: newProductData });
        this.calculatePrice(newProductData)
        await setStorageData("cart_items",JSON.stringify(newProductData))
        this.setItemCount()
    }

  }

  handleProductQuantityChange = async( value:string,catalogueId: string, price:unknown) => {
    
    const newQuantity = Number(value)
    const newPrice = parseFloat(price as string) * newQuantity;
    const indexValue = this.state.cartData.findIndex(item => item.id === catalogueId);
    if (indexValue !== -1 && value !== '0' && !isNaN(newQuantity)) {
        const quantityProdData = [...this.state.cartData];

        quantityProdData[indexValue].quantity = newQuantity;
        quantityProdData[indexValue].calculated_price = newPrice;

        const quantities = quantityProdData.map((orderItem: IOrderItem) => {
          const { id, quantity } = orderItem;
          return { id, quantity };
        });
        this.setState({
          quantity: quantities,
        });
        this.setState({ cartData: quantityProdData });
        this.calculatePrice(quantityProdData)
        await setStorageData("cart_items",JSON.stringify(quantityProdData))
        this.setItemCount()
    }
  }

  calculatePrice = (productData: CartItem[]) => {
    const totalCalculatedPrice = productData.reduce((total:number, item:unknown) => total + (item as CartItem).calculated_price, 0);
    this.setState({priceDetails: {...this.state.priceDetails, total_fees: totalCalculatedPrice, final_price: totalCalculatedPrice} })
  }

  addToFavourite = async (id: string) => {
    const token = GetCookies("ba")
    if(!token){
      const message: Message = new Message(getName(MessageEnum.SendMessage));
      message.addData(getName(MessageEnum.OpenBuyerEmailLoginModal), true);
      this.send(message);
    }
  }

  setItemCount = async() => {
    const token = GetCookies("ba")
    if(!token) {
      const cartData = await getStorageData("cart_items",true)
      const quantityArr = cartData.map((orderItem:{quantity: string}) => orderItem.quantity);
      const totalQuantity = quantityArr.reduce((accumulator:number, currentValue:number) => accumulator + currentValue, 0);
      this.setState({itemCount: totalQuantity as number, loading:false})
    }
  }

  setOrderId = (orderId:string) => {
    if(orderId) {
      this.getCartItems(orderId)
      this.setState({currentOrder: orderId})
    }
    else {
      this.setState({loading: false})
    }
  }

  handleQuickProductView = (productId: string) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "SingleProductQuickview");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationScreenNameMessage), productId);
    this.send(message)
  }
  // Customizable Area End
}
