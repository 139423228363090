import React from "react";

// Customizable Area Start
import { Box, Container, Grid, IconButton, InputAdornment, Modal } from "@material-ui/core";
import { BoxNoScroll, InputDesign1, InputDesign2, SupportSelectionButton } from "../CustomizedElementStyle";
const configJson = require("../config.js");
import ClearIcon from "@material-ui/icons/Clear";
import { errorImage } from "../../../bulkuploading/src/assets";
import {CustomOrangeButton} from "../../../../components/src/CommonButton";
// Customizable Area End

import ContatusController, { Props } from "./ContatusController";
import DOMPurify from "dompurify";

export class Contatus extends ContatusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  CloseButton = (testIdRef: string) => {
    return (
      <Box sx={webStyle.closeButtonStyle}>
        <IconButton onClick={this.handleCloseButton} data-test-id={testIdRef}>
          <ClearIcon style={{ color: "#3E3E3E" }} />
        </IconButton>
      </Box>
    );
  };

  docsModal = () => {
    return (
      <Modal open={this.state.showDocsModal} hideBackdrop={true}>
        <Box sx={webStyle.modalContainer}>
          <Box sx={webStyle.blurredBackground}></Box>
          <Container maxWidth={"lg"}>
            <Box sx={webStyle.modalWrapper}>
              {this.CloseButton("closeModal")}
              <Box sx={{ display: "flex", width: "100%", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", marginBottom: 25 }}>
                <Box sx={{ fontSize: "27px", fontWeight: 600, fontFamily: `"Lato", sans-serif` }}>{this.state.docs_title}</Box>
              </Box>
              <Grid container>
                <Grid item lg={12} sm={12} xs={12}>
                  {this.state.docs_content.map((item, index) => {
                    return (
                      <>
                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(item)}}>
                        </div>
                        <Box style={{ width: "100%", height: 20 }}></Box>
                      </>
                    );
                  })}
                </Grid>
              </Grid>
            </Box>
          </Container>
        </Box>
      </Modal>
    );
  };
  // Customizable Area End

  // Customizable Area Start
  render() {
    return (
      <>
        {this.docsModal()}
        <Box sx={{ padding: "20px 0px 15px 0px", fontSize: "18px", fontFamily: `"Lato", sans-serif`, fontWeight: 400 }}>{configJson.language[this.props.language].support}</Box>
        <BoxNoScroll style={{ overflow: "auto", paddingBottom: 20 }}>
          <Box style={{ flexDirection: "column", minHeight: 753, borderRadius: "8px", padding: "30px", overflowY: "auto", marginBottom: 50, overflowX: "hidden", position: "relative", display: "flex", background: "white" }}>
            <Box sx={{ display: "flex", width: "100%", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", marginBottom: 10 }}>
              <Box sx={{ fontSize: "27px", fontWeight: 600, fontFamily: `"Lato", sans-serif`, marginBottom: 30 }}>{configJson.language[this.props.language].support}</Box>
              <Grid container spacing={4}>
                {this.state.documents.map((item, index) => {
                  return (
                    <Grid item key={index}>
                      <SupportSelectionButton data-test-id={`docs-${index}`} onClick={() => this.handleOpenModal(item.page_title, item.content)}>
                        {item.page_title.toUpperCase()}
                      </SupportSelectionButton>
                    </Grid>
                  );
                })}
              </Grid>
              <Box style={{ color: "#2B2B2B", fontSize: 16, fontWeight: 700, marginBottom: 22, marginTop: 73, fontFamily: `"Lato", sans-serif` }}>{configJson.language[this.props.language].contact}</Box>
              <Grid container spacing={4}>
                <Grid item lg={6} sm={12} xs={12} style={{ marginTop: 20 }}>
                  <InputDesign1
                    data-test-id="first_name"
                    inputProps={{
                      maxLength: 15
                    }}
                    label={configJson.language[this.props.language].firstName}
                    id="outlined-start-adornment"
                    fullWidth
                    value={this.state.firstName}
                    onChange={event => this.handleOnChange("firstName", event.target.value)}
                    InputProps={{
                      startAdornment: <InputAdornment position="start" style={{ marginRight: 15 }}></InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item lg={6} sm={12} xs={12} style={{ marginTop: 20 }}>
                  <InputDesign1
                    data-test-id="last_name"
                    label={configJson.language[this.props.language].lastName}
                    id="outlined-start-adornment"
                    value={this.state.lastName}
                    inputProps={{
                      maxLength: 15
                    }}
                    onChange={event => this.handleOnChange("lastName", event.target.value)}
                    fullWidth
                    InputProps={{
                      startAdornment: <InputAdornment position="start" style={{ marginRight: 15 }}></InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12} style={{ marginTop: 20 }}>
                  <InputDesign1
                    data-test-id="email"
                    value={this.state.email}
                    onChange={event => this.handleOnChange("email", event.target.value)}
                    label={configJson.language[this.props.language].email}
                    inputProps={{
                      maxLength: 15
                    }}
                    id="outlined-start-adornment"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      startAdornment: <InputAdornment position="start" style={{ marginRight: 15 }}></InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item lg={12} sm={12} xs={12} style={{ marginTop: 20 }}>
                  <InputDesign2
                    data-test-id="details"
                    value={this.state.details}
                    onChange={event => this.handleOnChange("details", event.target.value)}
                    label={configJson.language[this.props.language].details}
                    placeholder="Type here"
                    multiline
                    rows={4}
                    id="outlined-start-adornment"
                    fullWidth
                    inputProps={{
                      maxLength: 1000
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start" style={{ marginRight: 15 }}></InputAdornment>
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ width: "100%", height: 161 }}>
              {this.state.errMsg && (
                <Box sx={webStyle.errorMsgContainer}>
                  <Box sx={{ marginRight: "5px" }}>
                    <img src={errorImage} />
                  </Box>
                  <Box style={{ fontSize: 14, color: "#D74D4D", fontFamily: `"Lato", sans-serif`, fontWeight: 700 }}>{this.state.errMsg}</Box>
                </Box>
              )}
              {this.state.successMsg && (
                <Box sx={webStyle.errorMsgContainer}>
                  <Box style={{ fontSize: 14, color: "#49AF41", fontFamily: `"Lato", sans-serif`, fontWeight: 700 }}>{this.state.successMsg}</Box>
                </Box>
              )}
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
              <CustomOrangeButton
                data-test-id="submit"
                onClick={() => this.handleOnSubmit()}
                style={{
                  position: "absolute",
                  right: 30,
                  bottom: 30
                }}
              >
                {configJson.language[this.props.language].submit}
              </CustomOrangeButton>
            </Box>
          </Box>
        </BoxNoScroll>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  modalContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "none",
    position: "absolute"
  },
  blurredBackground: {
    width: "100%",
    height: "100%",
    backdropFilter: "blur(8px)",
    position: "absolute",
    zIndex: -1,
    backgroundColor: "rgba(0,0,0,0.1)"
  },
  modalWrapper: {
    width: "auto",
    borderRadius: 10,
    position: "relative",
    padding: "30px",
    display: "flex",
    background: "white",
    maxHeight: 600,
    flexDirection: "column",
    overflow: "auto"
  },
  closeButtonStyle: {
    position: "absolute",
    top: "28px",
    right: "28px",
    boxShadow: "0px 3px 6px rgba(86, 86, 86, 0.1)",
    borderRadius: "100%"
  },
  errorMsgContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "no-wrap",
    alignItems: "center",
    marginTop: "20px"
  }
};

export default Contatus;
// Customizable Area End
