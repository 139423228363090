// Customizable Area Start
import React from "react";
import StockLogsController, {
  IWarehouseList,
  IWarehouseProdData,
  Props,
  configJSON,
} from "./StockLogsController.web";
import { withStyles } from "@material-ui/styles";
import { successImg } from "./assets";
import { Box, Checkbox, Grid, InputAdornment, IconButton, Modal, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import {
  CustomMenuItemBox,
    CustomizeBrandInput,
  CustomizeInputSearchBox,
  FormControlTwo,
  MenuItemTwo,
  OrangeButton,
  SearchIconSVG,
} from "../../dashboard/src/CustomizedElementStyle";
import { IProductData } from "../../dashboard/src/StoreManagement/StoreMenuTabController.web";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import { trash_1 } from "../../dashboard/src/assets";
import { Pagination } from "@material-ui/lab";
import ClearIcon from '@material-ui/icons/Clear';
interface StockProps {
    numSelected?: number;
  
    onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    orderBy?: string;
    rowCount?: number;
    classes: {
      header: string
    };
  }
  
  function StockHeader(props: StockProps) {
    const { classes } = props;
  
    return (
      <TableHead style={webStyle.tableHeaderColor}>
        <TableRow>
          <TableCell align="left" className={classes.header} style={webStyle.imageCell}>
            {configJSON.image}
          </TableCell>
          <TableCell align="left" className={classes.header}>
            {configJSON.productNameLabel}
          </TableCell>
          <TableCell align="left" className={classes.header}>
            {configJSON.pskuId}
          </TableCell>
          <TableCell align="center" className={classes.header}>
            {configJSON.productStatus}
          </TableCell>
          <TableCell align="center" className={classes.header} style={{width:'80px'}}>
            {configJSON.stockQty}
          </TableCell>
          <TableCell align="center" className={classes.header}>
            {configJSON.actionLabel}
          </TableCell>
        </TableRow>
      </TableHead>
    );
  }
// Customizable Area End

class StockLogs extends StockLogsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getSearchResultText = () => {
    return(
      this.state.searchProduct ? (
        <Box className={this.props.classes.searchLine}>
          <Box className={this.props.classes.results}>{this.state.searchProduct ? `found ${this.state.searchProdResults.length} results for ${this.state.searchProduct}` : ""}</Box>
          <Box className={this.props.classes.desc}>{configJSON.resultDes}</Box>
        </Box>
      ) : (
        ""
      )
    )
  }

  getStatus = (status:boolean) => {
    return status ? configJSON.activeText : configJSON.inactiveText
  }

  CloseButton = (ref: string) => {
    return (
      <Box sx={webStyle.closeButton}>
        <IconButton data-test-id={ref} onClick={this.handleCloseFWPModal}>
          <ClearIcon style={{ color: "#3E3E3E" }} />
        </IconButton>
      </Box>
    );
  };

  SuccessModal = () => {
    return (
      <Modal open={this.state.showChangeSuccessModal} data-test-id='successModal' hideBackdrop>
        <Box sx={webStyle.mainContainer}>
          <Box sx={webStyle.blurredBackground}></Box>
          <Box sx={webStyle.modalContainer}>
            {this.CloseButton("successModalRef")}
            <Box sx={webStyle.modalLayout}>
              <Box sx={webStyle.verifiedImageWrapper}>
                <Box sx={webStyle.verifiedImageContainer}>
                  <img src={successImg} alt="success image" />
                </Box>
                <Box sx={webStyle.modalText}>{configJSON.stockUpdatedSuccessfullyText}</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    );
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const countValue = this.getCount()
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        {this.SuccessModal()}
        <Box className={classes.box}>
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Typography className={classes.label}>
              {configJSON.fulfilledByPartnerLabel}
            </Typography>
          </Grid>
          <Grid container className={classes.main}>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.stockTextWrapper}
            >
              <Box className={classes.productSearch}>
                {configJSON.stockLogsLabel}
              </Box>
            </Grid>
            <Grid container className={classes.marginOne}>
            <Grid item lg={8} sm={12} xs={12} md={8}>
                  <FormControlTwo fullWidth={true}>
                    <Box className={classes.warehouse}>{"Select Warehouse"}</Box>
                    <Select
                      data-test-id="selectwarehouseDropdown"
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      placeholder="Select Warehouse"
                      value={this.state.selectedWarehouse}
                      onChange={this.handleSelectWarehouse}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null,
                        PaperProps: {
                          style: webStyle.warehouseSelect
                        }
                      }}
                      displayEmpty
                    >
                      <MenuItemTwo value="" disabled >{configJSON.selectWarehouseText}</MenuItemTwo>
                      {this.state.warehouseList.length > 0 && this.state.warehouseList.map((item: IWarehouseList) => <MenuItemTwo data-test-id="warehouselist" value={item.attributes.id}>{item.attributes.warehouse_name}</MenuItemTwo>)}
                    </Select>
                  </FormControlTwo>
                </Grid>
            </Grid>
              <Box>
            <Grid
              item
              xs={12}
              lg={8}
              md={8}
              sm={12}
              className={classes.searchContainer}
            >
              <Typography className={classes.warehouse}>
                {configJSON.searchProductLabel}
              </Typography>
              <Grid className={classes.searchField}>
                <CustomizeInputSearchBox
                  id="outlined-start-adornment"
                  placeholder="#SKU, #CODE"
                  value={this.state.searchProduct}
                  onChange={this.handleSearchProduct}
                  fullWidth
                  className={classes.searchInput}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        style={webStyle.marginRight}
                      ></InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        style={webStyle.rightPad}
                      >
                        <ClearIcon data-test-id="click-away" onClick={this.handleClickAway} style={{...webStyle.crossIcon, display: this.state.searchProduct ? 'flex': 'none'}}/>
                        <SearchIconSVG />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
            {this.getSearchResultText()}

              {this.state.openResults && (
                <>
                <Grid container>
                  <Grid item xs={12} sm={12} md={8} lg={8}>
                <Box
                  className={classes.resultBox}>
                  {this.state.searchProdResults?.map((item: IProductData, index: number) => {
                    const title = item.attributes.product_content?.product_attributes?.product_title;
                    return (
                      <CustomMenuItemBox className={classes.resultWrapper} style={{ background: Boolean(this.state.selectProducts.includes(item.id)) ? "#ff7020b0" : "#fff" }} key={`${item}-${index}`} >
                        <Checkbox onChange={(event) => this.handleAddProduct(event, item)} data-test-id="selectProdFromList" color="default" icon={<RadioButtonUncheckedIcon className={classes.radioStyle} />} checkedIcon={<RadioButtonCheckedIcon className={classes.radioStyle} />} checked={Boolean(this.state.selectProducts.includes(item.id))} />
                        <Box className={classes.textWrap}>
                          <Typography className={classes.title}>{title}</Typography>
                          <Typography className={classes.category}>{this.getRelation(item)}</Typography>
                        </Box>
                      </CustomMenuItemBox>
                    );
                  })}
                </Box>
                </Grid>
                </Grid>
                </>
              )}
              </Box>
              {this.state.prodError ? 
              <Typography style={webStyle.redText}>{this.state.prodError}</Typography> : ''}
            {this.state.stockData.length > 0 && 
            <Grid container style={webStyle.padTop}>
              <TableContainer className={classes.table}>
                <Table aria-label="simple table" style={webStyle.overflow}>
                  <StockHeader
                    data-test-id="tableHeader"
                    classes={classes}
                  />
                  <TableBody data-test-id="warehouseList" className={classes.wrapper}>
                    {this.state.stockData.map((item:IWarehouseProdData, index:number) => {
                      const catalogueData = item.attributes.catalogue.data.attributes
                      const {product_image, status, stocks} = catalogueData;
                      const { unique_psku, product_title } = catalogueData.product_content.product_attributes
                      return(
                    <TableRow className={classes.borderBottom}>
                      <TableCell align="left" style={webStyle.prodImageCell}>
                      <img
                        src={product_image}
                        className={classes.image}
                        alt="img"
                      />
                      </TableCell>

                      <TableCell align="left" style={webStyle.verticalAlign}>
                      <Box
                        className={classes.header} sx={webStyle.prodTitle}
                        >
                        {product_title}
                        </Box>
                      </TableCell>
                      <TableCell align="left" style={webStyle.verticalAlign}>
                        <Typography className={classes.bodyText}>
                          {unique_psku} 
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={webStyle.verticalAlign}>
                      <Typography className={classes.bodyTextOne}>
                          {this.getStatus(status)}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" style={webStyle.verticalAlign}>
                        <CustomizeBrandInput
                        type="text"
                        style={{...webStyle.quantity, border: this.getQuantityLimit(this.state.quantity[index]) ? '0.1px solid red' : 'unset', borderRadius: '5px', maxHeight: '42px'}}
                        defaultValue={Number(this.state.quantity[index])}
                        value={this.state.quantity[index] === '' ? '' : Number(this.state.quantity[index])}
                        onKeyDown={this.handleKeyDown}
                        inputProps={{
                          min: 0,
                        }}
                        onChange={(event:React.ChangeEvent<HTMLInputElement>) => this.handleStockQty(event.target?.value,index)}
                        />
                        {this.state.qtyError[index] ?
                      <Typography style={webStyle.redTextOne} data-test-id="qtyErrText">{this.state.qtyError[index]}</Typography>: ''}
                      </TableCell>
                      
                      <TableCell align="center" style={webStyle.btnBox}>
                        <OrangeButton data-test-id="updateBtn" className={classes.update} disabled={this.getQuantityLimit(this.state.quantity[index])} onClick={() => this.handleUpdateBtn(item.id, this.state.quantity[index])}>{configJSON.updateLabel}</OrangeButton>
                          <img data-test-id="delIcon" src={trash_1} alt="delImg" style={webStyle.delImage} onClick={this.removeProduct.bind(this,item.id)}/>
                      </TableCell>
                    </TableRow>
                    )})}
                  </TableBody>
                </Table>
              </TableContainer>
              {this.state.stockData?.length > 0 && (
                    <Pagination
                      className={classes.pagination}
                      data-test-id="pagination"
                      page={this.state.pageNumber}
                      count={countValue}
                      onChange={(event: React.ChangeEvent<unknown>, value: number) => {
                        this.setState({ pageNumber: value });
                        this.listWarehouseProducts();
                      }}
                      shape="rounded"
                      siblingCount={0}
                      boundaryCount={1}
                    />
                  )}
            </Grid>
            }
          </Grid>
          </Box>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  label: {
    fontFamily: `"Lato", sans-serif` as const,
    padding: "20px 0px 15px 0px",
    fontSize: "18px",
    fontWeight: 400,
  },
  main: {
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as const,
    padding: "30px",
    position: "relative" as const,
    background: "white",
  },
  stockTextWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row" as const,
    flexWrap: "wrap" as const,
    justifyContent: "space-between",
    alignItems: "center" as const,
    marginBottom: 10,
  },
  productSearch: {
    fontSize: "27px",
    fontWeight: 700 as const,
    fontFamily: `"Lato", sans-serif` as const,
  },
  searchContainer: {
    padding: "20px 0px 15px 0px",
    "@media screen and (max-width:460px)": {
      width: "100%",
    },
  },
  searchProductText: {
    color: "#33333A",
    fontSize: "12px",
    fontWeight: 500 as const,
    fontFamily: "Lato, sans-serif",
    marginBottom: 5
  },
  searchField: {
    position: "relative" as const,
  },
  box: {
    overflowY: "auto" as const,
    overflowX: "hidden" as const,
  },
  header: {
    color: "#333333",
    fontSize: "14px",
    fontFamily: `"Lato", sans-serif` as const,
    fontWeight: 600 as const
  },
  table: {
    border: "0.5px solid #ccc",
    borderRadius: "8px",
  },
  wrapper: {
    backgroundColor: "white",
  },
  borderBottom: {
    borderBottom: "1px solid rgba(224,224,224,1)",
  },
  cellData: {
    color: "#697A8D",
    fontSize: "14px",
    fontWeight: 400 as const,
  },
  image: {
    height: "80px",
    width: "80px",
    objectFit: "fill" as const,
    borderRadius: "8px",
  },
  bodyText: {
    color: '#232529',
    fontSize:'14px',
    fontFamily: `"Lato", sans-serif` as const,
    fontWeight: 400 as const
  },
  update: {
    padding:'6px 20px',
    fontSize:'14px',
    borderRadius:'5px',
    fontFamily: `"Lato", sans-serif` as const,
    '@media (max-width:1240px)': {
      marginRight: '10px'
    }
  },
  textWrap: {
    marginLeft: "8px",
  },
  marginOne: {
    marginTop: "20px",
  },
  productText: {
    fontSize: "24px",
    fontWeight: 600,
    marginTop: "-30px",
    fontFamily: `"Lato", sans-serif`,
  },
  boxWrap: {
    marginTop: "20px",
    width: "70%",
    padding: "16px",
  },
  resultWrapper: {
    display: "flex",
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 8px",
    background: "#fff",
    margin: "12px",
    borderRadius: "5px",
    padding: "16px 10px",
  },
  radioStyle: {
    color: "#666666",
    paddingLeft: "0",
    "&.Mui-checked": {
      color: "#666666",
    },
  },
  title: {
    fontSize: "16px",
    fontFamily: `"Lato", sans-serif`,
    color: "#33333a",
    wordBreak: "break-all" as const
  },
  category: {
    fontSize: "14px",
    fontFamily: `"Lato", sans-serif`,
    color: "#697A8D",
  },
  searchLine: {
    marginBottom: 20,
    display: "flex",
    flexDirection: "row" as const,
    marginTop: 20,
  },
  results: {
    color: "#666666",
    fontWeight: 1000,
    fontSize: 12,
    fontFamily: `"Lato", sans-serif`,
    marginRight: 5,
  },
  desc: {
    fontFamily: `"Lato", sans-serif`,
    fontWeight: 500,
    color: "#666666",
    fontSize: 12,
  },
  resultBox: {
    position: "relative" as const,
    marginTop: "12px",
    maxHeight: "250px",
    overflowY: "auto" as const,
    scrollbarWidth: "thin" as const,
    borderRadius: "5px",
    marginLeft: "-12px",
    marginBottom: '30px',
    width: '102%'
  },
  warehouse: { 
    color: "#9a9a9a", 
    fontFamily: `"Lato", sans-serif`, 
    fontWeight: 600, 
    fontSize: 12, 
    marginBottom: 5 
  },
  searchInput: {
    borderRadius: '5px',
    "& .MuiInputBase-root": {
      border: "1px solid #b1b3b5"
    },
    "& .MuiInputBase-input::placeholder": {
      fontFamily: `"Lato", sans-serif !important` as const,
      color: '#333333 !important',
      fontSize:'14px !important',
      opacity: 'initial'
    }
  },
  bodyTextOne: {
    color: '#232529',
    fontSize:'14px',
    fontFamily: `"Lato", sans-serif` as const,
    fontWeight: 700 as const
  },
  pagination: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px",
  }
};

const webStyle = {
  warehouseSelect: {
    background: "white",
    border: "1px solid #b1b3b5",
    boxShadow: "none",
    borderRadius: 5,
    marginTop: "5px",
    padding: 0,
    maxHeight: "400px",
    overflowY: "auto" as const
  },
  quantity: {
    maxWidth:'80px'
  },
  verticalAlign: {
    verticalAlign: 'top'
  },
  btnBox: {
    verticalAlign: 'top',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    borderBottom: 0
  },
  prodImageCell: {
    verticalAlign: 'top',
    paddingLeft: '30px'
  },
  imageCell: {
    paddingLeft: '30px'
  },
  delImage: {
    width:'20px',
    height: '20px',
    cursor: 'pointer'
  },
  redText: {
    margin: '20px 0 20px 20px',
    color: 'rgb(215,77,77)',
    fontSize:'12px',
    fontFamily: `"Lato", sans-serif` as const,
    fontWeight: 400 as const
  },
  tableHeaderColor: {
    backgroundColor: "#f7f7f7"
  },
  marginRight: {
    marginRight: 20 
  },
  rightPad: {
    paddingRight: 20,
    display:'flex'
  },
  padTop: { 
    paddingTop: "20px" 
  },
  overflow: {
    overflowX: 'auto' as const
  },
  prodTitle: {
    width:'210px',
    wordBreak: "break-all",
  },
  redTextOne: {
    marginTop: '5px',
    color: 'rgb(215,77,77)',
    fontSize:'12px',
    fontFamily: `"Lato", sans-serif` as const,
    fontWeight: 400 as const
  },
  crossIcon: {
    borderRadius:'50%',
    marginRight: '10px', 
    padding:'5px', 
    backgroundColor: '#e5e5e5', 
    color: '#666',
    cursor: 'pointer',
    width:'20px',
    height: '20px'
  },
  mainContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "none"
  },
  blurredBackground: {
    position: "absolute",
    zIndex: -1,
    backgroundColor: "rgba(0,0,0,0.1)",
    width: "100%",
    height: "100%",
    backdropFilter: "blur(8px)"
  },
  modalContainer: {
    display: "flex",
    width: "450px",
    padding: "50px",
    height: 300,
    background: "white",
    borderRadius: "8px",
    flexDirection: "column",
    margin: "15px",
    position: "relative"
  },
  modalLayout: { 
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  },
  subHeaderBox: {
    marginTop: "22px",
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  verifiedImageWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  verifiedImageContainer: {
    width: "100px",
    height: "100px",
    marginBottom: "24px"
  },
  closeButton: {
    position: "absolute",
    top: "28px",
    right: "28px",
    boxShadow: "0px 3px 6px rgba(86, 86, 86, 0.1)",
    borderRadius: "100%"
  },
  modalText: { 
    margin: "10px 0px 20px 0px",
    fontWeight: "bold",
    fontSize: "20px",
    fontFamily: `"Lato", sans-serif` as const,
    color: "#121212",
    textAlign: 'center'
  }
}

export {StockLogs}
export default withStyles(styles)(StockLogs);
// Customizable Area End
