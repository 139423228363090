import { MouseEvent } from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("../src/config.js")
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { setStorageData } from "framework/src/Utilities";
interface DocumentsData {
  attributes: any,
  id: any,
  warehouse_type: string,
  warehouse_name: string,
  warehouse_address: string,
  created_at: string,
  updated_at: string,
  contact_number: any,
  contact_person: any,
  processing_days: any,
  account_id: any

}
interface SuccessResponse {
  data: DocumentsData[];
  total_count:number,
  meta: {
    message: string;
  };
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  lastUpdatedDealsId?: string;
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  myDealactiveStep: string;
  dealsList: Array<Object>;
  dealsWithSKUList: Array<Object>;
  anchorEl: null | HTMLElement;
  amount: any;
  isEdit: boolean;
  errorMessage: any;
  dealsValue: any;
  token: string;
  dealsDdValues: string;
  startDate: any;
  editDealValue: Array<Object>;
  editItemsId: any;
  successModal: boolean;
  page: number,
  totalCount: number,
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class MyDealsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getDropdownListId: string = "";
  getDealsListCallId: string = "";
  deleteDealsApiCallId: string = "";
  editDealsApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReceiveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      myDealactiveStep: "mydealsStep",
      anchorEl: null,
      isEdit: false,
      errorMessage: "",
      dealsValue: "",
      amount: 0,
      token: "",
      dealsDdValues: '',
      startDate: {},
      editDealValue: [],
      editItemsId: {},
      successModal: false,
      dealsList: [],
      dealsWithSKUList: [],
      totalCount: 0,
      page: 1,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recives", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const errorJson = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (this.getDropdownListId === apiRequestCallId) {
        this.handleDropdownlistApiCallResponse(responseJson, errorJson);
      }
      if (this.getDealsListCallId === apiRequestCallId) {
        this.handleGetDealsListApiResponse(responseJson);
      }
      if (this.deleteDealsApiCallId === apiRequestCallId) {
        this.handleDeleteDealsApiResponse(responseJson, errorJson);
      }
      if (this.editDealsApiCallId === apiRequestCallId) {
        this.handleEditDealsApiResponse(responseJson);
      }
    }
    // Customizable Area End
  }
  handleDropdownlistApiCallResponse = (responseJson: SuccessResponse, errorJson: any) => {
    const { lastUpdatedDealsId } = this.props;
    if (responseJson) {
      this.setState({ dealsList: responseJson?.data, }, () => {
        this.handleChangeMenuItem(lastUpdatedDealsId ? lastUpdatedDealsId : responseJson.data[0]?.attributes?.id)
      });
    } else {
      this.setState({ dealsList: [] })
      this.parseApiCatchErrorResponse(errorJson);
    }
  };

  getRoundedCount = (totalCount:number) => {
    const count: number = totalCount / 10;
    const roundedCount = Number.isInteger(count) ? count : Math.ceil(count); 
    return roundedCount
  }


  handleGetDealsListApiResponse = (responseJson: SuccessResponse) => {
    if (responseJson) {
      this.setState({ dealsWithSKUList: responseJson.data ,totalCount:this.getRoundedCount(responseJson.total_count)}, () => {
      });
    }
  }
  handleDeleteDealsApiResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson) {
      toast.success("Deals deleted successfully!", {
        position: "top-right",
        autoClose: 3000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      this.setState({ dealsValue: '' })
      this.handleChangeMenuItem(this.state.dealsDdValues);
      this.handleCloseAction();
    }

    if ("errors" in responseJson || errorResponse) {
      toast.error("Failed to delete deal. Please try again later!", {
        autoClose: 3000,
        draggable: true,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        pauseOnHover: true,
        position: "top-right",
      });
    }
  }
  handleEditDealsApiResponse = (responseJson: SuccessResponse) => {
    if (responseJson) {
      this.setState({ successModal: true });
      this.setState({ editDealValue: responseJson.data })
      this.handleChangeMenuItem(this.state.dealsDdValues);
    }

    this.setState({ myDealactiveStep: 'mydealsStep' });
    this.handleCloseAction();
  }
  handleStepMydeals(step: string) {
    this.setState({ myDealactiveStep: step });
  }
  handleCloseAction = () => {
    this.setState({ anchorEl: null });
  }

  handleClickAction = (event: MouseEvent<HTMLButtonElement>, item: any) => {
    this.setState({ anchorEl: event?.currentTarget, editItemsId: item, dealsValue: item.attributes.deal_price });
  };
  editDealHandler() {
    this.setState({ isEdit: true, myDealactiveStep: 'selectSKU' });
  }
  removeDealHandler() {
    this.setState({ isEdit: true });
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.deleteDealsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.myDealsApiEndPoint.delete(this.state.editItemsId?.attributes?.deal_id, this.state?.editItemsId.id));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeDelete);
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  handleCloseSuccessModal() {
    this.setState({ successModal: false });
  }
  onValueChange = (value: string) => {
    this.setState({ dealsValue: value });
  };

  handleStatusColor = (value: string) => {
    const style = {
      borderRadius: 5,
      textTransform: 'uppercase' as const,
      fontFamily: `"Lato", sans-serif`,
      width: '110px',
      padding: '4px 10px'
    }
    switch (value) {
      case 'review':
        return { ...style, backgroundColor: '#fcebe4', color: '#ef691c' }
      case 'approved':
        return { ...style, backgroundColor: '#90EE90', color: '#1b5e20' }
      case 'rejected':
        return { ...style, backgroundColor: '#FFCCCB', color: '#c62828' }
    }
  }
  onEditTask = () => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.token
    };
    const httpBody = {
      "data": {
        "attributes": {
          "deal_price": this.state.dealsValue,
          "status": "review"
        }
      }
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.editDealsApiCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.myDealsApiEndPoint.edit(this.state.editItemsId?.attributes?.deal_id, this.state.editItemsId?.id));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePatch);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  getToken = async () => {
    const token = await getStorageData("authToken");  
    this.setState({ token })
  }

  getAllDropDownMenu = () => {
    const headers = {
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getDropdownListId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.myDealsApiEndPoint.list + `?per_page=100&page=1`) ;
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleChangeMenuItem = (id: any,page=1 ) => {
    this.setState({ dealsDdValues: id , page });
    let startDate = this.state.dealsList.filter((item: any) => item.id == id)
    this.setState({ startDate: startDate[0] })
    const headers = {
      token: this.state.token
    };
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getDealsListCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.myDealsApiEndPoint.createDeals(id)+ `?per_page=10&page=${page}`);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handelGoToNextpage = () => {
    setStorageData('checkEdit', true)
    this.props.navigation.navigate('SellerHomeDashboard', {path: 'products',addSingleProduct:true})
  }
  // Customizable Area End

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    await this.getToken();
    this.getAllDropDownMenu();
    this.setState({ myDealactiveStep: this.state.myDealactiveStep });
  }
  // Customizable Area End
}
