import { Box, Typography, Button, Grid, TextField, styled, withStyles, Select } from "@material-ui/core";
import React from "react";
import { Divider } from "react-native-elements";
import UserAddressController, { Props, configJSON } from "./UserAddressController.web";
import { errorImage } from "../../bulkuploading/src/assets";
import { styledAddressList as UserAddressList } from "./UserAddressList.web";
import { checkbox, checkboxTicked } from "./assets";
import { FormControlTwo, MenuItemTwo } from "../../dashboard/src/CustomizedElementStyle";
import GooglePlaceAutoComplete from "../../geofence/src/GooglePlaceAutoComplete.web";
import  MapComponent from "../../../components/src/MapComponent.web";


const CustomBox = styled(Box)({
    marginTop: "30px",
    paddingRight: "40%",
    "@media(min-width: 960px)": {
        paddingRight: "38%",

    },


})

const CustomButton = styled(Button)({
    background: "rgb(253, 97, 12) none repeat scroll 0% 0%",
    padding: "10px 0px",
    fontWeight: 600,
    color: "white",
    borderRadius: "5px",
    textTransform: "none",
    paddingInline: "60px",
    maxWidth: "250px",
    width: '100%',
    fontFamily: `"Lato", sans-serif`,
    letterSpacing: "3px",
    '&:hover': {
        background: "rgb(253, 97, 12) none repeat scroll 0% 0%",
    },
    '@media (max-width: 960px)': {
        maxWidth: 'unset'
    }
})

export default class UserAddress extends UserAddressController {
    isValid: any;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start

    // Customizable Area End

    // Customizable Area Start

    personalDetails2() {
        const {
            firstName,
            lastName,
            address,
            address2,
            mobileNo,
            city,
            state,
            zip,
            inputFieldError,
            successMsg1,
            errorMessages1,
        } = this.state;

        const addressData = [
            { label: "First Name", type: "text", disabled: false, value: firstName, isMandatory:"*" },
            { label: "Last Name", type: "text", disabled: false, value: lastName ,isMandatory:"*"},
            { label: "Address 1", type: "locationSelect", disabled: false, value: address, isMandatory:"*" },
            { label: "Address 2", type: "text", disabled: false, value: address2, isMandatory:"*" },
            { label: "Phone number", type: "text", disabled: false, value: mobileNo, isMandatory:"*" },
            { label: "State", type: "select", disabled: false, value: state,isMandatory:"*" },
            { label: "City", type: "text", disabled: false, value: city, isMandatory:"*" },
            { label: "Zip code", type: "text", disabled: false, value: zip, isMandatory:"*" },
        ];
        const labelCollectionSet2 = ["Address 1", "Address 2"];
        const typeArray = ["text"]
        if (!successMsg1) {
            return (
                <>
                    <Typography variant="h4" className={this.props.classes.addressHeader}>
                        {configJSON.addNewAddressText}
                    </Typography>
                    <Divider style={webStyle.horizontalBar} />
                    <CustomBox>

                        <Grid container spacing={2}>
                            {addressData.map((item) => {
                                const isLabelSet2 = labelCollectionSet2.includes(item.label);
                                const isTypeArray = typeArray.includes(item.type)
                                const isTypeSelect = item.type === 'select';
                                const isLocation = item.type === "locationSelect";
                                const errorFlag = inputFieldError[item.label].length !== 0
                                return (
                                    <Grid
                                        data-test-id="form"
                                        style={{ margin: '0 0 -4px' }}
                                        key={item.label}
                                        item
                                        md={this.setFields(isLabelSet2)}
                                        xs={12}
                                    >
                                        {(isTypeArray) && (
                                            <Box style={{ marginRight: '6px' }}>
                                                <label
                                                    id={item.label}
                                                    className={this.props.classes.labelText}
                                                    style={{
                                                        color: this.getErrColor(errorFlag), fontFamily: `"Lato", sans-serif`
                                                    }}
                                                >
                                                    {item.label} {item.isMandatory}
                                                </label>
                                                <TextField
                                                    variant="outlined"
                                                    id={item.label}
                                                    data-testid="personalDetails1"
                                                    onChange={(event) => {this.handleInputChange(event, item.label)}}
                                                    helperText={
                                                        errorFlag &&
                                                        item.label + " " + inputFieldError[item.label]
                                                    }
                                                    error={errorFlag}
                                                    className={this.props.classes.inputFiled}
                                                    placeholder={item.label}
                                                    margin="normal"
                                                    type={item.type}
                                                    value={item.value}
                                                    inputProps={{ style: { borderRadius: '5px', color: "#666666", fontSize: '14px', fontFamily: "AvenirNext-Regular, sans-serif", fontWeight: 400 } }}
                                                />
                                            </Box>
                                        )}
                                        {(isTypeSelect) && (
                                            <Box style={{ marginRight: '6px' }}>
                                                <FormControlTwo style={{ width: '100%' }}>
                                                    <label
                                                        id={item.label}
                                                        className={this.props.classes.labelText}
                                                        style={{
                                                            color: this.getErrColor(errorFlag), fontFamily: `"Lato", sans-serif`
                                                        }}
                                                    >
                                                        {item.label}{item.isMandatory}
                                                    </label>

                                                    <Select
                                                        name="requestExchange"
                                                        onChange={(event) => this.handleInputChange(event, item.label)}
                                                        value={item.value}
                                                        data-testid="state"
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        displayEmpty
                                                        disableUnderline
                                                        defaultValue={""}
                                                        onOpen={this.handleMenuOpen}
                                                        onClose={this.handleMenuClose}
                                                        open={this.state.menuOpen}
                                                        MenuProps={{
                                                          onExited: this.handleMenuClose,
                                                        }}
                                                        style={{
                                                            borderColor: this.getErrColor(errorFlag),
                                                        height: "53px",
                                                        marginTop: "9px",
                                                        border: '1px solid rgba(0, 0, 0, 0.23)',
                                                    }}
                                                        inputProps={{
                                                            'aria-label': 'Without label', style: {
                                                                borderBottom: 'none', background: 'white', 
                                                                height: "53px",
                                                                marginTop: "9px"
                                                            }
                                                        }}
                                                        error={errorFlag}
                                                    >
                                                        {!this.state.menuOpen && <MenuItemTwo value={""} disabled><span style={{ color: "#9a9a9a", fontSize: '12px' }} >{'Choose'}</span></MenuItemTwo>}
                                                        <MenuItemTwo value="Abu Dhabi">Abu Dhabi</MenuItemTwo>
                                                        <MenuItemTwo value="Dubai">Dubai</MenuItemTwo>
                                                        <MenuItemTwo value="Sharjah">Sharjah</MenuItemTwo>
                                                        <MenuItemTwo value="Ajman">Ajman</MenuItemTwo>
                                                        <MenuItemTwo value="Umm Al-Quwain">Umm Al-Quwain</MenuItemTwo>
                                                        <MenuItemTwo value="Fujairah">Fujairah</MenuItemTwo>
                                                        <MenuItemTwo value="Ras Al Khaimah">Ras Al Khaimah</MenuItemTwo>
                                                    </Select>
                                                    {this.state.inputFieldError[item.label] ? <Typography style={{color: this.getStateErr(errorFlag), fontFamily: `"Lato", sans-serif`, fontSize:'12px', margin: '3px 14px 0'}}>{item.label} {this.state.inputFieldError[item.label]}</Typography> : "" }
                                                </FormControlTwo>
                                            </Box>
                                        )}
                                        {(isLocation) && (
                                <>
                                <label
                                id={"location"}
                                className={this.props.classes.labelText}
                                style={{
                                    color: this.getErrColor(errorFlag), fontFamily: `"Lato", sans-serif`
                                }}
                            >
                                {item.label}{item.isMandatory}
                            </label>
                            <Grid item xs={12} sm={12} md={12} lg={12} style={{marginTop:'10px'}}>
                            <GooglePlaceAutoComplete
                                data-test-id="address1"
                                placeholder={"Address 1"}
                                errorFlag={errorFlag} 
                                inputFieldError={this.state.inputFieldError}
                                handlePlacesAutocompleteChange={this.handlePlacesAutoCompleteChange}
                                handlePlacesAutocompleteSelect={this.handlePlacesAutoCompleteSelect}
                                address={this.state.address}
                                detectPlace={() => { } }
                                setFieldValue={() => { } }
                                lat={0}
                                lng={0}
                                navigation={this.props.navigation}
                                id={this.props.id}                          
                            />
                              </Grid>
                              <Grid item xs={12} sm={12} md={12} lg={12} style={{width:'99%', margin:'10px 0'}}>
                                <MapComponent google={this.state.google}
                                data-test-id="mapComponent"
                                lat={0}
                                lng={0}
                                mapCenter={this.state.mapCoOrdinates}
                                handlePlacesAutocompleteChange={this.handlePlacesAutoCompleteChange}
                                handlePlacesAutocompleteSelect={this.handlePlacesAutoCompleteSelect}
                                />
                            </Grid>
                            </>
                            )}
                                    </Grid>
                                );
                            })}
                            
                            <Grid item xs={12} className={this.props.classes.checkBoxContainer}>
                            <Box data-test-id="checkbox" onClick={this.handleCheckBox} style={{cursor: 'pointer', marginRight:'16px'}}>
                                <img src={this.state.checkBox ? checkboxTicked : checkbox} alt="checkBox"/>
                                </Box>
                                <Typography className={this.props.classes.defaultText}>Make this my default address</Typography>
                            </Grid>
                        </Grid>
                        {Boolean(errorMessages1.length)  && (
                            errorMessages1.map((item, index) => (<Box sx={{ display: "flex", flexDirection: "row", flexWrap: "no-wrap", alignItems: "center", paddingTop: "10px" }}>
                                <Box sx={{ marginRight: "10px" }}>
                                    <img src={errorImage} />
                                </Box>
                                <Box>
                                    <Typography variant="body1" style={{ color: "#D74D4D" }}>
                                        {item}
                                    </Typography>
                                </Box>
                            </Box>))

                        )}

                        <Grid item xs={12} md={6} style={webStyle.saveBtnBox}>
                            <CustomButton
                                data-testid="saveButton"
                                onClick={() => this.handleAddressSave()}
                            >
                                ADD ADDRESS
                            </CustomButton>
                        </Grid>
                    </CustomBox>
                </>
            );
        }

        return <UserAddressList navigation={this.props.navigation} id={this.props.id} userId={this.state.userId} match={this.props.match} defaultAddress={() => {}}/>;

    }

    render() {
        return (
            <>
                {this.personalDetails2()}
            </>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
const styles = {
    addressHeader : {
        color: "#232529",
        fontWeight: 700,
        fontSize: "32px",
        fontFamily: "Lato, sans-serif",
        '@media (max-width: 960px)': {
            fontSize: "28px"
        },
        '@media (max-width: 600px)': {
            fontSize: "24px"
        },
        '@media (max-width: 460px)': {
            fontSize: "22px"
        }
    },
    labelText: {
        color: "#666666",
        fontFamily: "Lato, sans-serif",
        fontSize: "12px",
        fontWeight: 400
    },
    defaultAdd: {
        fontSize:'18px',
        '@media (max-width: 960px)': {
            fontSize: "16px"
        },
        '@media (max-width: 600px)': {
            fontSize: "14px"
        },
    },
    inputFiled: {
        margin: "10px 5px 10px 0",
        width: "100%",
        '.MuiInputBase-root': {
            width: "100% !important"
        }
    },
    defaultText: {
        color: '#585858',
        fontFamily: "Lato, sans-serif",
        fontSize: '18px',
        fontWeight: 400,
        '@media (max-width: 960px)': {
            fontSize: "16px"
        },
        '@media (max-width: 600px)': {
            fontSize: "14px"
        }
    },
    checkBoxContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: '25px 0px 20px'
    }
}
const styledAddress = withStyles(styles)(UserAddress)
export {styledAddress}
// Customizable Area End

const webStyle = {

    horizontalBar: {
        height: "1px",
        marginBlock: "20px"
    },
    subHeaders: {
        color: "rgb(36, 35, 35)",
        fontWeight: 600,
        fontSize: "20px",
    },
    headerTxt: {
        color: "rgb(36, 35, 35)",
        fontWeight: 600,
        fontSize: "2rem",
        fontFamily: "Lato, sans-serif"
    },
    saveBtn: {
        background: "rgb(253, 97, 12) none repeat scroll 0% 0%",
        padding: "10px 25px",
        fontWeight: 600,
        color: "white",
        borderRadius: "5px",
        textTransform: "none"
    },
    saveBtnBox: {
        margin: "25px 0",
    },


}
