import React from "react";

// Customizable Area Start
import { Box, Typography, Grid, Select, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from "@material-ui/core";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import DatePicker from "react-datepicker";
import "../../catalogue/src/calendar.css";
import { styles } from "../../catalogue/src/SellerPartnerOrderManagement.web";
import { CustomAnalyticsExpandMoreIcon, FormControlTwo, MenuItemTwo } from "../../dashboard/src/CustomizedElementStyle";
import { greyCalendar, salesIcon } from "./assets";
// Customizable Area End

// Customizable Area Start
import AnalyticsController, {
  ICompareSales,
  IReturnReport,
  ISales,
  ISalesTraffic,
  Props,
  configJSON
} from "./AnalyticsController.web";
import { CustomOrangeButton } from "../../../components/src/CommonButton";
// Customizable Area End

export default class Analytics extends AnalyticsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderSalesList =() => {
    return(
      this.state.reportType === "Sales report" && 
        <TableContainer style={webStyle.tableContainer}>
        <Table component={Paper}>
          <TableHead>
            <TableRow style={webStyle.salesReportTableRow}>
              <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                <Typography style={webStyle.salesHeaderText}>{configJSON.saleReportHeaders.dateText}</Typography>
                <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
              </TableCell>
              <TableCell align="left" style={webStyle.salesHeader}>
              <Box style={webStyle.headerBox}>
                <Typography style={webStyle.salesHeaderText}>{configJSON.saleReportHeaders.orderedProdText}</Typography>
                <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
              </Box>
              </TableCell>
              <TableCell align="left" style={webStyle.salesHeader}>
              <Box style={webStyle.headerBox}>
                <Typography style={webStyle.salesHeaderText}>{configJSON.saleReportHeaders.unitsOrdered}</Typography>
                <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
              </Box>
              </TableCell>
              <TableCell align="left" style={webStyle.salesHeader}>
              <Box style={webStyle.headerBox}>
                <Typography style={webStyle.salesHeaderText}>{configJSON.saleReportHeaders.totalOrderItem}</Typography>
                <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
              </Box>
              </TableCell>
              <TableCell align="left" style={{...webStyle.salesHeader}}>
              <Box style={webStyle.headerBox}>
                <Typography style={webStyle.salesHeaderText}>{configJSON.saleReportHeaders.averageSalesPerOrderItem}</Typography>
                <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
              </Box>
              </TableCell>
              <TableCell align="left" style={{...webStyle.salesHeader}}>
              <Box style={webStyle.headerBox}>
                <Typography style={webStyle.salesHeaderText}>{configJSON.saleReportHeaders.averageUnitsPerOrderItem}</Typography>
                <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
              </Box>
              </TableCell>
              <TableCell align="left" style={webStyle.salesHeader}>
              <Box style={webStyle.headerBox}>
                <Typography style={webStyle.salesHeaderText}>{configJSON.saleReportHeaders.averageSellingPrice}</Typography>
                <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
              </Box>
              </TableCell>
              <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                <Typography style={webStyle.salesHeaderText}>{configJSON.saleReportHeaders.sessionsTotal}</Typography>
                <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
              </TableCell>
              <TableCell align="left" style={webStyle.salesHeader}>
              <Box style={webStyle.headerBox}>
                <Typography style={{...webStyle.salesHeaderText, ...webStyle.orderItemSessionPer}}>{configJSON.saleReportHeaders.orderItemSessionPer}</Typography>
                <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
              </Box>
              </TableCell>
              <TableCell align="left" style={webStyle.salesHeader}>
              <Box style={webStyle.headerBox}>
                <Typography style={webStyle.salesHeaderText}>{configJSON.saleReportHeaders.averageOfferCount}</Typography>
                <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
              </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={webStyle.bottomMargin}>
              {this.state.salesList.length > 0 &&
                this.state.salesList.map((item:ISales, index: number) => {
                  return (
                    <>
                    <TableRow data-test-id="saleslist" key={index} style={{...webStyle.salesReportTableRow,backgroundColor: '#fff',padding:'10px 0', borderBottom: '1px solid rgb(224, 224, 224)'}}>
                      <TableCell align="left" style={{...webStyle.salesTableCell, paddingLeft:'16px'}}>{item.date}</TableCell>
                      <TableCell align="left" style={{...webStyle.salesTableCell, ...webStyle.prodSales}}>AED {item.ordered_product_sales}</TableCell>
                      <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.units_ordered}</TableCell>
                      <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.total_order_item}</TableCell>
                      <TableCell align="left" style={{...webStyle.salesTableCell}}>AED {item.avg_sales_per_order_item}</TableCell>
                      <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.avg_units_per_order_item}</TableCell>
                      <TableCell align="left" style={{...webStyle.salesTableCell}}>AED {item.avg_selling_price}</TableCell>
                      <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.sessions_total}</TableCell>
                      <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.order_item_session_per}</TableCell>
                      <TableCell align="left" style={webStyle.salesTableCell}>{item.avg_offer_count}</TableCell>
                    </TableRow>
                    </>
                  );
                })
              }
            </TableBody>
          </Table>
          </TableContainer>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
        <Box style={styles.contentWrapper}>
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Typography style={webStyle.tabName}>
              {configJSON.analyticsReportsTabText}
            </Typography>
          </Grid>
          <Grid container style={webStyle.reportContainer}>
            <Grid item xs={12} sm={12} md={12} lg={12} style={styles.orderTextWrapper}>
              <Box style={webStyle.reportsHeading}>
                {configJSON.reportsText}
              </Box>
            </Grid>
            <Grid container style={webStyle.fieldContainer} spacing={2}>
              <Grid item xs={12} sm={12} md={2} lg={2} style={{marginTop:'-2px'}}>
              <FormControlTwo fullWidth={true}>
                  <Select
                    data-test-id="typeOfReport"
                    name="selectedReport"
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    style={{padding:'6px 0 6px 0', border: '1px solid #9a9a9a', ...webStyle.textOutline}}
                    inputProps={{
                      'aria-label': 'Without label',
                      classes: {
                        select: webStyle.select 
                      }
                    }}
                    value={this.state.reportType}
                    onChange={this.handleChangeReportType}
                    IconComponent={CustomAnalyticsExpandMoreIcon}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                      getContentAnchorEl: null,
                      PaperProps: {
                        style: webStyle.selectField,
                      },
                    }}
                    >
                    <MenuItemTwo value={"Sales report"} style={webStyle.menuStyle}>{"Sales report"}</MenuItemTwo>
                    <MenuItemTwo value={"Sales & traffic report"} style={webStyle.menuStyle}>{"Sales & traffic report"}</MenuItemTwo>
                    <MenuItemTwo value={"Return report"} style={webStyle.menuStyle}>{"Return report"}</MenuItemTwo>
                    <MenuItemTwo value={"Compare Sales"} style={webStyle.menuStyle}>{"Compare Sales"}</MenuItemTwo>
                  </Select>
                </FormControlTwo>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} style={{marginTop:'-8px'}}>
              <div className="DateContainers" style={{borderRadius:'8px'}}>
                <div className="dateInput" style={{padding:'0px'}}>
                  {" "}
                  <DatePicker
                    data-test-id="fromDate"
                    id="endDate"
                    placeholderText={"From date"}
                    toggleCalendarOnIconClick={true}
                    popperPlacement="auto"
                    popperModifiers={[
                      {
                        name: "offset",
                        options: {
                          offset: [0, 10],
                        },
                      },
                      {
                        name: "flip",
                        enabled: false,
                      },
                      {
                        name: "preventOverflow",
                        options: {
                          padding: 16,
                        },
                      },
                    ]}
                    showIcon
                    onChange={this.fromDate}
                    popperClassName="example-datepicker-class"
                    selected={this.state.fromDate}
                    icon={
                      <span className="icon" style={{right:'15px', padding:'0'}}>
                      <img src={greyCalendar} alt={"calendar"} />
                    </span>
                    }
                  />
                </div>
                
              </div>
              </Grid>
              <Grid item xs={12} sm={12} md={2} lg={2} style={{marginTop:'-8px'}}>
              <div className="DateContainers">
                  <div className="dateInput" style={{padding:'0px'}}>
                    {" "}
                    <DatePicker
                    data-test-id="toDate"
                      id="dateStart"
                      placeholderText={"To date"}
                      showIcon
                      toggleCalendarOnIconClick={true}
                      popperPlacement="auto"
                      popperModifiers={[
                        {
                          name: "offset",
                          options: {
                            offset: [0, 10],
                          },
                        },
                        {
                          name: "flip",
                          enabled: false,
                        },
                        {
                          name: "preventOverflow",
                          options: {
                            padding: 16,
                          },
                        },
                      ]}
                      onChange={this.toDate}
                      popperClassName="example-datepicker-class"
                      selected={this.state.toDate}
                      icon={
                        <span className="icon" style={{right:'15px', padding:'0'}}>
                        <img src={greyCalendar} alt={"calendar"} />
                      </span>
                      }
                    />
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} style={{display:'flex', alignItems:'center', justifyContent:'flex-end'}}>
                <CustomOrangeButton style={{marginTop:'-2px'}}>
                  Export (.csv)
                </CustomOrangeButton>
              </Grid>
            </Grid>
          </Grid>
          {this.state.reportType === "Compare Sales" &&
          <TableContainer style={{overflowX: 'auto'}}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow style={{backgroundColor:'#ececec', display:'flex', alignItems:'center'}}>
                  <TableCell align="center" colSpan={1} style={webStyle.headingOne}>{""}</TableCell>
                  <TableCell align="left" style={webStyle.headingTwo}>
                    <Typography style={webStyle.salesHeaderText}>{"TOTAL"}</Typography>
                    <Typography style={webStyle.salesHeaderText}>{"ORDER COUNT"}</Typography>
                  </TableCell>
                  <TableCell style={{...webStyle.heading, width:'140px'}}>
                    <Typography style={webStyle.salesHeaderText}>{"UNITS"}</Typography>
                    <Typography style={webStyle.salesHeaderText}>{" ORDERED"}</Typography>
                  </TableCell>
                  <TableCell style={{...webStyle.headingTwo, width:'140px'}}>
                    <Typography style={webStyle.salesHeaderText}>{"ORDERED"}</Typography>
                    <Typography style={webStyle.salesHeaderText}>{" PRODUCT SALES"}</Typography>
                    </TableCell>
                  <TableCell style={{...webStyle.heading,width:'160px'}}>
                    <Typography style={webStyle.salesHeaderText}>{"AVERAGE"}</Typography>
                    <Typography style={webStyle.salesHeaderText}>{" UNITS/ORDER"}</Typography>
                    <Typography style={webStyle.salesHeaderText}>{" ITEM"}</Typography>
                  </TableCell>
                  <TableCell style={webStyle.heading}>
                    <Typography style={webStyle.salesHeaderText}>{"AVERAGE"}</Typography>
                    <Typography style={webStyle.salesHeaderText}>{" SALES/ORDER"}</Typography>
                    <Typography style={webStyle.salesHeaderText}>{" ITEM"}</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{marginBottom:'20px'}}>
                {this.state.compareSalesList.length > 0 &&
                  this.state.compareSalesList.map((item:ICompareSales, index: number) => {
                    return (
                      <TableRow data-test-id="comparelist" key={index} style={{backgroundColor: '#fff',display:'flex'}}>
                        <TableCell style={webStyle.rowHeader}>
                          {item.heading}
                        </TableCell>
                        <TableCell align="left" style={webStyle.columnOne}>{item.total_order_item}</TableCell>
                        <TableCell align="left" style={webStyle.columnOne}>{item.units_ordered}</TableCell>
                        <TableCell align="left" style={webStyle.columnOne}>AED {item.order_product_sales}</TableCell>
                        <TableCell align="left" style={webStyle.columnThree}>{item.avg_units}</TableCell>
                        <TableCell align="left" style={webStyle.columnOne}>AED {item.avg_sales}</TableCell>
                      </TableRow>
                    )})
                }</TableBody></Table>
          </TableContainer>
          }
          {this.renderSalesList()}
          {this.state.reportType === "Return report" && 
          <TableContainer style={webStyle.tableContainer}>
          <Table component={Paper}>
            <TableHead>
              <TableRow style={webStyle.salesReportTableRow}>
                <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.dateText}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.orderedProdText}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.unitsOrdered}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.totalOrderItem}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.unitsRefunded}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.refundRate}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.feedbackReceived}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.negativeFeedbackReceived}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={{...webStyle.salesHeaderText, ...webStyle.orderItemSessionPer}}>{configJSON.returnReportsHeaders.receivedNegativeFeedbackRate}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.azClaimsGranted}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.returnReportsHeaders.claimsAmount}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={webStyle.bottomMargin}>
                {this.state.returnsList.length > 0 &&
                  this.state.returnsList.map((item:IReturnReport, index: number) => {
                    return (
                      <TableRow data-test-id="returnlist" key={index} style={webStyle.returnReportTableBodyRow}>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.date}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>AED {item.ordered_product_sales}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.units_ordered}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.total_order_item}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.units_refunded}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.refund_rate}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.feedback_received}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.negative_feedback_received}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.received_negative_feedback_rate}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.az_claims_granted}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>AED {item.claims_amount}</TableCell>
                      </TableRow>)})
                  }
              </TableBody>
            </Table>
            </TableContainer>
          }
          {this.state.reportType === "Sales & traffic report" && 
          <TableContainer style={webStyle.tableContainer}>
          <Table component={Paper}>
            <TableHead>
              <TableRow style={webStyle.salesReportTableRow}>
                <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.parentBesku}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.childBesku}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.title}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.skuText}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.sessionsTotal}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.sessionsPer}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.pageViewsTotal}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.pageViewsPerTotal}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={{...webStyle.salesHeaderText, ...webStyle.orderItemSessionPer}}>{configJSON.saleTrafficHeaders.featuredOffer}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.unitsOrdered}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.unitSessionPer}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={{...webStyle.salesHeader}}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.orderedProductSales}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
                <TableCell align="left" style={webStyle.salesHeader}>
                <Box style={webStyle.headerBox}>
                  <Typography style={webStyle.salesHeaderText}>{configJSON.saleTrafficHeaders.totalOrderItems}</Typography>
                  <img style={webStyle.salesIcon} src={salesIcon} alt="salesIcon" />
                </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={webStyle.bottomMargin}>
                {this.state.salesTrafficList.length > 0 &&
                  this.state.salesTrafficList.map((item:ISalesTraffic, index: number) => {
                    return (
                      <>
                      <TableRow data-test-id="salesTrafficlist" key={index} style={{backgroundColor: '#fff', borderBottom: '1px solid rgba(224, 224, 224, 1)', padding:'10px 0'}}>
                        <TableCell align="left" style={{...webStyle.salesTableCell, paddingLeft:'16px'}}>{item.parent_besku}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.child_besku}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.title}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.sku}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.sessions_total}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.session_per}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.page_views_total}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.page_views_percentage_total}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.featured_offer}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.units_ordered}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.unit_session_per}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>AED {item.ordered_product_sales}</TableCell>
                        <TableCell align="left" style={{...webStyle.salesTableCell}}>{item.total_order_items}</TableCell>
                      </TableRow>
                      </>
                    );
                  })
                }
              </TableBody>
            </Table>
            </TableContainer>
          }
        </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  tabName: {
    color: '#000',
    fontFamily: 'Lato, sans-serif',
    fontSize: '18px',
    fontWeight: 400,
    padding: '20px 0 15px'
  },
  reportsHeading: {
    color: '#000',
    fontFamily: "Lato, sans-serif",
    fontSize: '27px',
    fontWeight: 600
  },
  reportContainer: {
    display: "flex",
    flexDirection: "column" as const,
    padding: "30px",
    position: "relative" as const,
    background: "white"
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff"
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)"
  },
  selectField: {
    background: "white",
    border: "1px solid #b1b3b5",
    boxShadow: "none",
    borderRadius: 5,
    marginTop: "5px",
    padding: 0,
    maxHeight: "400px",
    overflowY: "auto" as const,
  },
  fieldContainer: {
    marginTop:'20px'
  },
  headingOne: {
    width:'300px',
    backgroundColor: '#ececec',
    fontSize:'16px',
    fontWeight: 400,
    fontFamily: 'Lato, sans-serif',
    textAlign:'left' as const,
    borderBottom:'none'
  },
  heading: {
    backgroundColor: '#ececec',
    fontSize:'14px',
    fontWeight: 400,
    fontFamily: 'Lato, sans-serif',
    textAlign:'left' as const,
    borderBottom:'none'
  },
  headingTwo: {
    display:'flex',
    flexDirection:'column' as const,
    width:'140px',
    backgroundColor: '#ececec',
    borderBottom:'none'
  },
  columnOne: {
    fontSize:'12px',
    fontWeight: 500,
    fontFamily: 'Avenir, sans-serif',
    width:'140px',
    color: '#000'
  },
  columnThree: {
    width:'160px',
    fontSize:'12px',
    fontWeight: 500,
    fontFamily: 'Avenir, sans-serif',
    color: '#000'
  },
  rowHeader: {
    width:'300px',
    backgroundColor: '#fff',
    fontSize:'14px',
    fontWeight: 500,
    fontFamily: 'Avenir, sans-serif',
    color: '#000'
  },
  salesHeader : {
    backgroundColor: '#ececec',
    borderBottom: 'none'
  },
  salesHeaderText : {
    backgroundColor: '#ececec',
    fontWeight: 500,
    fontSize: '14px',
    fontFamily: 'Avenir, sans-serif',
    color: 'rgb(102,102,102)',
    borderBottom: 'none'
  },
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    color: "#B1B2B4",
    fontFamily: `"Lato", sans-serif`,
    fontSize: "14px"
  },
  footerItem: {
    padding: "30px 0px 20px 20px",
    fontWeight: 600
  },
  salesIcon: {
    width:'16px',
    height:'16px',
    marginLeft:'6px'
  },
  orderItemSessionPer : {
    width:'88px'
  },
  salesTableCell: {
    fontWeight: 500,
    fontSize: '12px',
    fontFamily: 'Avenir, sans-serif',
    color: '#000',
    borderBottom:'none'
  },
  tableContainer: {
    overflowX: 'auto' as const, 
    maxWidth:'100%'
  },
  salesReportTableRow: {
    backgroundColor:'#ececec', 
    textAlign:'center' as const
  },
  bottomMargin: {
    marginBottom:'20px'
  },
  returnReportTableBodyRow: {
    backgroundColor: '#fff',
    borderBottom: '1px solid rgba(224, 224, 224, 1)', 
    padding:'10px 0'
  },
  headerBox: {
    display:'flex',
    alignItems:'center'
  },
  textOutline: {
    borderRadius: 8,
    '&.MuiFormControl-root': {
      borderRadius: 8
    }
  },
  select: {
    fontFamily: `"Lato", sans-serif !important` as const,
    color: '#333333 !important',
    fontSize:'14px !important',
    opacity: 'initial',
    borderRadius: 8,
    '&.MuiFormControl-root': {
      borderRadius: 8
    }
  },
  menuStyle: {
    fontFamily: `"Lato", sans-serif !important` as const,
    color: '#33333a !important',
    fontSize:'14px !important',
  },
  dateContainer: {
    marginTop: '6px',
    position: 'relative' as const
  },
  date: {
    display:'flex',
    alignItems:'center',
    justifyContent: 'center',
    input: {borderRadius:'8px'}
  },
  prodSales: {
    marginLeft:'-30px'
  }
};
// Customizable Area End
