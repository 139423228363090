import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import MessageEnum, { getName } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData } from "../../../../framework/src/Utilities";

// Customizable Area Start
export const configJSON = require("../../src/config.js");

export interface ICategory {
    id: string,
    type: string,
    attributes: {
      id: number,
      name: string,
      parent_id: number,
      relation: string,
      mini_categories: 
        {
          id: number,
          name: string,
          micro_categories: {
              id: number,
              name: string
            }[]
        }[]
      
    }
}

export interface IBrandList {
  id: string,
  type: string,
  attributes: {
    id: number,
    brand_name: string,
    brand_image: string
  }
}
interface DocumentsData {
  id: string;
  type: string;
  attributes: {
    document_type: string;
    document_name: string;
    vat_reason: string;
    account_no: string;
    iban: string;
    bank_address: string;
    name: string
    bank_name: string;
    swift_code: string;
    approved: boolean | null;
    rejected: boolean | null;
    reason_for_rejection: string;
    account_id: number;
    document_files: string[];
  };
}
export interface SuccessResponse {
  data: DocumentsData[];
  meta: {
    message: string;
  };
  min_range:string;
  max_range:string;
  message: string;
}
export interface ProductListArgs {
  category_id?: string | number,
  subCatagoryIds?: string[];
  brandIds?: string[];
  miniCategoryIds?: string[] | number[];
  microCategoryIds?: string[] | number[];
  per_page?: number | string;
  min_price?: number | null;
  max_price?: number | null;
  customFieldValues?: string[];
  filter_by_rating?: number[];
  color_filter?: string[];
}

interface ProductAttribute {
  product_title: string;
  product_image: string;
  product_content: {
      product_attributes: {
          product_title: string;
      }
  }
}

interface TrendingProduct {
  data: {
      id: string;
      type: string;
      attributes: ProductAttribute;
  };
}


interface IProductData {
  id:string,
  attributes: {
    sale_ad_image: string,
    catalogues: TrendingProduct[]
  }
}

interface ProductRes {
  data: IProductData[]
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  match: any;
  classes: {
    trandingTemlateImg: string;
    trendingTemplate: string;
    trendingBack2: string;
    dualSliderTxtBack: string;
    itemSliderWrraper: string;
    root: string,
    mainWrraper: string,
    brandContainer: string
    brandBtn: string
    drawer: string,
    drawerPaper: string,
    content: string,
    contentWrapper: string
    headerContainer: string,
    headerText: string,
    container: string,
    orderTextWrapper: string,
    ordersText: string,
    searchWrapper: string,
    searchOrderText: string,
    searchBoxWrapper: string,
    lAndIContainer: string,
    options: string,
    label: string,
    select: string,
    brandImage: string,
    category: string,
    shopByText: string,
    brandName: string,
    categoryOne: string,
    flex: string,
    catalogContainer: string,
    selectDisplay: string,
    displayFilter: string,
    categoryFilterContainer?: string;
    shopByBrandContainer?: string;
    productWrapper: string;
    noProductsBox: string;
    noProductsText: string;
  }
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  out_of_stocks: boolean;
  authToken: string;
  searchValue: string;
  productList: any
  category_id: any
  subCategoryList: ICategory[],
  brandList: IBrandList[],
  itemPerPage: any
  subCatagoryItemsIds: string[];
  brandItemsIds: string[];
  min_price: number | null;
  max_price: number | null;
  customFieldsData: {
    [key: string]: string[]; 
  }
  customFieldValues: string[],
  miniCategoryIds: string[],
  microCategoryIds: string[],
  sortByProduct: string;
  rating: number[],
  color_filter: string[],
  productListOne: TrendingProduct[];
  productListTwo: TrendingProduct[];
  updatedRating: boolean[]
  // Customizable Area End
}
interface SS {
  id: any;
}

export default class ProductCatalogueController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getApiProductListCallId: string = "";
  getApiSubCategoryCallId: string = "";
  getApiBrandCallId: string = "";
  getSocialMediaInfoApiCallId: string = "";
  getCustomFieldsApiCallId: string = "";
  getSearchKeywordApiCallId: string = "";
  getProductOneApiCallId: string = "";
  getProductTwoApiCallId: string = "";
  // Customizable Area End

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.NavigationPayLoadMessage), getName(MessageEnum.RestAPIResponceMessage)];
    this.state = {
      authToken: "",
      searchValue: '',
      category_id: '',
      subCatagoryItemsIds: [],
      brandItemsIds: [],
      subCategoryList: [],
      brandList: [],
      itemPerPage: 50,
      min_price: null,
      max_price: null,
      productList: {},
      customFieldsData: {},
      customFieldValues: [],
      miniCategoryIds: [],
      microCategoryIds: [],
      sortByProduct: "recommended",
      rating: [],
      color_filter: [],
      out_of_stocks: false,
      productListOne: [],
      productListTwo: [],
      updatedRating: []
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

      this.handleSuccessResponse(responseJson, apiRequestCallId);

    }
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidUpdate(prevProps: Props, prevState: S) {
    // Check if prop or state has changed
    window.addEventListener('popstate', this.handlePopState);
    if (prevProps.match.params?.id !== this.props.match.params?.id || prevProps.match.params?.type !== this.props.match.params?.type) {
      // Call your function here
      if(this.state.customFieldsData !== prevState.customFieldsData){
        this.setState({customFieldValues: []})
      }
      if(this.state.category_id !== prevState.category_id){
        this.setState({subCatagoryItemsIds: [], brandItemsIds:[]})
      }
      
      this.setState({min_price: null, max_price:null, customFieldValues: [], customFieldsData: {}, brandItemsIds: [], subCatagoryItemsIds: [], rating: [], color_filter: [], out_of_stocks: false, sortByProduct: "recommended", itemPerPage: "50" }, () => this.onMountApicall())
      
    }
    if(this.state.subCatagoryItemsIds !== prevState.subCatagoryItemsIds){
      this.setState({customFieldValues: []})
    }
    else return
  }

  async componentDidMount() {
    const authToken = await getStorageData("authToken");
    const sortByProduct = await getStorageData("sortFilter");
    const itemPerPage = await getStorageData("itemPerPage") 
    const colorFilter = await getStorageData("colorFilter") 
    if(itemPerPage) {
      this.setState({itemPerPage})
    }
    if(sortByProduct) {
      this.setState({sortByProduct})
    }
    this.setState({ authToken, out_of_stocks: false, color_filter: colorFilter })
    this.getTrendingProducts(1)
    this.getTrendingProducts(2)
    this.onMountApicall();
    this.handlePopState()
  }

  handlePopState = () => {
    const query = new URLSearchParams(window.location.search)
    const sortValue = query.get('sort') || 'recommended'
    const outOfStock = query.get('outOfStock')
    const perPageValue = query.get('itemPerPage') || '50'
    const subCategoryIds = query.get('subCatagoryIds')
    const brandIds = query.get('brandIds')
    const colors = query.get('color')
    const rating:any = query.get('rating')

    const decodedQuery = decodeURIComponent(rating);
    const values = decodedQuery.split(',');
    const result = new Array(5).fill(false);
    values.forEach(value => {
      const index = parseInt(value, 10) - 1; 
      if (index >= 0 && index < result.length) {
        result[index] = true;
      }
    });

    const subCategoryIdsArray = subCategoryIds ? subCategoryIds.split(',') : [];
    const brandIdsArray = brandIds ? brandIds.split(',') : [];
    const colorsArray = colors ? colors.split(',') : [];
    const filteredColorResults = colorsArray.map(item => item.replace(/_/g, ' '))
    const reversedResult = result.reverse();
    const filteredRatingRes = reversedResult;
    const ratingResults = JSON.stringify(filteredRatingRes)
    setStorageData("subCatagoryIds", JSON.stringify(subCategoryIdsArray))
    setStorageData("brandIds", JSON.stringify(brandIdsArray))
    setStorageData("colorFilter", JSON.stringify(filteredColorResults))
    setStorageData("outOfStock",outOfStock )
    setStorageData("rating", ratingResults)
    this.setState({ rating: !rating ? [] : values as any, updatedRating: result, out_of_stocks : Boolean(outOfStock) , color_filter: filteredColorResults ,brandItemsIds: brandIdsArray, subCatagoryItemsIds: subCategoryIdsArray,sortByProduct : sortValue, itemPerPage: perPageValue},() => this.handleProductlistContent({filter_by_rating: values as any }))
  }

  async componentWillUnmount() {
   await removeStorageData('sortFilter');
   await removeStorageData('itemPerPage');
   await removeStorageData('brandIds');
   await removeStorageData('subCatagoryIds');
   await removeStorageData('rating');
  }

  onMountApicall() {
    const { match } = this.props;
    const category_id = match.params?.id;

    const decodedUrl = decodeURIComponent(window.location.href);

    const subCatagoryIdsMatch = decodedUrl.match(/subCatagoryIds=([\d,]+)/);
    
    const subCatagoryIds = subCatagoryIdsMatch ? subCatagoryIdsMatch[1].split(',') : [];

    this.setState({ category_id, subCatagoryItemsIds: subCatagoryIds })

    this.handleProductlistContent({ category_id });
    this.subCategoryApiCall(category_id);
    this.brandApiCall(category_id);
    this.getCustomFields(category_id)
  }

  getCustomFields = (category_id:string) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.authToken,
    };
    const message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCustomFieldsApiCallId = message.messageId;
    message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/fetch_custom_field_filters?category_ids[]=${category_id}&sub_category_ids[]=${this.state.subCatagoryItemsIds}&mini_category_ids[]=${this.state.miniCategoryIds}&micro_category_ids[]=${this.state.microCategoryIds}`
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(message.id, message);
  }

  handleSuccessResponse = (responseJson: any, apiRequestCallId: any) => {

    switch (apiRequestCallId) {
      case this.getApiSubCategoryCallId:
        this.handleSubCategoryApiCallResponse(responseJson);
        break;

      case this.getApiBrandCallId:
        this.handleBrandApiCallResponse(responseJson);
        break;

      case this.getApiProductListCallId:
        this.handleProductlistContentResponse(responseJson);
        break;

      case this.getCustomFieldsApiCallId: 
        this.handleCustomFieldsResponse(responseJson)  
        break;

      case this.getProductOneApiCallId:
        const productRes = responseJson as ProductRes
        this.setState({ 
          productListOne: productRes?.data[0].attributes.catalogues, 
        })
        break;

      case this.getProductTwoApiCallId:
        const productTwoRes = responseJson as ProductRes
        this.setState({ 
          productListTwo: productTwoRes.data[0].attributes.catalogues, 
        })
        break; 

      default: break;
    }
  }

  handleCustomFieldsResponse = (responseJson: any) => {
    this.setState({customFieldsData: responseJson.custom_field_values })
  }

  handleProductlistContentResponse = (resposeJson: unknown) => {
    const successResponse = resposeJson as SuccessResponse;
    if ("data" in successResponse) {
      this.setState({ productList: successResponse })
      this.getCustomFields(this.state.category_id)
    }
  };

  handleSubCategoryApiCallResponse = (resposeJson: any) => {
    const successResponse = resposeJson.data as ICategory[];
    this.setState({ subCategoryList: successResponse })
  };

  handleBrandApiCallResponse = (resposeJson: any) => {
    const successResponse = resposeJson ;
    this.setState({ brandList: successResponse.data || [] as IBrandList[]})
  };

  handleSetColorFilter = (color: string) => {
    this.setState((prevState) => {
      const color_filter = prevState.color_filter.includes(color)
        ? prevState.color_filter.filter((clr) => clr !== color)
        : [...prevState.color_filter, color];
  
      return { color_filter };
    }, () => {
      this.handleUpdateUrl("color", this.state.color_filter)
      this.handleProductlistContent({})
  });
  }

  handleOutOfStocks = (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState({
        out_of_stocks: event.target?.checked || false
      }, () => {
        this.handleUpdateUrl('outOfStock',this.state.out_of_stocks ? "true" : '')
        this.handleProductlistContent({})
      })
  }

  handleProductlistContent = async ({ category_id = this.state.category_id, subCatagoryIds = this.state.subCatagoryItemsIds, brandIds = this.state.brandItemsIds, miniCategoryIds = this.state.miniCategoryIds, microCategoryIds = this.state.microCategoryIds, per_page = this.state.itemPerPage, min_price , max_price, customFieldValues, filter_by_rating = this.state.rating }: ProductListArgs) => {
    const localsubCatagoryIds = await getStorageData("subCatagoryIds", true);
    if(localsubCatagoryIds && localsubCatagoryIds.length > 0) {
      subCatagoryIds = localsubCatagoryIds
      this.setState({subCatagoryItemsIds: localsubCatagoryIds}, () => console.log("List", subCatagoryIds))
    }
    const localbrandIds = await getStorageData("brandIds", true);
    if(localbrandIds && localbrandIds.length > 0) {
      brandIds = localbrandIds
      this.setState({ brandItemsIds : localbrandIds})
    }
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.authToken
    };
    const isSearch = window.location.pathname.includes('search');
    let body = {};
    if(isSearch) {
      body = {
        search_keyword: await getStorageData("searchQuery"),
        per_page,
        category_id,
        brand_ids: brandIds,
        min_price: this.state.min_price ,
        max_price: this.state.max_price,
        page_no: 1,
        sort_by: this.state.sortByProduct,
        color_filter: this.state.color_filter.length > 0 ?  this.state.color_filter : null,
        filter_by_rating,
        out_of_stocks: this.state.out_of_stocks
      }
    }else{
      body =
      {
        category_id, //category id mandatory
        sub_category_ids: subCatagoryIds,     // we can use this to filter if needed
        brand_ids: brandIds, // pass only if you are filtering by brand , also if multiple brand you can pass in array
        mini_category_ids: miniCategoryIds,  // and also if we want pass multiple ids pass like sub_category_ids: [1,2]
        micro_category_ids: microCategoryIds,
        min_price,
        max_price,
        per_page,
        page_no: 1,
        customFieldValues: customFieldValues,
        sort_by: this.state.sortByProduct,
        filter_by_rating,
        color_filter: this.state.color_filter.length > 0 ?  this.state.color_filter : null,
        out_of_stocks: this.state.out_of_stocks,
      };
    }
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getApiProductListCallId = requestMessage.messageId;
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),isSearch ? configJSON.getSearchProductAPIEndPoint : configJSON.postProductListApiEndPoint);
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypePost);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  subCategoryApiCall = async (categoryid: any) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.authToken
    };
    const isSearch = window.location.pathname.includes('search');
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getApiSubCategoryCallId = requestMessage.messageId;
    const searchQuery = await getStorageData('searchQuery');
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),isSearch ? `${configJSON.getCategoryListSeachKeywordAPIEndPoint}?search_keyword=${searchQuery || this.props.match.params.type}` : configJSON.getSubCategoryEndPoint(categoryid));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  brandApiCall = async (catagoryId: any) => {
    const headers = {
      "Content-Type": configJSON.productApiContentType,
      token: this.state.authToken
    };
    const isSearch = window.location.pathname.includes('search');
    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getApiBrandCallId = requestMessage.messageId;
    const searchQuery = await getStorageData('searchQuery');
    requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),isSearch ? `${configJSON.getBrandListSearchKeywordEndPoint}?search_keyword=${searchQuery || this.props.match.params.type}` : configJSON.getBrandListEndPoint(catagoryId));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleItemPerPage: any = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    this.setState({
      itemPerPage: value,
    },() => {
      setStorageData("itemPerPage", value)
      this.handleUpdateUrl("itemPerPage", JSON.stringify(value))
      this.handleProductlistContent({ per_page: value ,min_price :this.state.min_price, max_price :this.state.max_price, miniCategoryIds: this.state.miniCategoryIds, microCategoryIds: this.state.microCategoryIds})
    });
  };

  handleShopByBrandFilter = (brandId: string) => {
    setStorageData("brandIds", JSON.stringify([brandId]))
    this.handleUpdateUrl("brandIds", [brandId])
    this.handleProductlistContent({})
    this.setFilterValues({ brandIds: [brandId] })
  }

  setFilterValues = async({ subCatagoryIds = this.state.subCatagoryItemsIds, brandIds = this.state.brandItemsIds,min_price = this.state.min_price, max_price = this.state.max_price,  customFieldValues= this.state.customFieldValues }) => {
    const result: any = {};
    subCatagoryIds.forEach((subCategoryId:any) => {
    const subCategory = this.state.subCategoryList.find((item:any) => item.attributes.id === subCategoryId);

    if (subCategory) {
      result[subCategoryId] = {
        miniCategoriesIds: subCategory.attributes.mini_categories.map((miniCat: any) => miniCat.id),
        microCategoriesIds: subCategory.attributes.mini_categories.reduce((acc:any, miniCat:any) => {
          return acc.concat(miniCat.micro_categories.map((microCat:any) => microCat.id));
        }, []),
      }
    } else {
      result[subCategoryId] = {
        miniCategoriesIds: [],
        microCategoriesIds: [],
      };
    }
  });
  const miniCategoryIds: string[] = (subCatagoryIds as string[])
  .map((id: string): string[] => result[id]?.miniCategoriesIds || [])
  .reduce((acc: string[], ids: string[]) => [...acc, ...ids], []);

const microCategoryIds: string[] = (subCatagoryIds as string[])
  .map((id: string): string[] => result[id]?.microCategoriesIds || [])
  .reduce((acc: string[], ids: string[]) => [...acc, ...ids], []);
    this.setState({
      subCatagoryItemsIds: subCatagoryIds,
      brandItemsIds: brandIds,
      min_price,
      max_price,
      customFieldValues:[...new Set(Object.values(this.state.customFieldValues).flat())],
      microCategoryIds: microCategoryIds,
      miniCategoryIds: miniCategoryIds
    });
    this.handleProductlistContent({ subCatagoryIds, brandIds ,min_price, max_price, customFieldValues, miniCategoryIds, microCategoryIds})
  }

  handleUpdateUrl = async(type: string | any, value: string | string[] | number[] | number | any) => {
    const searchQuery = await getStorageData("searchQuery")
    const { origin, pathname } = window.location;
    const { id } = this.props.match.params;
    const pathParts = pathname.split('/');
    const baseUrl = `${origin}/buyer/categories/${id}/${pathParts[4]}`;

    const currentParams = new URLSearchParams(window.location.search);
    if(value && value.length > 0) {
      if (currentParams.has(type)) {
        currentParams.set(type, Array.isArray(value) ? value.join(',') : value);
        } else {
            currentParams.append(type, Array.isArray(value) ? value.join(',') : value);
        }
    }
    else {
      currentParams.delete(type)
    }
    

      let newUrl;
      if (searchQuery) {
          newUrl = `${origin}/search/${id}/${pathParts[3]}?${currentParams.toString()}`.replace(/\+/g, '_');
      } else {
          newUrl = `${baseUrl}?${currentParams.toString()}`.replace(/\+/g, '_');
      }

      window.history.pushState(null, '', newUrl);
};
  
  handleHome = () => {
    this.props.navigation.navigate('Home')
  }

  handleSortByProductOption = (event: React.ChangeEvent<{ name?: string; value: unknown }>): void => {
    const value = event.target.value as string;
    this.setState({sortByProduct: value},() => {
      setStorageData("sortFilter", value);
      this.handleUpdateUrl("sort", value)
      this.handleProductlistContent({ per_page: this.state.itemPerPage ,min_price :this.state.min_price, max_price :this.state.max_price, miniCategoryIds: this.state.miniCategoryIds, microCategoryIds: this.state.microCategoryIds})
    })
  }

  handleRatingVlaue = async(value : any) => {
    const baseValue = 5;
    const rating: number[] = value.reduce((acc: number[], item: boolean, index: number) => {
      if (item) {
        acc.push(baseValue - index);
      }
      return acc;
    }, []);
    this.setState({ rating: rating }, () => this.handleUpdateUrl('rating', rating)); 
    
    this.handleProductlistContent({ filter_by_rating: rating });
}

  noProductsFound = () => {
    return this.state.productList.data && this.state.productList.data.length === 0
  }

  renderTrendingProducts = () => {
    const paramsValue = this.props.navigation.getParam("type")
    return (
      paramsValue === "noResult" && 
      this.state.productList.data && this.state.productList.data.length === 0

    )
  }

  handleTrendingProducts = (catalogId: string) => {
    this.props.navigation.navigate("ProductQuickview", { id: catalogId });
  } 

  getTrendingProducts = (sliderValue: number) => {
    const headers = {
      "Content-Type": configJSON.contentType,
      token: this.state.authToken
    };
    const singleMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    if (sliderValue === 1) {
      this.getProductOneApiCallId = singleMessage.messageId;
    } else {
      this.getProductTwoApiCallId = singleMessage.messageId;
    }
    singleMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.getTrendingProductApiEndPoint}?slider=${sliderValue}`);
    singleMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
    singleMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
    runEngine.sendMessage(singleMessage.id, singleMessage);
  };
  // Customizable Area End
}
